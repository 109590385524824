import React from "react";
// import "./Ecommerce.css";
import { Outlet } from "react-router-dom";
import { BiStore } from "react-icons/bi";
import { FaShopify } from "react-icons/fa6";
import { GiConfirmed } from "react-icons/gi";
import { IoLogoStackoverflow } from "react-icons/io5";
import { MdOutlineMessage, MdProductionQuantityLimits } from "react-icons/md";

function Integration() {
  return (
    <>
      <div className="flex">
        <div className="w-[100%] h-screen overflow-auto">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Integration;
