import React from "react";
import GlobalHeader from "../../../../components/GlobalComponets/GlobalHeader";
import DateRange from "../GlobalCmp/DateRange";

function ShopifyAnalytics() {
  const initialData = [
    { date: "30 Nov", user: 54, business: 474, chatbot: 18 },
    { date: "01 Dec", user: 150, business: 10, chatbot: 50 },
    { date: "02 Dec", user: 300, business: 600, chatbot: 100 },
    { date: "03 Dec", user: 50, business: 0, chatbot: 30 },
  ];

  const DetailsOfGlobalHeader = {
    Title: "Shopify Analytics",
    Details: false,
  };
  return (
    <div className="w-full min-h-[90vh] mt-10">
      <GlobalHeader item={DetailsOfGlobalHeader} />

      <DateRange initialData={initialData} />
    </div>
  );
}

export default ShopifyAnalytics;
