// import React, { useState } from "react";
// import { BsSend } from "react-icons/bs";
// import useSendMessages from "../../hooks/useSendMessages";

// const TemplateMsgSend = () => {
//   const [input, setInput] = useState("");

//   const { loading, sendMessage } = useSendMessages();

//   const hadleOnSubmit = async (e) => {
//     e.preventDefault();
//     await sendMessage(input);
//     setInput("");
//   };

//   return (
//     <form className="px-4 my-3" onSubmit={hadleOnSubmit}>
//       <div className="w-full relative">
//         <input
//           type="text"
//           className="border text-sm rounded-lg block p-2.5 bg-gray-700 border-gray-600 text-white w-full"
//           placeholder="Send a message"
//           value={input}
//           onChange={(e) => {
//             setInput(e.target.value);
//           }}
//         />
//         <button
//           type="submit"
//           className="btn bg-transparent border-none hover:bg-transparent hover:text-blue-500 text-xl mt-[-2px] absolute inset-y-0 end-0 flex items-center pe-3"
//         >
//           {loading ? (
//             <div className="loading loading-spinner"></div>
//           ) : (
//             <BsSend />
//           )}
//         </button>
//       </div>
//     </form>
//   );
// };

// export default TemplateMsgSend;

//STARTER CODE OF THIS FILE
import React, { useState, useCallback, useRef } from "react";

import { Button, IconButton, styled } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import BoltIcon from "@mui/icons-material/Bolt";
import SendIcon from "@mui/icons-material/Send";

// import { useSingleMessage } from "../hooks/useManagetemplate";
// import useZusConversation from "../zustand/useZusConversation";
import useZusConversation from "../../../zustand/useZusConversation";
import useSendMessage from "../../../hooks/useSendMessage";
import BulkMessageBox from "../../BulkMessageBox";
import { useSingleMessage } from "../../../hooks/useManagetemplate";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const TemplateMsgSend = ({ modalRef }) => {
  const [input, setInput] = useState("");
  const { loading, sendMessage } = useSendMessage();

  const { selectedConversation } = useZusConversation();

  console.log("number looks likes this:", selectedConversation?.mobile_Number);
  const number = selectedConversation?.mobile_Number;
  const Numberlist = selectedConversation?._id;
  const contacts = [String(number)];
  const Conatct_Id = [String(Numberlist)];

  const hadleSubmit = async (e) => {
    e.preventDefault();
    // await sendMessage(input);
    console.log("hhelow");
    document.getElementById("messagetemplate").showModal();
    setInput("");
  };

  const data = {
    button1: "Send",
    button2: "Cancel",
  };

  const { isSendTemplate, isloader, sendSinglemesssage } = useSingleMessage();

  const storedDataRef = useRef(null);

  const handlesingleMessageTemplate = useCallback((newData) => {
    // console.log("data in template single message looks like this:", newData);
    storedDataRef.current = newData;
  }, []);

  console.log("id in call back looks likes this:", storedDataRef.current);
  const handletreiggerButton = useCallback(async () => {
    if (!storedDataRef.current) {
      console.error("No data stored ");
      return;
    }

    const id = storedDataRef.current.id;
    const contact = storedDataRef.current.contact;
    const mediaurl = storedDataRef.current.mediaurl;
    const conatactId = storedDataRef.current.conatactId;
    const parameters = storedDataRef.current.parameters;
    const latitude = storedDataRef.current.latitude;
    const longitude = storedDataRef.current.longitude;
    const address = storedDataRef.current.address;
    const name = storedDataRef.current.name;

    await sendSinglemesssage({
      id,
      contact,
      mediaurl,
      parameters,
      conatactId,
      latitude,
      longitude,
      name,
      address,
    });
    console.log(
      "contact in call back function looks likes this :",
      contact,
      id,
      mediaurl,
      conatactId,
      parameters,
      latitude,
      longitude,
      name,
      address
    );
  }, [data, contacts, Conatct_Id, sendSinglemesssage]);

  return (
    <>
      <form className="px-4 z-50">
        <div className="w-full bg-white rounded-md mb-2 relative py-4 px-5">
          <div className="font-bold text-base">This chat is expired</div>
          <div className="font- mt-0.5 text-gray-900">
            Chats are marked as expired 24 hours after the last received
            customer message. WhatsApp allows only template messages to be sent
            in such chats.
          </div>
          <div className="flex justify-between items-center mt-2">
            <div>
              <Button
                variant="contained"
                sx={{ background: "#23A455" }}
                endIcon={<SendIcon />}
                className="hover:bg-[#0A474C]"
                onClick={(e) => {
                  hadleSubmit(e);
                }}
                disabled={loading}
                type="submit"
              >
                Send Template
              </Button>
              <dialog id="messagetemplate" className="modal" ref={modalRef}>
                <div className="modal-box min-w-[70%]  bg-white text-black">
                  <div className="modal-action">
                    <form method="dialog">
                      {/* if there is a button in form, it will close the modal */}
                      <button className="btn btn-sm border-none outline-none btn-ghost absolute right-2 top-2">
                        ✕
                      </button>
                    </form>
                  </div>
                  <BulkMessageBox
                    data={data}
                    onsend={handlesingleMessageTemplate}
                    trigger={handletreiggerButton}
                    mobileNumbers={contacts}
                    selectedList={Conatct_Id}
                  />
                </div>
              </dialog>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default TemplateMsgSend;
