import React, { useEffect, useState } from "react";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import { MdDelete } from "react-icons/md";
import PhoneInput from "react-phone-number-input";
import { Autocomplete, TextField } from "@mui/material";
import { UseAuthContext } from "../../context/AuthContext";
import {
  UseDeleteAgent,
  UseEditAgent,
  Usegetagents,
} from "../../hooks/useAgenst";
import toast, { Toaster } from "react-hot-toast";
import { RiDeleteBin5Fill } from "react-icons/ri";
// import { UseAuthContext } from "../../context/AuthContext";
function AgentsCard({
  username,
  status,
  currentposition,
  email,
  phonenumber,
  user_Role,
  id,
}) {
  const { authUser } = UseAuthContext();
  const { refetchusername } = Usegetagents();

  const { isDeleteagent, isloaderdelete, handledeleteAgent } = UseDeleteAgent();
  // console.log("id looks likes this :", id);
  const { isEditedAgent, isloader, handleEditAgent } = UseEditAgent();
  console.log("agenst auth data looks likes this :", authUser);
  const [iseditedmail, seteditedmail] = useState(email);
  const [isEditedPhonenumber, seteditedphonenumber] = useState(phonenumber);
  const [isEditedUserRole, setEditedUserRole] = useState(currentposition);
  const [iseditedPassword, setPassword] = useState(null);

  // useEffect(() => {
  //   seteditedmail(email);
  //   seteditedphonenumber(phonenumber);
  //   setEditedUserRole(currentposition);
  // }, [id]);
  // console.log("user role :", username);
  // console.log("edited gmail :", currentposition);
  // console.log("edited gmail :", phonenumber);
  // console.log("edited gmail :", email);

  const managementoption = [
    { label: "Agent", id: 1 },
    { label: "Manager", id: 2 },
  ];

  const Handlemanagement = (event, newValue) => {
    if (newValue) {
      setEditedUserRole(newValue.label); // Use `id` instead of `code`
    } else {
      setEditedUserRole(currentposition); // Reset state when selection is cleared
    }
  };

  const handleupdateEditedData = async (id) => {
    if (!iseditedmail.includes("@") || !iseditedmail.includes(".")) {
      toast.error("Enter a valid email");
      return;
    }


      let updatedata = {
        agentId: id,
        newUserRole: isEditedUserRole,
        newEmail: iseditedmail,
        newMobileNumber: isEditedPhonenumber,
      };
      
      if (iseditedPassword && iseditedPassword.length > 1) {
        updatedata.newUserPassword = iseditedPassword;
      }

    console.log("updated data looks likes :",updatedata)
    const requiredFields = ["newUserRole", "newEmail", "newMobileNumber"];
    for (const field of requiredFields) {
      if (!updatedata[field]) {
        toast.error(`${field} is required`);
        return;
      }
    }

    await handleEditAgent(updatedata);
    document.getElementById(`editmodal-${id}`).close();
    refetchusername();
  };

  const handleDeleteData = async (id) => {
    try {
      if (!id) {
        toast.error("please select..");
      }
      const agentId = id;
      console.log("required id :", agentId);
      await handledeleteAgent({ agentId });
      document.getElementById(`deletagent-${id}`).close();
      refetchusername();
    } catch (error) {
      toast.error("something went wrong...");
    }
  };

  return (
    <>
      <div className="flex flex-col items-center p-8">
        <div className="bg-white shadow-lg rounded-lg w-72 p-6 relative">
          <div className="flex items-center mb-4">
            {/* <img
            src="/api/placeholder/48/48"
            alt="Profile Picture"
            className="w-12 h-12 rounded-full"
          /> */}
            <div className="w-12 h-12 rounded-full bg-blue-500 text-white flex items-center justify-center text-2xl font-bold">
              1
            </div>
            {authUser?.user_Role == null && (
              <div>
                <ModeEditOutlineRoundedIcon
                  className="absolute top-5 right-3 text-xl"
                  onClick={() => {
                    seteditedmail(email);
                    seteditedphonenumber(phonenumber);
                    setEditedUserRole(currentposition);
                    document.getElementById(`editmodal-${id}`).showModal();
                  }}
                />

                <dialog id={`editmodal-${id}`} className="modal">
                  <div className="modal-box">
                    <form method="dialog">
                      {/* if there is a button in form, it will close the modal */}
                      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                        ✕
                      </button>
                    </form>
                    <input
                      type="text"
                      placeholder="email"
                      className="input bg-[#F0F0F0]  w-full text-sm mt-4"
                      value={iseditedmail}
                      required
                      onChange={(e) => seteditedmail(e.currentTarget.value)}
                    />

                    <div className="w-full  phone-input-container1   text-xs">
                      <PhoneInput
                        getOptionLabel={(option) => option.label || "default"}
                        international
                        defaultCountry="IN"
                        required
                        value={isEditedPhonenumber}
                        onChange={(value) => seteditedphonenumber(value)}
                      />
                    </div>

                    <div className="w-full ">
                      <Autocomplete
                        disablePortal
                        id="TemplateLanguage"
                        options={managementoption}
                        sx={{
                          width: "100%",
                          marginTop: "25px",
                          background: "#F0F0F0",
                          borderRadius: "8px",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .css-8ujita-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                            {
                              padding: "7.5px 4px 7.5px 13px",
                            },
                          "& .css-1b9mdof-MuiFormLabel-root-MuiInputLabel-root":
                            {
                              color: "#A9A9A9",
                              fontSize: "medium",
                            },
                        }}
                        value={isEditedUserRole}
                        onChange={Handlemanagement}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select what you want to create"
                          />
                        )}
                      />
                    </div>
                    <input
                      type="text"
                      placeholder=" password "
                      className="input bg-[#F0F0F0]  w-full text-sm mt-4"
                      required
                      onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                    <div className="flex justify-end">
                      <button
                        className={`btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e] mt-3  `}
                        onClick={() => {
                          handleupdateEditedData(id);
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </div>

                  <Toaster
                    toastOptions={{
                      style: {
                        zIndex: 9999, // Ensure toast stays above the dialog
                      },
                    }}
                  />
                </dialog>
              </div>
            )}

            <h2 className="ml-4 text-xl font-bold text-gray-800">{username}</h2>
          </div>
          <div className="flex flex-col">
            <p className="text-gray-600">
              <strong>Username:</strong> {username}
            </p>

            {authUser?.user_Role == null && (
              <button>
                <MdDelete
                  className="absolute right-3 bottom-5 text-xl text-red-500 "
                  // onClick={() =>

                  //   document.getElementById("deletagent").showModal()
                  // }

                  onClick={() => {
                    seteditedmail(email);
                    seteditedphonenumber(phonenumber);
                    setEditedUserRole(currentposition);
                    document.getElementById(`deletagent-${id}`).showModal();
                  }}
                />
                <dialog id={`deletagent-${id}`} className="modal">
                  <div className="modal-box">
                    <form method="dialog">
                      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                        ✕
                      </button>
                    </form>

                    <div className="mx-auto flex justify-center">
                      <RiDeleteBin5Fill className="text-4xl text-gray-500" />
                    </div>
                    <p className="mx-auto text-lg text-center p-3 text-black">
                      Are you sure you want to delete {username} ?
                    </p>
                    <button
                      className="btn btn-sm bg-[#DC2C2B] text-white border-none rounded-md hover:bg-[#DC2C2B]"
                      // onClick={handleDeletemedia}
                      // disabled={isloading}
                    >
                      <RiDeleteBin5Fill className="text-lg text-white" />

                      <span
                        className="gap-1 text-lg"
                        onClick={() => {
                          handleDeleteData(id);
                        }}

                        disabled={isloaderdelete}
                      >
                        Yes, I'm sure
                      </span>
                    </button>
                  </div>
                </dialog>
              </button>
            )}

            <p className="text-gray-600 mt-4">
              <strong>Status:</strong> {currentposition}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AgentsCard;
