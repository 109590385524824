import React from "react";
import FlowTable from "../GlobalCmp/FlowTable";
import QuickGuilde from "../../../../components/QuickGuilde";
import useStoreDetails from "../../../../zustand/sopify/useMetaUserDetails";

const OrderFlow = () => {
  const { storeLoading, storeDetails } = useStoreDetails();

  const quickReplyObj = {
    width: "full",
    text1: "Easily configure Ecomm+ to automate your shopify messages.",
    text2:
      "Automatic OrderFlow Cart Reminders, Order Notifications, COD recovery on whatsapp.",
    // link1: "How to get Access Token and API Secret Key",
    // link2: "How to setup automated shopify messages",
  };

  const flowtabdata = [
    {
      flowName: "Order Confirmed",
      campaign: "OrderFlow Cart 1: 19 Oct 12:23:38",
      status: storeDetails?.shopData?.orderConfirmedSettings?.isFlowRun
        ? "ENABLED"
        : "DISABLED" || "DISABLED",
      enabled:
        storeDetails?.shopData?.orderConfirmedSettings?.isFlowRun || false,
      link: "orderConfirmedSettings",
    },
    {
      flowName: "Order Cancelled",
      campaign: "OrderFlow Cart 2: 19 Oct 12:25:59",
      status: storeDetails?.shopData?.orderCancelledSettings?.isFlowRun
        ? "ENABLED"
        : "DISABLED" || "DISABLED",
      enabled:
        storeDetails?.shopData?.orderCancelledSettings?.isFlowRun || false,
      link: "orderCancelledSettings",
    },
    {
      flowName: "Order Fulfilled",
      campaign: "N/A",
      status: storeDetails?.shopData?.orderFulfilledSettings?.isFlowRun
        ? "ENABLED"
        : "DISABLED" || "DISABLED",
      enabled:
        storeDetails?.shopData?.orderFulfilledSettings?.isFlowRun || false,
      link: "orderFulfilledSettings",
    },
    {
      flowName: "Reorder",
      campaign: "N/A",
      status: storeDetails?.shopData?.reorderSettings?.isFlowRun
        ? "ENABLED"
        : "DISABLED" || "DISABLED",
      enabled: storeDetails?.shopData?.reorderSettings?.isFlowRun || false,
      link: "reorderSettings",
    },
    {
      flowName: "Order Feedback",
      campaign: "N/A",
      status: storeDetails?.shopData?.orderFeedbackSettings?.isFlowRun
        ? "ENABLED"
        : "DISABLED" || "DISABLED",
      enabled:
        storeDetails?.shopData?.orderFeedbackSettings?.isFlowRun || false,
      link: "orderFeedbackSettings",
    },
  ];
  return (
    <div className="w-full min-h-[90vh] ">
      <QuickGuilde data={quickReplyObj} />
      {!storeLoading && <FlowTable data={flowtabdata} />}
    </div>
  );
};

export default OrderFlow;
