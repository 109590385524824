import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";

const UseAnalytics = () => {
  const [isanalytics, setanalytics] = useState("");
  const [isloading, setloading] = useState(false);

  const updatetime = async (updateData) => {
    try {
      setloading(true);
      const resposne = await axios.post("/api/analytics/getData", updateData);
      if (resposne?.status === 200 || resposne?.status === 201) {
        setanalytics(resposne?.data);
      }
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setloading(true);
    }
  };

  return { isanalytics, isloading, updatetime };
};

const UseAnalyticsAgents = () => {
  const [isanalyticsagent, setanalyticsagent] = useState("");
  const [isagentanalyticsloader, setagentanalyticsloader] = useState(false);

  const getagentanalytics = async (updateData) => {
    try {
      setagentanalyticsloader(true);
      const resposne = await axios.post(
        "/api/analytics/agentActivity",
        updateData
      );

      console.log("response looks likes this :", resposne);

      if (resposne.status === 200) {
        setanalyticsagent(resposne?.data);
      }
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setagentanalyticsloader(false);
    }
  };

  return { isagentanalyticsloader, getagentanalytics, isanalyticsagent };
};
export { UseAnalytics, UseAnalyticsAgents };
