import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";


const useFlowUploud = () => {
  const [loading, setLoading] = useState(false);

  const FlowUploud = async (updateData, settingsKey) => {
    if (!updateData) return;
    // if (!updateData.selectedTemplate) {
    //   toast.error("Template field required!");
    //   return;
    // }

    console.log(updateData);

    setLoading(true);

    try {
      const res = axios.post(`/api/shopify/flow-settings`, updateData, {
        params: { settingsKey }, // Specify the part of the schema to update
      });
      const data = await res;

      console.log("res", data);

      toast.success("Flow saved successfully!");
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, FlowUploud };
};

export default useFlowUploud;
