import { RiGooglePlayFill } from "react-icons/ri";
import { GiPowerLightning } from "react-icons/gi";
import { TbApiApp } from "react-icons/tb";
import { MdMenuBook, MdOutlineDone } from "react-icons/md";
import { MdWhatsapp } from "react-icons/md";
import { ImFacebook2 } from "react-icons/im";
import { CgProfile } from "react-icons/cg";
import VerifiedIcon from "@mui/icons-material/Verified";
import { MdEdit } from "react-icons/md";
import { MdCurrencyRupee } from "react-icons/md";
import { IoMdInformationCircle } from "react-icons/io";

import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import { UseAuthContext } from "../../context/AuthContext";
import axios from "axios";
import useMetaUserDetails from "../../zustand/useMetaUserDetails";
import { Link } from "react-router-dom";
import { Link as LinkOfMui } from "@mui/material";
import toast from "react-hot-toast";
import { useGetProfile } from "../../hooks/useProfile";

//icons for the navbar
import { IoSearch } from "react-icons/io5";
import { PiBellSimpleRingingFill } from "react-icons/pi";
import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { IoClose } from "react-icons/io5"; // Import the close icon
// import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import { AiTwotoneApi } from "react-icons/ai";
import LocalAtmTwoToneIcon from "@mui/icons-material/LocalAtmTwoTone";
import AccountBalanceWalletTwoToneIcon from "@mui/icons-material/AccountBalanceWalletTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";

const Dashboard = () => {
  const { metaUserDetails } = useMetaUserDetails();
  const { authUser, setAuthUser } = UseAuthContext();
  console.log("authUser looks likes this 123 :", authUser);

  const [isCollapse, setIsCollapse] = useState(true);
  const [isCollapse1, setIsCollapse1] = useState(false);
  const [isCollapse2, setIsCollapse2] = useState(false);
  const [isCollapse3, setIsCollapse3] = useState(false);
  const [isCollapse4, setIsCollapse4] = useState(false);

  const { refreshData, setRefreshData } = useGetProfile(); // To trigger profile fetch

  useEffect(() => {
    authUser.isMetaConnected === "Connected"
      ? setIsCollapse(false)
      : setIsCollapse(true);
  }, [authUser]);

  const [sessionInfoResponse, setSessionInfoResponse] = useState("");
  const [sdkResponse, setSdkResponse] = useState({}); // Changed from string to object to store code
  const [wabaId, setWabaId] = useState(null);
  const [phoneNumberId, setPhoneNumberId] = useState(null);

  // Function to handle Facebook login callback
  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const code = response.authResponse.code;

      // Log the values for debugging
      console.log("Code:", code);

      // Set the code in the sdkResponse state
      setSdkResponse({ code });
    }
    setSdkResponse((prev) => ({
      ...prev,
      response: JSON.stringify(response, null, 2),
    }));
  };

  // useEffect to trigger the API call only when all required values are set
  useEffect(() => {
    if (sdkResponse.code && wabaId && phoneNumberId) {
      console.log(
        "Making API call with:",
        sdkResponse.code,
        wabaId,
        phoneNumberId
      );

      // Send the code, waba_id, and phone_number_id to your backend
      axios
        .post(
          "/meta/auth/facebook",
          {
            code: sdkResponse.code,
            waba_id: wabaId,
            phone_number_id: phoneNumberId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const data = response.data;
          console.log("response data:", data);
          // Set the local storage for AuthUser
          localStorage.setItem("authUser", JSON.stringify(data));
          setAuthUser(data); //re-render the components
          setRefreshData(!refreshData); // Trigger profile fetching via state change
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error(error.response?.data?.error || error.message);
        });
    }
  }, [sdkResponse.code, wabaId, phoneNumberId]);

  // Function to launch WhatsApp signup
  const launchWhatsAppSignup = () => {
    window.FB.login(fbLoginCallback, {
      config_id: "545421348387545", // configuration ID goes here
      response_type: "code", // must be set to 'code' for System User access token
      override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      extras: {
        setup: {},
        featureType: "",
        sessionInfoVersion: "3",
      },
    });
  };

  // Initialize Facebook SDK
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "991474582322820", // Your Facebook App ID
        autoLogAppEvents: true,
        xfbml: true,
        version: "v20.0",
      });
    };

    const script = document.createElement("script");
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Handle the message event for Facebook responses
  useEffect(() => {
    const handleMessage = (event) => {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      ) {
        return;
      }
      try {
        const data = JSON.parse(event.data);
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          if (data.event === "FINISH") {
            const { phone_number_id, waba_id } = data.data;
            console.log(
              "Phone number ID:",
              phone_number_id,
              "WhatsApp business account ID:",
              waba_id
            );

            // Set waba_id and phone_number_id to state
            setWabaId(waba_id);
            setPhoneNumberId(phone_number_id);
          } else if (data.event === "CANCEL") {
            const { current_step } = data.data;
            console.warn("Cancel at", current_step);
          } else if (data.event === "ERROR") {
            const { error_message } = data.data;
            console.error("Error:", error_message);
          }
        }
        setSessionInfoResponse(JSON.stringify(data, null, 2));
      } catch {
        console.log("Non JSON Responses", event.data);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div className="">
      {/* HEADER */}
      {/* <Header /> */}
      {/* <NavBar /> */}
      {/* MAIN CONTAINER */}
      <div className="flex justify-between m-5 overflow-x-hidden">
        {/* left Container */}
        <div
          className={` ${
            metaUserDetails?.profile_Pic ? "w-[70%]" : "w-full"
          } me-5`}
        >
          {/* First Starter Line */}
          <div className="flex justify-between items-center my-2">
            <div className="text-lg">
              Hey {authUser.fullName}, Welcome to THEBOTMODE!
            </div>
            {/* <div className="btn btn-sm bg-opacity-20 hover:bg-slate-200 shadow-none border-none mx-4 font-normal text-black">
              <RiGooglePlayFill /> Onboarding
            </div>
            <div className="btn btn-sm bg-opacity-20 hover:bg-slate-200 shadow-none border-none mx-4 font-normal text-black">
              <GiPowerLightning /> Start Tour
            </div> */}
          </div>

          {/* remaining Quota */}
          <ApiStatus />

          {/* MAIN CARD */}
          <div className="bg-white p-5 rounded-md shadow ">
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center text-lg">
                <div className="me-2">
                  <img
                    src="/assets/whatsapp-873316_1280.jpg"
                    width={40}
                    alt=""
                  />
                </div>
                <div className="tracking-wider">
                  Setup FREE{" "}
                  <span className="text-green-400 font-semibold">WhatsApp</span>
                  Business Account
                </div>
              </div>
              <div className="text-sm text-gray-400">5 steps left</div>
            </div>
            {/* SUB CARD */}
            {/* First Accodion */}
            <div className="collapse collapse-arrow text-[#2F4F4F] bg-[#EBF5F3] mt-4 rounded-md shadow">
              <input
                type="radio"
                name="my-accordion-1"
                checked={isCollapse}
                disabled={authUser.isMetaConnected === "Connected"}
                onClick={() => {
                  setIsCollapse(!isCollapse);
                }}
              />
              <div className="collapse-title flex justify-between">
                <div className="flex items-center">
                  <div className="me-2 text-3xl text-yellow-600">
                    <TbApiApp />
                  </div>
                  <div className="font-semibold text-base">
                    Apply for WhatsApp Business API
                  </div>
                </div>
                {authUser.isMetaConnected === "Connected" && (
                  <div className=" text-3xl font-bold text-green-500">
                    <MdOutlineDone />
                  </div>
                )}
              </div>

              <div className="collapse-content">
                <div className="flex justify-between items-center ms-1">
                  <div>
                    <p className="font-medium text-sm my-2">
                      Click on Continue With Facebook to apply for WhatsApp
                      Business API
                    </p>
                    <p className="font-medium text-sm text-[#6E6E6E] my-2">
                      Requirements to apply for WhatsApp Business API
                    </p>
                    <p className="font-medium text-xm text-[#6E6E6E] my-2">
                      • A Registered Business & Working Website.
                    </p>
                    <p className="flex font-medium text-sm hover:underline my-2">
                      <MdMenuBook className="text-lg me-2" />
                      <a href="#">How to apply for WhatsApp Business API?</a>
                    </p>
                  </div>
                  <div className="text-center mb-2">
                    <img src="/assets/whatsapp.jpg" width={300} alt="" />
                    <button
                      onClick={launchWhatsAppSignup}
                      disabled={authUser.isMetaConnected === "Connected"}
                      className="btn btn-sm bg-blue-600 rounded-md border-none text-white px-5 hover:bg-blue-700"
                    >
                      Continue With Facebook
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Second Accodion */}
            {/* <div className="collapse collapse-arrow text-[#2F4F4F] bg-[#EBF5F3] mt-4 rounded-md shadow">
              <input
                type="radio"
                name="my-accordion-2"
                checked={isCollapse1}
                onClick={() => {
                  setIsCollapse1(!isCollapse1);
                }}
              />
              <div className="collapse-title">
                <div className="flex items-center">
                  <div className="me-2 text-3xl text-green-500">
                    <MdWhatsapp />
                  </div>
                  <div className="font-semibold text-base">
                    Phone & Display Name Verification
                  </div>
                </div>
              </div>

              <div className="collapse-content">
                <p className="font-medium text-sm text-[#6E6E6E] my-2">
                  Complete display name & phone number verification to increase
                  WhatsApp messaging limit.
                </p>

                <div className="flex justify-between items-center ms-1">
                  <p className="flex font-medium text-sm hover:underline my-2">
                    <MdMenuBook className="text-lg me-2" />
                    <a href="#">Phone Number Rules</a>
                  </p>
                  <p className="flex font-medium text-sm hover:underline my-2">
                    <MdMenuBook className="text-lg me-2" />
                    <a href="#">Display Name Rules</a>
                  </p>
                </div>
              </div>
            </div> */}
            {/* Third Accodion */}
            {/* <div className="collapse collapse-arrow text-[#2F4F4F] bg-[#EBF5F3] mt-4 rounded-md shadow">
              <input
                type="radio"
                name="my-accordion-3"
                checked={isCollapse2}
                onClick={() => {
                  setIsCollapse2(!isCollapse2);
                }}
              />
              <div className="collapse-title">
                <div className="flex items-center">
                  <div className="me-2 text-3xl text-blue-600">
                    <ImFacebook2 />
                  </div>
                  <div className="font-semibold text-base">
                    Facebook Manager Varification
                  </div>
                </div>
              </div>

              <div className="collapse-content">
                <div className="flex justify-between items-center ms-1">
                  <div>
                    <p className="font-medium text-sm text-[#6E6E6E] my-2">
                      Complete your Facebook manager verification to increase
                      WhatsApp messaging limit.
                    </p>
                    <p className="font-medium text-xm text-[#6E6E6E] my-2">
                      • A Registered Business & Working Website.
                    </p>
                    <p className="flex font-medium text-sm hover:underline my-2">
                      <MdMenuBook className="text-lg me-2" />
                      <a href="#">How to apply for FB Verification?</a>
                    </p>
                    <p className="flex font-medium text-sm hover:underline my-2">
                      <MdMenuBook className="text-lg me-2" />
                      <a href="#">FB Verification button greyed out?</a>
                    </p>
                    <button className="btn btn-sm bg-blue-600 rounded-md border-none text-white px-5 hover:bg-blue-700 mt-4">
                      Continue With Facebook
                    </button>
                  </div>
                  <div className="text-center mb-2">
                    <img
                      src="/assets/facebookVerification.png"
                      width={300}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div> */}
            {/* Forth Accodion */}
            {/* <div className="collapse collapse-arrow text-[#2F4F4F] bg-[#EBF5F3] mt-4 rounded-md shadow">
              <input
                type="radio"
                name="my-accordion-4"
                checked={isCollapse3}
                onClick={() => {
                  setIsCollapse3(!isCollapse3);
                }}
              />
              <div className="collapse-title">
                <div className="flex items-center">
                  <div className="me-2 text-3xl">
                    <CgProfile />
                  </div>
                  <div className="font-semibold text-base">
                    Setup Your Profile
                  </div>
                </div>
              </div>

              <div className="collapse-content">
                <div className="ms-1">
                  <div>
                    <p className="font-medium text-sm text-[#6E6E6E] my-2">
                      Customize your WhatsApp profile, to help understand your
                      customers well.
                    </p>

                    <p className="flex font-medium text-sm hover:underline my-2">
                      <MdMenuBook className="text-lg me-2" />
                      <a href="#">How to Edit Business Profile?</a>
                    </p>
                  </div>

                  <button className="btn btn-sm bg-blue-600 rounded-md border-none text-white font-medium px-5 hover:bg-blue-700">
                    Edit Profile
                  </button>
                </div>
              </div>
            </div> */}
            {/* Five Accodion */}
            {/* <div className="collapse collapse-arrow text-[#2F4F4F] bg-[#EBF5F3] mt-4 rounded-md shadow">
              <input
                type="radio"
                name="my-accordion-5"
                checked={isCollapse4}
                onClick={() => {
                  setIsCollapse4(!isCollapse4);
                }}
              />
              <div className="collapse-title">
                <div className="flex items-center">
                  <div className="me-2 text-green-500">
                    <VerifiedIcon sx={{ fontSize: "30px" }} />
                  </div>
                  <div className="font-semibold text-base">
                    Apply for Green Tick
                  </div>
                </div>
              </div>

              <div className="collapse-content">
                <div className="flex justify-between items-center ms-1">
                  <div>
                    <p className="font-medium text-sm my-2">
                      Get Verified Green Tick on your Whatsapp.
                    </p>
                    <p className="font-medium text-sm text-[#6E6E6E] my-2">
                      Requirements to apply for WhatsApp Business API
                    </p>
                    <p className="font-medium text-xm text-[#6E6E6E] my-2">
                      • Make sure to go through all eligiblity criteria before
                      applying for Green Tick.
                    </p>
                    <p className="font-medium text-xm text-[#6E6E6E] my-2">
                      • For any help contact KoreGrow Support.
                    </p>
                    <p className="flex font-medium text-sm hover:underline my-2">
                      <MdMenuBook className="text-lg me-2" />
                      <a href="#">WhatsApp Green Tick</a>
                    </p>
                    <button className="btn btn-sm bg-blue-600 rounded-md border-none text-white font-medium px-5 hover:bg-blue-700 mt-5">
                      Apply For Green Tick
                    </button>
                  </div>
                  <div className="text-center mb-2">
                    <img src="/assets/greenTick.png" width={300} alt="" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        {metaUserDetails.profile_Pic && (
          <div className="flex justify-center mt-5 w-[30%]">
            <div className="max-w-fit">
              <div className="bg-white shadow-xl rounded-lg py-3">
                <div className="photo-wrapper p-2">
                  <img
                    className="w-32 h-32 rounded-full mx-auto object-cover"
                    src={metaUserDetails?.profile_Pic}
                    alt="John Doe"
                  />
                </div>
                <div className="p-2">
                  <h3 className="text-center text-xl text-gray-900 font-medium leading-8">
                    {}
                    {metaUserDetails?.verified_name
                      ? metaUserDetails.verified_name
                      : metaUserDetails?.phone_Number}
                  </h3>
                  {!metaUserDetails.verified_name && (
                    <div className="text-center text-yellow-400 text-xs font-semibold">
                      <p>Profile name is not yet set!</p>
                    </div>
                  )}
                  <div className="text-center text-gray-400 text-xs font-semibold">
                    <p>{metaUserDetails?.vertical}</p>
                  </div>
                  <table className="text-xs my-3">
                    <tbody>
                      <tr>
                        <td className="px-2 py-2 text-gray-500 font-semibold">
                          Description
                        </td>
                        <td className="px-2 py-2 max-w-20 overflow-hidden ">
                          {metaUserDetails?.description}
                        </td>
                      </tr>
                      <tr>
                        <td className="px-2 py-2 text-gray-500 font-semibold">
                          Address
                        </td>
                        <td className="px-2 py-2">
                          {metaUserDetails?.address}
                        </td>
                      </tr>
                      <tr>
                        <td className="px-2 py-2 text-gray-500 font-semibold">
                          Email
                        </td>
                        <td className="px-2 py-2">{metaUserDetails?.email}</td>
                      </tr>
                      <tr>
                        <td className="px-2 py-2 text-gray-500 font-semibold">
                          Websites
                        </td>
                        <td>
                          <a
                            href={metaUserDetails?.websites?.[0]}
                            target="_blank"
                            className="block px-2 pt-2 pb-1 text-xs text-indigo-500 italic hover:underline hover:text-indigo-600 font-medium"
                          >
                            {metaUserDetails?.websites?.[0]}
                          </a>
                          {metaUserDetails?.websites?.[1] && (
                            <a
                              href={metaUserDetails?.websites?.[1]}
                              target="_blank"
                              className="block px-2 pt-2 pb-1 text-xs text-indigo-500 italic hover:underline hover:text-indigo-600 font-medium"
                            >
                              {metaUserDetails?.websites?.[1]}
                            </a>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="px-2 py-2 text-gray-500 font-semibold">
                          About
                        </td>
                        <td className="px-2 py-2">{metaUserDetails?.about}</td>
                      </tr>
                      <tr>
                        <td className="px-2 py-2 text-gray-500 font-semibold">
                          Phone Number
                        </td>
                        <td className="px-2 py-2">
                          {metaUserDetails?.phone_Number}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {authUser?.user_Role === "Agent" ? (
                    // <div className="text-center my-3">
                    //   <Link
                    //     to="/profile"
                    //     className="text-xs text-indigo-500 italic hover:underline hover:text-indigo-600 font-medium"
                    //     href="#"
                    //   >
                    //     edit Profile
                    //   </Link>
                    // </div>
                    <div></div>
                  ) : (
                    <div className="text-center my-3">
                      <Link
                        to="/profile"
                        className="text-xs text-indigo-500 italic hover:underline hover:text-indigo-600 font-medium"
                        href="#"
                      >
                        edit Profile
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Right Container */}
        {
          // <div className="flex-none w-96">
          //   {/* FORM  */}
          //   <div className="w-full bg-white rounded-md shadow p-5">
          //     <div className="text-lg">
          //       Account Status :{" "}
          //       <span className="text-[#2F4F4F]"> Test Mode</span>
          //     </div>
          //     <div className="text-gray-500 text-xs my-3">
          //       Test mode enables you to explore Broadcasting and Live Chat
          //       features of KoreGrow before applying for WhatsApp API
          //     </div>
          //     <div>
          //       Test Business Number :{" "}
          //       <span className="text-[#2F4F4F] text-lg">+919810765443</span>
          //     </div>
          //     <form>
          //       <div className="flex justify-between items-center my-3">
          //         <div className="text-sm">Test Contact</div>
          //         <div className="btn btn-xs bg-opacity-5 shadow-none hover:bg-gray-300  text-black border-none rounded-md">
          //           Edit
          //           <MdEdit />
          //         </div>
          //       </div>
          //       <div className="text-green-500">
          //         Your contact has been added successfully.
          //       </div>
          //       <div className="flex justify-between items-center my-3">
          //         <label>Contact Name</label>
          //         <input
          //           type="text"
          //           placeholder="Type Name"
          //           className="input input-bordered input-sm bg-white"
          //         />
          //       </div>
          //       <div className="flex justify-between items-center">
          //         <label>WhatsApp Number</label>
          //         <input
          //           type="text"
          //           placeholder="+910000000000"
          //           className="input input-bordered input-sm bg-white"
          //         />
          //       </div>
          //     </form>
          //     <div className="flex justify-between items-center my-4">
          //       <button className="btn glass btn-sm bg-slate-700 text-white">
          //         Try Broadcasting
          //       </button>
          //       <button className="btn glass btn-outline btn-sm">
          //         Try Live Chat
          //       </button>
          //     </div>
          //     <div className="divider" />
          //     <div className="text-center text-gray-600 font-medium mb-5">
          //       Wish to setup THEBOTMODE for your Business?
          //     </div>
          //     <div className="btn btn-sm w-full bg-green-500 hover:bg-green-600 border-none text-white font-medium tracking-wider">
          //       FREE WhatsApp Business API
          //     </div>
          //   </div>
          //   {/* next container */}
          //   <div className="my-4">
          //     {/* FORM  */}
          //     <div className="w-full bg-white rounded-md shadow p-5">
          //       <div className="text-sm text-gray-600 tracking-wide">
          //         Current Plan
          //       </div>
          //       <div className="text-lg">NONE</div>
          //       <div className="text-center text-xs text-gray-600 font-medium mt-5">
          //         You don’t have any active plan
          //       </div>
          //       <div className="flex justify-center items-center mt-2">
          //         <button className="btn btn-sm w-3/4 bg-[#0A474C] hover:bg-[#122d30] border-none text-white font-medium tracking-wider">
          //           Get a Plan
          //         </button>
          //       </div>
          //     </div>
          //   </div>
          //   {/* FEE container */}
          //   <div className="my-4">
          //     <div className="w-full bg-white rounded-md shadow p-5">
          //       <div className="flex justify-between items-center">
          //         <div className="text-sm text-gray-600 tracking-wide">
          //           Free Service Conversation
          //         </div>
          //         <div className="text-sm">15/1000 used</div>
          //       </div>
          //       <progress
          //         className="progress w-full progress-success  my-3"
          //         value="10"
          //         max="100"
          //       ></progress>
          //       <div className="text-sm tracking-wider">
          //         WhatsApp Conversation Credits (WCC)
          //       </div>
          //       <div className="flex justify-between items-center mt-5">
          //         <div className="text-[#0A474C] text-lg font-semibold tracking-wide">
          //           ₹ 50.00
          //         </div>
          //         <button
          //           className="btn btn-sm bg-[#0A474C] hover:bg-[#122d30] border-none text-white font-medium tracking-wider"
          //           onClick={() =>
          //             document.getElementById("my_modal_3").showModal()
          //           }
          //         >
          //           Buy More
          //         </button>
          //       </div>
          //     </div>
          //   </div>
          // </div>
        }
      </div>

      {/* Dialog */}
      <dialog id="my_modal_3" className="modal">
        <div className="modal-box bg-white min-w-[40vw] relative min-h-[96vh]">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-5 text-lg">
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg mb-5">
            Purchase WhatsApp Conversation Credits (WCC)
          </h3>
          <div className="bg-[#FBFBFB] shadow absolute left-0 w-full">
            {/*Payment Method Container*/}
            <div className="p-5 border-2 rounded-md border-gray-300 m-5">
              <h2 className="text-sm font-medium tracking-wide">
                Enter WCC Amount
              </h2>
              <p className="text-gray-500 my-2">
                Minimum purchase of ₹1000 credits is allowed
              </p>
              <label className="input mt-3 input-bordered rounded-sm flex items-center gap-2 bg-[#F0F0F0]">
                <MdCurrencyRupee className="text-gray-500" />
                <input
                  type="text"
                  className="grow "
                  placeholder="Enter Credit Amount Here"
                />
              </label>
              <div className="flex items-center justify-start gap-5 my-4 ">
                <button className="btn btn-sm rounded-md text-black font-medium border-gray-400 hover:bg-[#F0F0F0] hover:border-gray-400 bg-transparent ">
                  +2,500
                </button>
                <button className="btn btn-sm rounded-md text-black font-medium border-gray-400 hover:bg-[#F0F0F0] hover:border-gray-400 bg-transparent ">
                  +5,000
                </button>
                <button className="btn btn-sm rounded-md text-black font-medium border-gray-400 hover:bg-[#F0F0F0] hover:border-gray-400 bg-transparent ">
                  +10,000
                </button>
                <button className="btn btn-sm rounded-md text-black font-medium border-gray-400 hover:bg-[#F0F0F0] hover:border-gray-400 bg-transparent ">
                  +50,000
                </button>
              </div>
              <div className="flex items-center justify-start gap-5">
                <button className="btn border-none rounded-md text-white bg-[#0A474C] hover:bg-[#073135] font-medium">
                  Pay Via Razorpay
                </button>
                <button className="btn border-none rounded-md text-white bg-[#0A474C] hover:bg-[#073135] font-medium flex flex-col">
                  <div className="mt-[3px]">Pay Now</div>
                  <div className="text-xs mt-[-3px]">
                    Via International Cards
                  </div>
                </button>
              </div>
            </div>

            {/* Auto payment container */}
            <div className="p-5 border-2 rounded-md border-gray-300 m-5">
              <h2 className="text-sm font-medium tracking-wide">
                Enable WCC auto-recharge
              </h2>
              <p className="text-gray-500 my-2 flex justify-start gap-3 items-center">
                Enter minimum WCC amount
                {/* toolTip */}
                <div
                  className="tooltip tooltip-accent"
                  data-tip="WCC auto-recharge will be initiated when the balance goes below the amount specified here"
                >
                  <IoMdInformationCircle className="text-lg" />
                </div>
              </p>
              <label className="input input-sm outline-none mt-3 input-bordered rounded-md flex items-center gap-2 bg-[#F0F0F0]">
                <MdCurrencyRupee className="text-gray-500" />
                <input
                  type="text"
                  className="grow "
                  placeholder="Minimum WCC amount Here"
                />
              </label>
              <p className="text-gray-500 my-2 flex justify-start gap-3 items-center">
                Enter auto-recharge amount
                {/* toolTip */}
                <div
                  className="tooltip tooltip-accent"
                  data-tip="WCC auto-recharge will be done of amount specified here"
                >
                  <IoMdInformationCircle className="text-lg" />
                </div>
              </p>
              <label className="input input-sm outline-none mt-3 input-bordered rounded-md flex items-center gap-2 bg-[#F0F0F0]">
                <MdCurrencyRupee className="text-gray-500" />
                <input
                  type="text"
                  className="grow "
                  placeholder="amount to recharge"
                />
              </label>
              <p className="text-gray-400 text-xs my-2 tracking-wide">
                WCC auto-recharge of ₹ will be initiated when WhatsApp
                Conversation Credit (WCC) goes below ₹
              </p>

              <button className="btn btn-sm border-none rounded-md text-white bg-[#0A474C] hover:bg-[#073135] font-medium mt-3">
                Save
              </button>
            </div>
          </div>
        </div>
      </dialog>

      <Box
        component="footer"
        sx={{
          textAlign: "center",
          p: 2,
          bgcolor: "background.paper",
          position: "fixed",
          bottom: 0,
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          <LinkOfMui
            href="https://thebotmode.com/privacy-policy/"
            target="_blank"
            underline="hover"
            sx={{ color: "#0A474C" }}
          >
            Privacy & Policy
          </LinkOfMui>{" "}
          and{" "}
          <LinkOfMui
            href="https://thebotmode.com/terms-condition/"
            target="_blank"
            underline="hover"
            sx={{ color: "#0A474C" }}
          >
            Terms & Conditions
          </LinkOfMui>
          .
        </Typography>
      </Box>
    </div>
  );
};

export default Dashboard;

function ApiStatus() {
  const { metaUserDetails } = useMetaUserDetails();

  const Details = {
    Status: metaUserDetails ? metaUserDetails.quality_rating : "None",

    RemaingCount: metaUserDetails ? metaUserDetails.remaining_quota : "unknown",
  };

  return (
    <div className="bg-white p-5 rounded-md shadow mb-2">
      <div className="flex justify-around items-center w-full">
        <div className="w-1/3 text-center">
          <h3>WhatsApp Business API Status</h3>
          <div className="bg-green-500 py-1 px-5 rounded-s-3xl text-white font-bold rounded-e-3xl w-fit text-center mx-auto mt-1">
            {metaUserDetails.phone_Number ? "Live" : "Pendding"}
          </div>
        </div>
        <div className="w-1/3 text-center">
          <h3>Quality Rating</h3>
          <div className="bg-green-500 py-1 px-5 rounded-s-3xl text-white font-bold rounded-e-3xl w-fit text-center mx-auto mt-1">
            {Details?.Status}
          </div>
        </div>
        <div className="w-1/3 text-center">
          <h3>Remaining Quota</h3>
          <div className="py-1 px-5 rounded-s-3xl text-[#0A474C] text-lg font-bold rounded-e-3xl w-fit text-center mx-auto">
            {Details?.RemaingCount}
          </div>
        </div>
      </div>
    </div>
  );
}

function NavBar() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 320 }} role="presentation">
      <div className="w-full flex  flex-col mt-10  h-screen bg-white rounded-lg shadow-md p-4 ">
        {/* Profile Section */}
        <div className="flex  w-full   h-44 flex-col items-center">
          {/* Avatar */}
          <div className="relative mb-4">
            <div className="absolute inset-0 w-[99px] h-[99px] rounded-full bg-gradient-to-r from-green-600 animate-spin-slow items-center justify-center flex "></div>

            <div className=" relative w-24 h-24 rounded-full  ">
              <div className="w-full h-full rounded-full bg-white p-1">
                <div className="w-full h-full rounded-full bg-gray-200 flex items-center justify-center">
                  {/* Placeholder for Avatar */}
                  {/* <span className="text-xl text-gray-500">Avatar</span> */}
                  <img
                    src=" https://res.cloudinary.com/dvulhjjes/image/upload/v1731749079/profile%20images/djxylmp8mv56audk3lqa.png"
                    className="w-full h-full  object-cover"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Profile Info */}
          <div className="text-center">
            <p className="text-lg font-medium">zoro</p>
            <p className="text-sm text-gray-500 font-medium">
              zoro812@gmail.com
            </p>
          </div>
        </div>
        {/* <div class="border-b-gray-400 border-dotted border-gray-500 w-full"></div> */}
        <div class="border-t border-dashed border-gray-300 w-full"></div>

        {/* Navigation */}
        <div className="mt-5 space-y-3">
          <div className="w-full text-left px-4 py-2 text-gray-700 rounded-lg flex  gap-x-3 items-center  hover:bg-gray-200">
            {/* <AccountBoxIcon className=" text-gray-500 " fontSize="large" /> */}
            <AccountCircleTwoToneIcon
              className="text-gray-500"
              fontSize="large"
            />
            <div className="font-medium text-gray-500 hover:text-gray-700 text-sm ">
              Profile
            </div>
          </div>
          {/* <button className="w-full text-left px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-100">
            Projects
            <span className="ml-2 text-sm text-red-500">(3)</span>
          </button> */}
          {/* <button className="w-full text-left px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-100">
            Subscription
          </button> */}

          <div className="w-full text-left px-4 py-2 text-gray-700 rounded-lg flex  gap-x-3 items-center  hover:bg-gray-200">
            {/* <AccountBoxIcon className=" text-gray-500 " fontSize="large" /> */}
            <LocalAtmTwoToneIcon className="text-gray-500" fontSize="large" />
            <div className="font-medium text-gray-500 hover:text-gray-700 text-sm ">
              {" "}
              Subscription
            </div>
          </div>

          <div className="w-full text-left px-4 py-2 text-gray-700 rounded-lg flex  gap-x-3 items-center  hover:bg-gray-200">
            {/* <AccountBoxIcon className=" text-gray-500 " fontSize="large" /> */}
            <AiTwotoneApi className="text-gray-500 text-xl" />
            <div className="font-medium text-gray-500 hover:text-gray-700 text-sm ">
              {" "}
              Whatsapp business api status
            </div>
          </div>
          {/* <button className="w-full text-left px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-100">
            billing
          </button> */}
          <div className="w-full text-left px-4 py-2 text-gray-700 rounded-lg flex  gap-x-3 items-center  hover:bg-gray-200">
            {/* <AccountBoxIcon className=" text-gray-500 " fontSize="large" /> */}
            <AccountBalanceWalletTwoToneIcon
              className="text-gray-500 "
              fontSize="large"
            />
            <div className="font-medium text-gray-500 hover:text-gray-700 text-sm ">
              {" "}
              Billing
            </div>
          </div>
          {/* <button className="w-full text-left px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-100">
            Subscription
          </button> */}
        </div>
      </div>

      <div className="mt-6 flex justify-center items-center bottom-3 fixed w-[310px] ">
        <button className="w-[97%] mr-2 ml-2 px-4 py-3 bg-red-100 text-red-700 text-sm  rounded-lg hover:bg-red-200">
          <span className="font-bold">Logout</span>
        </button>
      </div>
    </Box>
  );

  return (
    <>
      <div className=" backdrop-blur-md bg-white/30 border border-white/20 rounded-lg shadow-lg  mx-auto flex items-center justify-between p-4 border-b h-20  sticky top-0 z-50">
        {/* Left Content (Logo or Items) */}
        <div className="flex items-center space-x-4"></div>

        {/* Right Content (Icons or Profile) */}
        <div className="flex items-center space-x-4 h-14 w-52 p-2">
          {/* Add your icons or profile here */}
          <div className="w-16 h-8 p-2 bg-[#EDEFF2] flex  items-center justify-between hover:bg-gray-200 rounded-lg">
            <IoSearch className="text-lg text-[#637381]" />
            <div className=" bg-white h-5 w-6 p-1 rounded-md shadow-md items-center justify-evenly flex ">
              <span>K</span>
            </div>
          </div>
          <div className="h-9 w-9 rounded-full  bg-[#F7F7F7] hover:bg-gray-200   hover:w-[40px] hover:h-[40px]  relative flex items-center justify-center  transition-all duration-200 [ease: cubic-bezier(0.4, 0, 0.2, 1)] ">
            <PiBellSimpleRingingFill className="text-gray-400 text-lg  " />
          </div>

          <div className="relative">
            {/* Gradient border container */}
            <div
              className="w-9 h-9 rounded-full bg-gradient-to-r from-orange-400 to-green-400 p-0.5 shadow-md 
               hover:w-[40px] hover:h-[40px] 
               transition-all duration-300 ease-[cubic-bezier(0.4, 0, 0.2, 1)] 
               animate-spin-slow" // Added animation here
              onClick={toggleDrawer(true)}
            >
              {/* Drawer */}
              <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
                {DrawerList}
              </Drawer>

              {/* Inner avatar container */}
              <div className="w-full h-full rounded-full bg-white p-0.5">
                {/* Your actual avatar content */}
                <span className="h-full w-full rounded-full bg-gray-200 relative flex items-center justify-center">
                  {/* If you want to add an image */}
                  {/* <img src="..." alt="avatar" className="h-full w-full rounded-full object-cover" /> */}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
