// import { useEffect } from "react";
// import io from "socket.io-client";
// import useZusConversation from "../zustand/useZusConversation";

// // const DOMAIN_WHATSAPP_API_UR = "https://app.thebotmode.com";
// const DOMAIN_WHATSAPP_API_UR = "http://localhost:5000";

// const socket = io(`${DOMAIN_WHATSAPP_API_UR}`);

// const useListenMessages = () => {
//   const { messages, setMessages, selectedConversation } = useZusConversation();

//   useEffect(() => {
//     socket.on("newMessage", (newMessage) => {
//       console.log("message from socket:", newMessage);
//       console.log("selectedConversation:", selectedConversation);
//       if (selectedConversation?._id) {
//         // Send selected conversation ID to backend
//         socket.emit("joinConversation", selectedConversation._id);
//       }

//       if (selectedConversation?._id === newMessage.senderId) {
//         setMessages([...messages, newMessage]);
//       }
//     });

//     return () => socket?.off("newMessage");
//   }, [messages, selectedConversation]);
// };

// export default useListenMessages;

import { useEffect, useCallback } from "react";
import { useSocketContext } from "../context/SocketContext";
import useZusConversation from "../zustand/useZusConversation";
import { UseAuthContext } from "../context/AuthContext";
const notificationSound = "/assets/sounds/notification.mp3"; // Direct path from public directory

const useListenMessages = () => {
  const { socket } = useSocketContext(); // Use socket from context
  const { messages, setMessages, selectedConversation } = useZusConversation();
  const { authUser } = UseAuthContext();

  const handleNewMessage = useCallback(
    (newMessage) => {
      console.log("Received new message:", newMessage);

      if (authUser?._id !== newMessage?.senderId) {
        return;
      }

      newMessage.shouldShake = true;
      // const sound = new Audio(notificationSound);
      // sound.play();
      // Add new message to the list if it belongs to the selected conversation
      if (
        selectedConversation?._id &&
        (selectedConversation._id === newMessage.senderId ||
          selectedConversation._id === newMessage.receiverId)
      ) {
        // setMessages((prevMessages) => [...prevMessages, newMessage]);
        setMessages([...messages, newMessage]);
      }
    },
    [socket, selectedConversation, messages]
  );

  useEffect(() => {
    if (!socket) return;

    socket.on("newMessage", handleNewMessage);

    return () => {
      socket.off("newMessage", handleNewMessage);
    };
  }, [socket, handleNewMessage]);

  return null; // This is a hook, no UI to render
};

export default useListenMessages;
