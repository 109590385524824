// import { useEffect } from "react";
// import io from "socket.io-client";
// import useZusConversation from "../zustand/useZusConversation";
// const notificationSound = "/assets/sounds/notification.mp3"; // Direct path from public directory

// // const DOMAIN_WHATSAPP_API_UR = "https://app.thebotmode.com";
// const DOMAIN_WHATSAPP_API_UR = "http://localhost:5000";

// const socket = io(`${DOMAIN_WHATSAPP_API_UR}`);

// const useNewUnseenMessages = () => {
//   const {
//     selectedConversation,
//     setSelectedConversation,
//     conversations,
//     setConversations,
//   } = useZusConversation();
//   useEffect(() => {
//     socket.on("newUnseenMessages", (newUnseenMessages) => {
//       console.log("newUnseenMessages from socket:", newUnseenMessages);
//       // console.log("conversations:", conversations);
//       const sound = new Audio(notificationSound);
//       sound.play();

//       let data = [];

//       if (
//         selectedConversation?._id &&
//         selectedConversation._id === newUnseenMessages._id
//       ) {
//         // setMessages((prevMessages) => [...prevMessages, newUnseenMessages]);
//         // setMessages([...messages, newUnseenMessages]);
//         setSelectedConversation(newUnseenMessages);
//       }

//       data = conversations.map((item) =>
//         item._id === newUnseenMessages._id
//           ? { ...item, ...newUnseenMessages }
//           : item
//       );

//       // console.log("data :", data);

//       setConversations(data);
//       // console.log("newLsConversations:", conversations);
//     });

//     return () => socket?.off("newUnseenMessages");
//   }, [selectedConversation, conversations]);
// };

// export default useNewUnseenMessages;

import { useEffect, useCallback } from "react";
import { useSocketContext } from "../context/SocketContext";
import useZusConversation from "../zustand/useZusConversation";
import { UseAuthContext } from "../context/AuthContext";
const notificationSound = "/assets/sounds/notification.mp3"; // Direct path from public directory

const useNewUnseenMessages = () => {
  const { socket } = useSocketContext(); // Use socket from context
  const {
    selectedConversation,
    setSelectedConversation,
    conversations,
    setConversations,
  } = useZusConversation();

  const { authUser } = UseAuthContext();

  const handleNewMessage = useCallback(
    (newUnseenMessages) => {
      console.log("useNewUnseenMessages:", newUnseenMessages);

      // newUnseenMessages.shouldShake = true;
      // const sound = new Audio(notificationSound);
      const sound = new Audio("/assets/sounds/notification.mp3");
      sound.play();

      let data = [];

      if (authUser?._id !== newUnseenMessages?.senderId) {
        return;
      }

      if (
        selectedConversation?._id &&
        selectedConversation._id === newUnseenMessages._id
      ) {
        setSelectedConversation(newUnseenMessages);
      }

      // Check if newUnseenMessages._id exists in conversations
      const exists = conversations.some(
        (item) => item._id === newUnseenMessages._id
      );

      if (exists) {
        // Update the existing conversation
        data = conversations.map((item) =>
          item._id === newUnseenMessages._id
            ? { ...item, ...newUnseenMessages }
            : item
        );
      } else {
        // Add the new conversation to the array
        data = [...conversations, newUnseenMessages];
      }

      setConversations(data);
    },
    [socket, selectedConversation, conversations]
  );

  useEffect(() => {
    if (!socket) return;

    socket.on("newUnseenMessages", handleNewMessage);

    return () => {
      socket.off("newUnseenMessages", handleNewMessage);
    };
  }, [socket, handleNewMessage]);

  return null; // This is a hook, no UI to render
};

export default useNewUnseenMessages;
