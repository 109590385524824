// import React from "react";
// import SearchInput from "./SearchInput";
// import Conversations from "./Conversations";
// import LogoutButton from "./LogoutButton";

// const SideBar = () => {
//   return (
//     <div className="border-r border-slate-500 p-4 flex flex-col">
//       <SearchInput />
//       <div className="divider px-3 "></div>
//       <Conversations />
//       <LogoutButton />
//     </div>
//   );
// };

// export default SideBar;

// STARTER CODE OF THIS FILE
import React, { useState } from "react";
import SearchInput from "./SearchInput";
import Conversations from "./Conversations";
import AgentQuary from "./AgentQuary";
import { UseAuthContext } from "../../../context/AuthContext";
import ConversationList from "./ConversationList";

const SideBar = () => {
  const { authUser } = UseAuthContext();

  const [searchQuery, setSearchQuery] = useState("");
  const [agentQuery, setAgentQuery] = useState({
    heading: "all",
    subHeading: "",
  });
  return (
    <div className="border-none w-1/4 flex flex-col customScroller bg-[#FDFBF7]">
      <div className="mx-4">
        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </div>

      <AgentQuary agentQuery={agentQuery} setAgentQuery={setAgentQuery} />

      <ConversationList  searchQuery={searchQuery} agentQuery={agentQuery} />
    </div>
  );
};

export default SideBar;
