import React from "react";
import { BsFileEarmarkPdf, BsFillPlayFill } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { IoDocumentText, IoImageSharp, IoPlayCircle } from "react-icons/io5";

function DocumentTemplate({ document, chatClass }) {
  console.log("Document template:", document);
  console.log("Document chatClass:", chatClass);

  const mediaType = document?.mediaType?.toUpperCase();
  let content = null;

  // Determine content based on media type
  // Determine content based on media type
  switch (mediaType) {
    case "IMAGE":
      content = document?.mediaUrl ? (
        <img
          src={document?.mediaUrl}
          className="w-auto h-auto rounded-md"
          alt="template image"
        />
      ) : (
        <IoImageSharp className="h-40 w-80 bg-orange-300 rounded-md" />
      );
      break;

    case "VIDEO":
      content = document?.mediaUrl ? (
        <video width="300" controls muted>
          <source src={document?.mediaUrl} />
        </video>
      ) : (
        <IoPlayCircle className="h-40 w-72 text-blue-600" />
      );
      break;

    case "DOCUMENT":
    case "PDF": // Handle PDFs as documents
      content = document?.mediaUrl ? (
        <div>
          <iframe
            src={document?.mediaUrl}
            className="w-full h-40 border rounded-md"
            title="Document Preview"
            frameBorder="0"
          ></iframe>
          {/* Link to open PDF in a new tab */}
          <a
            href={document?.mediaUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline mt-2 block"
          >
            Open PDF in a new tab
          </a>
          {/* Button to download the PDF */}
          <a
            href={document?.mediaUrl}
            download
            className="bg-blue-600 text-white px-4 py-2 rounded mt-2 inline-block"
          >
            Download PDF
          </a>
        </div>
      ) : (
        <BsFileEarmarkPdf className="h-40 w-72 text-red-400" />
      );
      break;

    case "LOCATION":
      content = <FaLocationDot className="h-40 w-72 text-red-600" />;
      break;

    case "TEXT":
      content = (
        <div className="w-full bg-white p-2">
          <p className="font-bold text-gray-700">{document?.caption}</p>
        </div>
      );
      break;

    case "AUDIO": // Handle audio files
      content = document?.mediaUrl ? (
        <audio controls className="w-full">
          <source src={document?.mediaUrl} type="audio/ogg" />
          Your browser does not support the audio element.
        </audio>
      ) : (
        <BsFillPlayFill className="h-40 w-72 text-purple-600" />
      );
      break;

    default:
      content = (
        <p className="text-gray-500">
          <a
            href={document?.mediaUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Unsupported media type
          </a>
        </p>
      );
      break;
  }

  return (
    <div className={`p-2 rounded-md bg-white shadow-md ${chatClass}`}>
      <div className="rounded-md overflow-hidden">
        <figure className="mb-2">{content}</figure>
        {document?.documentData?.caption && (
          <div className="p-2 text-sm bg-white font-normal overflow-hidden whitespace-pre-wrap">
            <p className="text-gray-800">{document?.documentData?.caption}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default DocumentTemplate;
