import React from "react";
import { TbReload } from "react-icons/tb";

function SearchBar({ width,handlebutton ,isloader}) {



  const handleEndButton =  () => {
    handlebutton();
    console.log("Refresh function has been called");
  }; 
  return (
    <>
      <div
        className={`${
          width ? "w-11/12" : "w-5/6"
        } mt-10 mx-auto flex justify-between items-center`}
      >
        <div>
          <label className="input input-bordered flex items-center gap-2 bg-[#F0F0F0] focus-within:bg-white w-96 focus-within:outline-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4 opacity-70"
            >
              <path
                fillRule="evenodd"
                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                clipRule="evenodd"
              />
            </svg>
            <input
              type="text"
              className="grow placeholder:text-sm placeholder:pb-1 placeholder:font-normal"
              placeholder="Search by campaign name"
            />
          </label>
        </div>
        <button className="btn btn-sm font-normal rounded-md bg-transparent text-black hover:bg-[#d8d7d7] border-none shadow-none"  onClick={handleEndButton}  disabled={isloader} >
          <TbReload className="text-lg"    /> Refresh
        </button>
      </div>
    </>
  );
}

export default SearchBar;
