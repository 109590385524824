import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useAllTags from "../zustand/useAllTags.Zus";
import useZusConversation from "../zustand/useZusConversation";

const UsecreateAndAddTag = () => {
  const [iscreatedandAddedtag, setcreatedandAddedtag] = useState("");
  const [isloader, setloader] = useState(false);
  const { selectedConversation, setSelectedConversation } =
    useZusConversation();

  const createandAddtags = async (updateData) => {
    try {
      setloader(true);

      const response = await axios.post("/api/tags/create", updateData);
      console.log("resposne looks likes this :", response);
      if (response?.status === 200) {
        setcreatedandAddedtag(response.data);

        console.log("createandAddtags:", response?.data?.updateContact);

        // Get the updated tags from the response
        const updatedTags =
          response?.data?.contactTags || selectedConversation.tags;
        // Update only the tags in selectedConversation
        if (updatedTags) {
          setSelectedConversation({
            ...selectedConversation,
            tags: updatedTags,
          });
        }

        toast.success("tag updated!!!");
      }
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setloader(false);
    }
  };

  return { iscreatedandAddedtag, isloader, createandAddtags };
};

const UsegetAlltags = () => {
  // const [isgettags, setgettags] = useState("");
  const { AllListTags, setAllListTags, loadingAllTags, setloadingAllTags } =
    useAllTags();
  // const [isloadergetTags, setloadergeTags] = useState(false);

  const getalltags = async () => {
    try {
      setloadingAllTags(true);

      const response = await axios.get("/api/tags/alltags");

      if (response?.status === 200 || response?.status === 201) {
        setAllListTags(response?.data);
      }
    } catch (error) {
      // toast.error(error.response?.data?.error || error.message);
    } finally {
      setloadingAllTags(false);
    }
  };

  useEffect(() => {
    getalltags();
  }, []);

  return { getalltags };
};

const useDeleteTag = () => {
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteTag = async (tag) => {
    try {
      setIsDeleting(true);

      const response = await axios.delete("/api/tags/deleteTag", {
        data: { tag },
      });

      if (response?.status === 200) {
        toast.success("Tag deleted successfully!");
        return true; // Success
      }
    } catch (error) {
      toast.error(error.response?.data?.error || "Failed to delete tag.");
      return false; // Failure
    } finally {
      setIsDeleting(false);
    }
  };

  return { deleteTag, isDeleting };
};

const useRemoveTags = () => {
  const [isloading, setloading] = useState(false);
  const { selectedConversation, setSelectedConversation } =
    useZusConversation();

  const RemoveContact = async (updateData) => {
    try {
      setloading(true);
      const response = await axios.post("/api/tags/removetag", updateData);

      if (response.status === 200) {
        toast.success("tags removed!!");

        console.log("createandAddtags:", response?.data?.contact);

        // Get the updated tags from the response
        const updatedTags =
          response?.data?.contact?.tags || selectedConversation.tags;
        // Update only the tags in selectedConversation
        if (updatedTags) {
          setSelectedConversation({
            ...selectedConversation,
            tags: updatedTags,
          });
        }
      }
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setloading(false);
    }
  };

  return { isloading, RemoveContact };
};

export { UsecreateAndAddTag, UsegetAlltags, useDeleteTag, useRemoveTags };
