import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useAutoResponse, useFetchStatus, useShowautoResponseTemplate } from "../../hooks/useManagetemplate";

import { TextComponent } from "../../components/template/CreateTemplate";
import { Autocomplete, TextField } from "@mui/material";
import { RiWhatsappFill } from "react-icons/ri";
import { IoDocumentText, IoImageSharp, IoPlayCircle } from "react-icons/io5";
import { FaArrowRightLong, FaArrowUpRightFromSquare } from "react-icons/fa6";
import { MdOutlineCall } from "react-icons/md";
import { HiLink } from "react-icons/hi2";
import { FaLocationDot } from "react-icons/fa6";
import BulkMessageBox from '../../components/BulkMessageBox';
function UserConcerns() {
    const { isloading, isConfiguration, newConfiguration } = useAutoResponse()
    const [isuserconcern, setuserConcern] = useState(false);

    const handleUserConcer = () => {
        setuserConcern(!isuserconcern);
    };

    const storedData = useRef(null);


    //trigger the onchnage if data and collect data 
    const handleautoresponsTemplate = useCallback((newData) => {
        // console.log("data in template single message looks like this:", newData);
        storedData.current = newData;
    }, []);
    console.log("response looks likes this in user concern page looks likes this :", storedData.current);

    // trigger the save configuration
    const handlesaveConfigButton = useCallback(async () => {
        if (!storedData.current) {
            console.error("No data stored ");
            return;
        }
        const id = storedData.current.id;
        const mediaurl = storedData.current.mediaurl;
        const parameters = storedData.current.parameters;
        console.log("selected data looks likes this :", id, mediaurl, parameters)
        await newConfiguration({
            id,
            mediaurl,
            parameters,
        });
        console.log("contact in call back function looks likes this :", id, mediaurl, parameters);
    }, [newConfiguration]);


    const data = {
        button1: "save Configureation",
        button2: "cancel Configuration"
    }

    const { isloader, isData, refreshData } = useShowautoResponseTemplate();

    const [isdisplayTemplate, setDisplayTemplate] = useState(null);

    const handletoggleData = () => {
        setDisplayTemplate(!isdisplayTemplate);
        refreshData();
    };
    console.log("data looks like this:", isdisplayTemplate);

    const [isheader, setheader] = useState("");
    const [isbody, setbody] = useState("");
    const [isfooter, setfooter] = useState("");
    const [buttons, setbuttons] = useState([]);
    const [istitle, setTitle] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [selectedmedia, setselectedmedia] = useState(null);
    const [istemplateid, settemplateid] = useState("");
    useEffect(() => {
        if (isData?.configData?.components) {
            const components = isData.configData.components;
            let templateFormat = "";
            let headerText = "";
            let bodyText = "";
            let footer = "";
            let totalbutton = [];
    
            for (let j = 0; j < components.length; j++) {
                const component = components[j];
    
                console.log("Component looks like this:", component);
    
                if (component.type === "HEADER" && component.format) {
                    templateFormat = component.format;
    
                    if (component.format === "Text") {
                        headerText = component.text;
                        console.log("Header looks like this in map function:", headerText);
                    }
                } else if (component.type === "BODY" && component.text) {
                    bodyText = component.text;
                } else if (component.type === "FOOTER" && component.text) {
                    footer = component.text;
                } else if (component.type === "BUTTONS" && component.buttons) {
                    totalbutton = totalbutton.concat(component.buttons);
                }
            }
    
            // Update the state with the extracted values
            setheader(templateFormat);
            setbody(bodyText);
            setfooter(footer);
            setbuttons(totalbutton);
            setTitle(headerText); // Assuming Title is set from headerText
        }
    }, [isData?.configData]);

    let content = null;

    if (isheader?.toUpperCase() === "IMAGE") {
        content = <IoImageSharp className="h-40 w-80 bg-orange-300 rounded-md" />;
    } else if (isheader?.toUpperCase() === "VIDEO") {
        content = (
            <div className="text-4xl text-blue-600 h-40 w-72 rounded-md">
                <IoPlayCircle className="h-40 w-72" />
            </div>
        );
    } else if (isheader?.toUpperCase() === "DOCUMENT") {
        content = <IoDocumentText className="h-40 w-72 text-red-400 " />;
    } else if (isheader?.toUpperCase() === "LOCATION") {
        content = (
            <div className="text-3xl text-red-600 h-40 w-72 rounded-md">
                <FaLocationDot className="h-40 w-72" />
            </div>
        );
    } else if (isheader?.toUpperCase() === "TEXT") {
        content = (
            <div className=" w-full bg-white">
                <p className="font-whatsapp-bold font-bold ml-2">
                    {istitle.toLowerCase()}
                </p>
            </div>
        );
    }
    return (
        <>
            <div className='w-full h-auto text-2xl mt-4 mx-auto ml-4'>User concern</div>
            <br />

            <div className='mx-auto  shadow-lg mt-6 ml-4'>

                <p className='mx-auto text-xl mt-3'>for users concerns send a pre-approved template</p>
                <div className='flex gap-4 mt-4'>


                    <p className='text-xl '> user Concern</p>
                    <label className="relative inline-flex items-center cursor-pointer">

                        <input
                            type="checkbox"
                            className="sr-only peer bg-white"
                            checked={isuserconcern}
                            onChange={handleUserConcer}
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                    <p className='text-xl '>show pre-set template</p>
                    <label className="relative inline-flex items-center cursor-pointer">

                        <input
                            type="checkbox"
                            className="sr-only peer bg-white"
                            checked={isdisplayTemplate}
                            onChange={handletoggleData}
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>


                </div>
                <div className='mx-auto flex justify-center'>
                    {isuserconcern === true ? (
                        <BulkMessageBox data={data} onsend={handleautoresponsTemplate} trigger={handlesaveConfigButton} />
                    ) : (
                        <div></div>
                    )}
                </div>
                <div className='mx-auto flex justify-center'>
                    {isdisplayTemplate ? (
                        <div>
                            {isdisplayTemplate === null || isdisplayTemplate === undefined || !isdisplayTemplate ? (
                                <div className="w-80 h-80 bg-gray-100 flex justify-center items-center  rounded-md overflow-hidden mb-2">
                                    <span>Select your template</span>
                                </div>
                            ) : (
                                <div className="w-80 card mx-auto bg-white text-black rounded-md shadow-md p-5">
                                    <div className="p-1 rounded-md bg-transparent">
                                        <div className="w-full rounded-md overflow-hidden mb-2">
                                            <figure>{content}</figure>
                                            <div className="w-full p-2 text-sm bg-white font--mt-1">
                                                <p className="font-whatsapp">{isbody}</p>
                                                <div className="text-xs text-zinc-500 font-normal">
                                                    {isfooter}
                                                </div>
                                            </div>
                                        </div>
                                        {buttons.map((button, index) => (
                                            <div key={index}>
                                                {button.type === "url" && (
                                                    <div className="h-9 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                                                        <FaArrowRightLong className="text-sm" /> {button.text}
                                                    </div>
                                                )}
                                                {button.type === "PHONE_NUMBER" && (
                                                    <div className="h-9 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                                                        <MdOutlineCall className="text-sm" /> {button.text}
                                                    </div>
                                                )}
                                                {button.type === "QUICK_REPLY" && (
                                                    <div className="h-9 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                                                        <HiLink className="text-sm" /> {button.text}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>

            </div>
        </>
    )
}

export default UserConcerns;