import React from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import MyProSidebar from "./MyProSidebar";
import { useGetProfile } from "../../../hooks/useProfile";

export const MyProSidebarProvider = ({ children }) => {
  // get meta user details
  useGetProfile();
  return (
    <ProSidebarProvider>
      <div
        style={{
          display: "flex",
          position:"relative"
        }}
      >
        <MyProSidebar />
        {children}
      </div>
    </ProSidebarProvider>
  );
};

export default MyProSidebarProvider;
