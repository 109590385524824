// import axios from "axios";
// import { useEffect, useState } from "react";
// import useZusConversation from "../zustand/useZusConversation";
// import toast from "react-hot-toast";

// const useGetConversations = () => {
//   const { loadingCNV, setLoadingCNV, conversations, setConversations } =
//     useZusConversation();

//   useEffect(() => {
//     setLoadingCNV(true);

//     const getConversations = async () => {
//       try {
//         const res = axios.get("/api/client/users");

//         const data = (await res).data;

//         // console.log(data.data);

//         setConversations(data);
//       } catch (error) {
//         toast.error(error.response?.data?.error || error.message);
//       } finally {
//         setLoadingCNV(false);
//       }
//     };

//     getConversations();
//   }, []);

//   return { loadingCNV, conversations, setConversations };
// };

// export default useGetConversations;

import axios from "axios";
import { useEffect, useState } from "react";
import useZusConversation from "../zustand/useZusConversation";
import toast from "react-hot-toast";

// const useGetConversations = () => {
//   const { loadingCNV, setLoadingCNV, conversations, setConversations } =
//     useZusConversation();

//   const [olderLoading, setOlderLoading] = useState(false);
//   const [hasMore, setHasMore] = useState(true); // Track if more conversations are available
//   const [skip, setSkip] = useState(0); // Track how many conversations have been loaded
//   const limit = 500;

//   // Function to fetch conversations with pagination
//   const fetchConversations = async (isInitialLoad = true) => {
//     try {
//       if (isInitialLoad) setLoadingCNV(true);
//       else setOlderLoading(true);

//       const res = await axios.get("/api/client/users", {
//         params: { skip, limit },
//       });

//       const data = res.data;

//       // Update conversations: add to the end for older data
//       setConversations(isInitialLoad ? data : [...conversations, ...data]);

//       setSkip((prev) => prev + data.length); // Update skip count
//       setHasMore(data.length === limit); // Check if more data exists
//     } catch (error) {
//       toast.error(error.response?.data?.error || error.message);
//     } finally {
//       if (isInitialLoad) setLoadingCNV(false);
//       else setOlderLoading(false);
//     }
//   };

//   // Initial load
//   useEffect(() => {
//     fetchConversations(true);
//   }, []);
//   // Initial load

//   // Load more conversations (e.g., when scrolling)
//   const fetchOlderConversations = () => {
//     if (!hasMore || olderLoading) return; // Prevent unnecessary calls
//     fetchConversations(false);
//   };

//   useEffect(() => {
//     fetchOlderConversations();
//   }, [skip]);

//   console.log("hasMore:", hasMore);

//   return {
//     loadingCNV,
//     conversations,
//     olderLoading,
//     hasMore,
//     fetchOlderConversations,
//   };
// };

const useGetConversations = () => {
  const { loadingCNV, setLoadingCNV, conversations, setConversations } =
    useZusConversation();

  const [olderLoading, setOlderLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if more conversations are available
  const [skip, setSkip] = useState(0); // Track how many conversations have been loaded
  const [isInitialLoad, setInitialLoad] = useState(true); // Track how many conversations have been loaded
  let limit = 500;

  // Function to fetch conversations with pagination
  const fetchConversations = async () => {
    try {
      if (isInitialLoad) setLoadingCNV(true);
      else setOlderLoading(true);

      const res = await axios.get("/api/client/users", {
        params: { skip, limit },
      });

      const { data, totalCount, remaining } = res.data;

      if (data.length > 0) {
        setConversations(isInitialLoad ? data : [...conversations, ...data]);
        setSkip((prev) => prev + data.length); // Update skip count
        setHasMore(remaining > 0); // Check if more data exists
      } else {
        setHasMore(false); // No more data to fetch
      }
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      if (isInitialLoad) setLoadingCNV(false);
      else setOlderLoading(false);
    }
  };

  // Initial load
  useEffect(() => {
    fetchConversations(true);
    setInitialLoad(false);
  }, []);

  // Load more conversations (e.g., when scrolling)
  const fetchOlderConversations = () => {
    if (!hasMore || olderLoading || isInitialLoad) return; // Prevent unnecessary calls
    fetchConversations(false);
  };

  // remaining conversations
  useEffect(() => {
    fetchOlderConversations();
  }, [skip]);

  return {
    loadingCNV,
    conversations,
    olderLoading,
    hasMore,
    fetchOlderConversations,
  };
};

export default useGetConversations;
