// import React, { useEffect, useRef, useState } from "react";
// import Message from "./Message";
// import useGetMessage from "../../../hooks/useGetMessage";
// import useListenMessages from "../../../hooks/useListenMessages";
// import MessageSkeleton from "../../skeletons/MessageSkeleton";

// const Messages = () => {
//   const { loading, messages, olderLoading, fetchOlderMessages, hasMore } =
//     useGetMessage();

//   useListenMessages();

//   // const lastMessageRef = useRef();
//   const containerRef = useRef();

//   useEffect(() => {
//     if (containerRef.current) {
//       setTimeout(() => {
//         containerRef.current.scrollTo({
//           top: containerRef.current.scrollHeight,
//           behavior: "smooth", // Smooth scrolling behavior
//         });
//       }, 100); // Wait till render is complete
//     }
//   }, [loading]);

//   const handleScrollBaseChatPreLoad = async () => {
//     if (containerRef.current.scrollTop === 0) {
//       console.log("You have reached the top!"); // When the user reaches the top
//       const previousScrollHeight = containerRef.current.scrollHeight;

//       await fetchOlderMessages(); // Fetch older messages

//       // Adjust scroll position to maintain user context
//       const newScrollHeight = containerRef.current.scrollHeight;
//       // const scrollOffset = newScrollHeight / 100;
//       const scrollOffset = previousScrollHeight - newScrollHeight;

//       console.log("scrollOffset", scrollOffset);

//       containerRef.current.scrollTo({
//         top: scrollOffset,
//         behavior: "smooth", // Smooth scrolling behavior
//       });
//     }
//   };

//   const lastMessageRef = useRef();

//   useEffect(() => {
//     setTimeout(() => {
//       lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
//     }, 100);
//   }, [messages]);

//   return (
//     <div
//       className="px-4 flex-1 overflow-auto"
//       ref={containerRef}
//       onScroll={handleScrollBaseChatPreLoad}
//     >
//       {/* if no more  conversations to fetch show this message*/}
//       {!hasMore && (
//         <p className="text-center text-gray-500">No more conversations!</p>
//       )}

//       {/* Loading older messages */}
//       {olderLoading && <MessageSkeleton />}

//       {loading && [...Array(4)].map((_, idx) => <MessageSkeleton key={idx} />)}
//       {/* If loading competed then run these */}
//       {!loading &&
//         Array.isArray(messages) &&
//         messages.length > 0 &&
//         messages.map((msg) => (
//           <div key={msg._id} ref={lastMessageRef}>
//             <Message msg={msg} />
//           </div>
//         ))}
//       {!loading && messages.length === 0 && (
//         <p className="text-center text-gray-500">
//           Send a Template Message to start the conversation
//         </p>
//       )}
//     </div>
//   );
// };

// export default Messages;

import React, { useEffect, useRef, useState } from "react";
import Message from "./Message";
import useGetMessage from "../../../hooks/useGetMessage";
import useListenMessages from "../../../hooks/useListenMessages";
import MessageSkeleton from "../../skeletons/MessageSkeleton";
import useZusConversation from "../../../zustand/useZusConversation";

const Messages = () => {
  const { loading, olderLoading, fetchOlderMessages, hasMore } =
    useGetMessage();

  const { messages } = useZusConversation();

  useListenMessages();

  const containerRef = useRef();
  const [isFetchingOlder, setIsFetchingOlder] = useState(false);

  // Scroll to the bottom on initial load
  useEffect(() => {
    if (!isFetchingOlder && containerRef.current) {
      setTimeout(() => {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [loading]);

  const handleScrollBaseChatPreLoad = async () => {
    if (containerRef.current.scrollTop === 0 && !olderLoading && hasMore) {
      const previousScrollHeight = containerRef.current.scrollHeight;
      setIsFetchingOlder(true); // Set fetching state

      await fetchOlderMessages(); // Fetch older messages

      const newScrollHeight = containerRef.current.scrollHeight;

      // Adjust scroll position minimally (20px offset)
      containerRef.current.scrollTo({
        // top: newScrollHeight - previousScrollHeight + 20,
        top: 200,
        // behavior: "auto",
        behavior: "smooth",
      });

      setIsFetchingOlder(false); // Reset fetching state
    }
  };

  // Scroll to the last message when new messages are added
  const lastMessageRef = useRef();
  useEffect(() => {
    if (!isFetchingOlder) {
      setTimeout(() => {
        lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [messages]);

  return (
    <div
      className="px-4 flex-1 overflow-auto"
      ref={containerRef}
      onScroll={handleScrollBaseChatPreLoad}
    >
      {/* If no more conversations to fetch show this message */}
      {!hasMore && (
        <p className="text-center text-gray-500">No more conversations!</p>
      )}

      {/* Loading older messages */}
      {olderLoading && <MessageSkeleton />}

      {loading && [...Array(4)].map((_, idx) => <MessageSkeleton key={idx} />)}

      {/* Display messages */}
      {!loading &&
        Array.isArray(messages) &&
        messages.length > 0 &&
        messages.map((msg, idx) => (
          <div
            key={msg._id}
            ref={idx === messages.length - 1 ? lastMessageRef : null}
          >
            <Message msg={msg} />
          </div>
        ))}

      {!loading && messages.length === 0 && (
        <p className="text-center text-gray-500">
          Send a Template Message to start the conversation
        </p>
      )}
    </div>
  );
};

export default Messages;
