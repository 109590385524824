import React, { useEffect } from "react";
import { UseAuthContext } from "../context/AuthContext";

const Header = () => {
  useEffect(() => {
    // Load the Facebook SDK script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "991474582322820",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v20.0",
      });
    };
  }, []);

  const launchWhatsAppSignup = () => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const code = response.authResponse.code;
          // Use this token to call the debug_token API and get the shared WABA's ID
          // console.log("Client's Meta Response: ", code);
          console.log(response);

          if (code) {
            window.location.href = `http://localhost:5000/meta/login`;
          }
          localStorage.setItem("metaRes", JSON.stringify(response));
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "7721872334516274", // configuration ID obtained in the previous step goes here
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true,
        extras: {
          setup: {
            // Prefilled data can go here
          },
        },
      }
    );
  };

  const { authUser } = UseAuthContext();
  return (
    <div className="flex justify-between items-center bg-white px-5 py-3 shadow sticky top-0 z-50">
      <div className="text-lg font-semibold">Project Name</div>
      <div className="flex justify-center gap-2 items-center">
        <div className="font-semibold">WhatsApp Business API Status : </div>
        {authUser.isMetaConnected === "Connected" ? (
          <span className="text-green-500 text-base font-semibold">
            {authUser.isMetaConnected}
          </span>
        ) : (
          <div>
            <span className="text-red-600 font-semibold">PENDING</span>
            <button
              className="btn btn-sm border-none bg-[#10C110] text-white mx-2"
              // onClick={launchWhatsAppSignup}
            >
              Apply Now
            </button>
          </div>
        )}
      </div>
      <div>
        Current Plan: <span className="text-green-800 font-semibold">NONE</span>{" "}
        {/* <button className="btn btn-sm border-none bg-[#600E7D] text-white ms-2">
          Upgrade Now
        </button> */}
      </div>
    </div>
  );
};

export default Header;
