import React, { Component, useCallback, useState } from "react";
import {
  FaArrowRightLong,
  FaArrowUpRightFromSquare,
  FaRegStar,
  FaLocationDot,
} from "react-icons/fa6";
import { IoImageSharp, IoDocumentText, IoPlayCircle } from "react-icons/io5";
import { MdOutlineContentCopy, MdDelete, MdOutlineCall } from "react-icons/md";
import { RiDeleteBin5Fill, RiWhatsappFill } from "react-icons/ri";
import { HiLink } from "react-icons/hi2";
import { useDeleteTemplate } from "../../hooks/useManagetemplate";
import toast from "react-hot-toast";
function List({
  row1,
  row2,
  row3,
  row4,
  row5,
  row6,
  boxContent2,
  boxContent3,
  boxContent4,
  boxContent5,
  buttons,
  header,
  time,
  cardsaArray,
  CAROUSELtype
}) {
  // const { isLoading, isDeleted } = useDeleteTemplate();
  // const [showbox, setshowbox] = useState(false);
  // const handleshowDeleteButton = () => {
  // const timestamp = time.split('T')[0];
  // console.log(timestamp); // Output: 2024-07-13

  // };

  // console.log("header in list looks likes this :",header)
  const { isLoading, isDeleted, deleteTemplate } = useDeleteTemplate();



  const handleFormatDate = useCallback((date) => {
    const getdate = new Date(date);

    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return getdate.toLocaleString("en-US", options);
  }, []);


  // const HandleconfirmDeletebutton = async (id) => {
  //   console.log("template id looks like:", id);
  //   await deleteTemplate({ id });
  //   document.getElementById("deletemedia").close();
  // };

  const handleDeleteClick = (e, row5) => {
    e.stopPropagation();
    console.log("Delete clicked for ID:", row5);
    document.getElementById(`delete-modal-${row5}`).showModal();
  };

  const handleConfirmDelete = async (id, name) => {
    if (!id) {
      toast.error("Invalid template ID");
      return;
    }
    console.log("Confirming delete for ID:", id);
    try {
      await deleteTemplate({ id, name });
    } catch (error) {
      toast.error(`Error deleting template ${id}`);
    }
    document.getElementById(`delete-modal-${id}`).close();
  };

  // Determine the content to render based on row4
  let content;
  if (row4?.toUpperCase() === "IMAGE") {
    content = <IoImageSharp className="h-40 w-72 bg-orange-300" />;
  } else if (row4?.toUpperCase() === "VIDEO") {
    content = (
      <div className="text-4xl text-blue-600">
        <IoPlayCircle className="h-40 w-72" />
      </div>
    );
  } else if (row4?.toUpperCase() === "DOCUMENT") {
    content = <IoDocumentText className="h-40 w-72 text-red-400" />;
  } else if (row4?.toUpperCase() === "LOCATION") {
    content = (
      <div className="text-4xl text-red-600 h-40 w-72">
        <FaLocationDot />
      </div>
    );
  } else if (row4?.toUpperCase() === "TEXT") {
    content = <div className="font-bold">{header}</div>;
  }

  // let ArraycardData = cardsaArray?.components;

  const openDialog = () => {
    document.getElementById(`box-${row1}`).showModal();
  };

  return (
    <>
      <tbody
        className=" w-auto bg-gray-50 border text-sm font-normal gap-y-3 "
        // onClick={() => document.getElementById("box").showModal()}
      >
        <tr className="text-center h-16 w-auto mt-5 cursor-pointer">
          <td onClick={openDialog}>{row1}</td>
          <td>{row2}</td>
          {/* <td className={`text-${row3.toLowerCase()}`}>{row3}</td> */}
          {row3 === "APPROVED" && <td className="text-green-700">{row3}</td>}
          {row3 === "PENDING" && <td className="text-orange-300">{row3}</td>}
          {row3 === "REJECTED" && <td className="text-red-500">{row3}</td>}
          {/* {CAROUSELtype ? <td>{CAROUSELtype}</td> : <td>{row4}</td> || <td>text</td>} */}
          {CAROUSELtype ? <td>{CAROUSELtype}</td> : row4 ? <td>{row4}</td> : <td>text</td>}

          {/* <td className="flex justify-center items-center h-16">
          <div className="font-semibold border-none rounded-2xl h-5 w-14 bg-[#28C152] text-white">
            {row5}
          </div>
        </td> */}
          <td>{handleFormatDate(time)}</td>
          <td className="flex gap-4 text-gray-500 text-xl items-center justify-center h-16">
            <FaRegStar />
            {/* <MdOutlineContentCopy /> */}
            <MdDelete
              onClick={(e) => handleDeleteClick(e, row5)}
              className="cursor-pointer"
            />

            <dialog id={`delete-modal-${row5}`} className="modal">
              <div className="modal-box">
                <form method="dialog">
                  <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                    ✕
                  </button>
                </form>

                <div className="mx-auto flex justify-center">
                  <RiDeleteBin5Fill className="text-4xl text-gray-500" />
                </div>
                <p className="mx-auto text-lg text-center p-3">
                  Are you sure you want to delete{" "}
                  <span className="font-bold text-gray-400">{row1}</span>{" "}
                  Template?
                </p>
                <button
                  className="btn btn-sm bg-[#DC2C2B] text-white border-none rounded-md hover:bg-[#DC2C2B]"
                  onClick={() => handleConfirmDelete(row5, row1)}
                  disabled={isLoading}
                >
                  <RiDeleteBin5Fill className="text-lg text-white" />
                  {isLoading ? (
                    <span className="gap-1 text-lg">
                      Deleting
                      <span className="loading loading-dots"></span>
                    </span>
                  ) : (
                    <span className="gap-1 text-lg">Yes, I'm sure</span>
                  )}
                </button>
              </div>
            </dialog>
          </td>
        </tr>

        {/* Dialog */}
        <dialog id={`box-${row1}`} className="bg-white p-10 mx-auto h-screen">
          {cardsaArray ? (
            <div className="card w-80  mx-auto bg-white text-black rounded-md shadow-md p-5 relative ">
              <RiWhatsappFill className="text-green-500 text-4xl absolute top-[-16px] left-[-14px]" />
              <div className="h-20 w-full shadow-md  rounded-md  mt-2 text-start ">
                <span className="ml-2"> {boxContent2}</span>
              </div>
              <div className=" card w-full  mx-auto bg-white text-black rounded-md shadow-md  relative flex-row gap-2 overflow-auto mt-2 snap-mandatory snap-x  ">
                {/* <div className="h-20 w-auto ">{boxContent2}</div> */}
                {cardsaArray?.map((data, index) => (
                  <div key={index} className="snap-center">
                    {data.components?.map((component, i) => (
                      <div key={i}>
                        {/* <div className="h-40 w-72 text-xl"> */}
                        {component.type === "HEADER" && (
                          <div>
                            {component.format === "IMAGE" && (
                              <div className="flex h-40 w-72 text-xl">
                                <IoImageSharp className="h-40 w-72 bg-orange-300" />
                                {component.text}
                              </div>
                            )}
                            {component.format === "VIDEO" && (
                              <div className="flex h-40 w-72 text-xl">
                              <IoPlayCircle className="h-40 w-72 text-blue-600" />
                              {component.text}
                            </div>
                            )}
                          </div>
                        )}
                        {/* </div> */}
                        {component.type === "BODY" && (
                          <div className="card-body pt-2 pb-0 pr-4 pl-4">
                            <p className="text-start whitespace-pre-wrap">{component.text}</p>
                          </div>
                        )}

                        {component.type === "BUTTONS" && (
                          <div>
                            {component.buttons.map((button, INDEX) => (
                              <div key={INDEX}>
                                {button.type === "url" && (
                                  <div className="h-10 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                                    <FaArrowRightLong className="text-sm" />{" "}
                                    {button.text}
                                  </div>
                                )}
                                {button.type === "PHONE_NUMBER" && (
                                  <div className="h-10 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                                    <MdOutlineCall className="text-sm" />{" "}
                                    {button.text}
                                  </div>
                                )}
                                {button.type === "QUICK_REPLY" && (
                                  <div className="h-10 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                                    <HiLink className="text-sm" /> {button.text}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <div className="card w-80 mx-auto bg-white text-black rounded-md shadow-md p-5 relative">
                <RiWhatsappFill className="text-green-500 text-4xl absolute top-[-16px] left-[-14px]" />
                <figure>
                  {row4 && <div className=" rounded-md">{content}</div>}
                </figure>
                <div className="card-body pt-2 pb-0 pr-4 pl-4">
                  <p className="text-start whitespace-pre-wrap">{boxContent2}</p>
                  <p className="text-start ">{boxContent3}</p>
                  <p className="text-start text-gray-400 ">{boxContent4}</p>
                  <p className="text-start text-gray-400">{boxContent5}</p>
                  <div className="h-6 w-64 bg-white text-[#4C9EDC] flex justify-center items-center gap-2">
                    {/* <FaArrowUpRightFromSquare className="text-sm" />
              View properties */}
                  </div>
                </div>
              </div>
              {buttons.map((button, index) => (
                <div key={index}>
                  {button.type === "url" && (
                    <div className="h-10 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                      <FaArrowRightLong className="text-sm" /> {button.text}
                    </div>
                  )}
                  {button.type === "PHONE_NUMBER" && (
                    <div className="h-10 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                      <MdOutlineCall className="text-sm" /> {button.text}
                    </div>
                  )}
                  {button.type === "QUICK_REPLY" && (
                    <div className="h-10 w-full mt-2 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2">
                      <HiLink className="text-sm" /> {button.text}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          <form method="dialog">
            <button className="btn btn-sm text-black btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
        </dialog>
      </tbody>
    </>
  );
}

export default List;
