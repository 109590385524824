import { useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { FaGoogle } from "react-icons/fa";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { MdVerified } from "react-icons/md";
import useSignUp from "../../hooks/useSignUp";
import { Link } from "react-router-dom";

const SignUp = () => {
  const [inputs, setInputs] = useState({
    fullName: "",
    email: "",
    number: "",
    password: "",
    confirmPass: "",
  });

  //useSign context For send data to api
  const { loading, signup } = useSignUp();

  const [showPassword, setShowPassword] = useState(false);

  const hadleSubmit = async (e) => {
    e.preventDefault();
    await signup(inputs);
  };


  const DOMAIN_WHATSAPP_API_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:5000"
    : "https://app.thebotmode.com";

  console.log("URL :", DOMAIN_WHATSAPP_API_URL);

  const handleGoogleAuth = async () => {
    window.location.href = `${DOMAIN_WHATSAPP_API_URL}/api/auth/google`;
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div className="flex box-border h-[100vh] relative">
      <div className="w-[42%] bg-[#EBF5F3]">
        <div className="h-full flex justify-center items-center">
          <img
            src="/assets/login&signupLogo.png"
            alt=""
            width={300}
            height={200}
          />
        </div>

        {/* <div className="pt-16 ps-10 text-4xl font-semibold tracking-wider">
          <span className="text-green-500">T</span>
          <span className="text-gray-700">HE</span>
          <span className="text-green-500">B</span>
          <span className="text-gray-700">OT</span>
          <span className="text-green-500">M</span>
          <span className="text-gray-700">ODE</span>
        </div> */}
        {/* <div className="mt-20 ps-16">
          <h1 className="text-[#0A474C] text-[28px] font-semibold">
            Start your 14 day FREE Trial Now
          </h1>
          <div className="flex mt-6 items-center">
            <div className="text-xl text-green-400">
              <MdVerified />
            </div>
            <div className="font-[400] text-[16px] ps-4">
              FREE WhatsApp Business API Verification
            </div>
          </div>
          <div className="flex mt-4 items-center">
            <div className="text-xl text-green-400">
              <MdVerified />
            </div>
            <div className="font-[400] text-[16px] ps-4">
              Unlimited Monthly Active Users on new plans
            </div>
          </div>
          <div className="flex mt-4 items-center">
            <div className="text-xl text-green-400">
              <MdVerified />
            </div>
            <div className="font-[400] text-[16px] ps-4">
              1000 FREE conversations/month on all plans{" "}
            </div>
          </div>
          <div className="flex mt-4 items-center">
            <div className="text-xl text-green-400">
              <MdVerified />
            </div>
            <div className="font-[400] text-[16px] ps-4">
              Access all Basic Plan Features in your Free Trial
            </div>
          </div>
        </div>
        <div className="mt-[14vh] text-center text-base text-gray-500">
          <h3>Trusted by 1500+ Brands</h3>
          <div className="flex justify-center">
            <img
              src="/assets/Shopify-Logo.png"
              width={100}
              height={50}
              alt=""
            />
            <img
              className="h-9 w-36 mt-4 ms-4"
              src="/assets/shiprocket.png"
              alt=""
            />
            <img
              className="h-7 w-[50px] mt-5 ms-[-2px]"
              src="/assets/fig.png"
              alt=""
            />
            <img
              src="/assets/Razorpay_logo.svg"
              width={100}
              height={50}
              alt=""
            />
          </div>
        </div> */}
      </div>
      <div className="w-[58%] bg-white shadow-sm rounded py-10 overflow-y-auto text-center relative">
        <div className="w-1/2 mx-auto">
          <div className="text-gray-500 font-medium absolute top-14 right-16">
            Already a member ?{" "}
            <Link
              to="/login"
              className="font-bold cursor-pointer text-[#0A474C]"
            >
              Log in
            </Link>
          </div>
          <h1 className="text-2xl font-bold leading-7 tracking-wide mt-24 mb-4">
            Create Your THEBOTMODE Account
          </h1>
          <p className="text-gray-500 mb-5 px-3">
            Fill in the details below to start your 14 Day FREE trial. No Credit
            Card needed.
          </p>
          <button
            className="btn w-full text-lg bg-[#2A75F3] border-none text-white hover:bg-blue-700"
            onClick={handleGoogleAuth}
          >
            <span>
              <FaGoogle />
            </span>
            SignUp with Google
          </button>
          <div className="divider divider-neutral opacity-[0.3]">
            <span className="font-light text-xs text-gray-700">OR</span>
          </div>

          <form onSubmit={hadleSubmit}>
            <Box
              component="form"
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "gray",
                  borderWidth: "2px",
                  borderRadius: "8px",
                },
              }}
              autoComplete="on"
            >
              <div className="mb-5">
                <TextField
                  value={inputs.fullName}
                  onChange={(e) =>
                    setInputs({ ...inputs, fullName: e.target.value })
                  }
                  sx={{
                    width: "100%",
                  }}
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                />
              </div>
              <div className="mb-5">
                <TextField
                  value={inputs.email}
                  onChange={(e) =>
                    setInputs({ ...inputs, email: e.target.value })
                  }
                  sx={{ colors: "#808080", width: "100%" }}
                  id="outlined-basic"
                  label="Email"
                  type="email"
                  variant="outlined"
                />
              </div>
              <div className="mb-5">
                <TextField
                  value={inputs.number}
                  onChange={(e) =>
                    setInputs({ ...inputs, number: e.target.value })
                  }
                  sx={{ colors: "#808080", width: "100%" }}
                  id="outlined-basic"
                  label="Persional WhatsApp Number"
                  variant="outlined"
                  type="text"
                />
              </div>
              <div className="mb-5">
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    value={inputs.password}
                    onChange={(e) =>
                      setInputs({ ...inputs, password: e.target.value })
                    }
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {!showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </div>
              <div className="mb-10">
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    value={inputs.confirmPass}
                    onChange={(e) =>
                      setInputs({ ...inputs, confirmPass: e.target.value })
                    }
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {!showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                </FormControl>
              </div>
            </Box>
            <div className="mb-4 relative">
              <label className="flex justify-center items-center gap-1">
                <input
                  className="checkbox checkbox-xs checkbox-success"
                  type="checkbox"
                  id="terms"
                  name="terms"
                  required
                />
                <div className="text-gray-500">
                  {" "}
                  I accept the{" "}
                  <a
                    href="https://thebotmode.com/privacy-policy/"
                    target="_blank"
                    className="hover:underline text-[#0A474C]"
                  >
                    Privacy & Policy
                  </a>
                  {"  "}
                  and {"  "}
                  <a
                    href="https://thebotmode.com/terms-condition/"
                    target="_blank"
                    className="hover:underline text-[#0A474C]"
                  >
                    Terms & Conditions
                  </a>
                  .
                </div>
              </label>
            </div>

            <div>
              <button
                className="btn w-full bg-[#0A474C] text-white tracking-wider hover:bg-[#073135]"
                disabled={loading}
              >
                {loading ? (
                  <span className="loading loading-dots"></span>
                ) : (
                  "Sign Up"
                )}
              </button>
            </div>
          </form>
          <div className="text-gray-400 my-4">
            The Google{" "}
            <span className="text-[#0A474C]">
              <a
                className="hover:underline"
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>{" "}
            and{" "}
            <span className="text-[#0A474C]">
              <a
                className="hover:underline"
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </span>{" "}
            apply.
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
