import React from "react";
import { Panel } from "reactflow";
import { FaArrowLeft } from "react-icons/fa";
import { HiPencil } from "react-icons/hi2";

function TopbarFlow({ saveChanges }) {
  return (
    <div>
      <Panel position="top-left" className="w-full bg-white h-14 px-5 panal1">
        <div className="flex h-14 justify-between items-center">
          <div className="flex justify-center items-center">
            <div className="text-gray-400">
              <FaArrowLeft />
            </div>
            <div className="mx-3"> Ecommerce </div>
            <div className="btn bg-gray-200 border-none hover:bg-gray-200 btn-xs text-gray-400 shadow-none rounded flex justify-center items-center">
              <HiPencil />
            </div>
          </div>
          <div>
            <div className="btn btn-sm bg-transparent hover:bg-transparent rounded-md text-[#0A474C] border-[#0A474C] me-4">
              Fallback & Intents
            </div>
            <div
              className="btn btn-sm bg-[#0A474C] text-white hover:bg-[#0A474C] border-none "
              onClick={saveChanges}
            >
              Save Changes
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
}

export default TopbarFlow;
