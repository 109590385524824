import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useDeleteCamp } from "../../hooks/useCampaign";
import PropTypes from "prop-types";

function CampaignRow({
  tem_id,
  name,
  type,
  date,
  status,
  audience,
  setCampaigns,
}) {
  const { loading, deleteCamp } = useDeleteCamp();

  // Delete campaign handler with error handling and confirmation
  const handleDeleteCamp = useCallback(
    async (id, campName) => {
      const confirmed = window.confirm(
        `Are you sure you want to delete the "${campName}" campaign?`
      );
      
      if (confirmed) {
        try {
          const IsCampDeleted = await deleteCamp(id);
          console.log("IsCampDeleted :", IsCampDeleted);

          if (IsCampDeleted) {
            setCampaigns((Camps) => Camps.filter((camp) => camp._id !== id));
          }
          console.log(`Campaign "${campName}" deleted successfully.`);
        } catch (error) {
          console.error("Error deleting campaign:", error);
        }
      }
    },
    [deleteCamp]
  );

  // Date formatting function
  const handleFormatDate = useCallback((date) => {
    const getdate = new Date(date);

    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return getdate.toLocaleString("en-US", options);
  }, []);

  return (
    <div
      className={`my-5 w-full p-5 font-medium bg-white rounded-md grid grid-cols-12 items-center ${
        loading ? "pointer-events-none opacity-50" : ""
      }`}
    >
      <Link
        to={`analytics/${tem_id}/camp/overview`}
        className="col-span-11 grid gap-3 grid-cols-12 items-center"
      >
        <div className="col-span-1 text-2xl">
          <BarChartIcon />
        </div>
        <div className="col-span-4">{name}</div>
        <div className="col-span-2">{type}</div>
        <div className="col-span-3">{handleFormatDate(date)}</div>
        <div className="col-span-1 text-center">
          <div className="w-14 py-0.5 text-center font-semibold border-none rounded-2xl bg-[#28C152] text-white mx-auto">
            {status}
          </div>
        </div>
        <div className="col-span-1 text-center">{audience?.length || 0}</div>
      </Link>
      <div className="col-span-1 text-center">
        {loading ? (
          <span className="loading loading-spinner"></span>
        ) : (
          <MdDelete
            onClick={() => handleDeleteCamp(tem_id, name)}
            className="cursor-pointer btn-ghost btn-circle btn-sm text-red-600 mx-auto"
            title={`Delete ${name}`}
          />
        )}
      </div>
    </div>
  );
}

// Adding PropTypes for better type checking
CampaignRow.propTypes = {
  tem_id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  audience: PropTypes.arrayOf(PropTypes.object), // Adjust as per the actual structure of `audience`
};

export default React.memo(CampaignRow);
