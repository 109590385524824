import React from "react";
import { FaRegListAlt, FaUserNurse } from "react-icons/fa";
import { GiClick } from "react-icons/gi";
import { MdPermMedia, MdProductionQuantityLimits } from "react-icons/md";
import { TbTemplate } from "react-icons/tb";

const nodeData = [
  {
    icon: <GiClick className="text-lg" />,
    onClickText: "textUpdater",
    buttonName: "Text + Buttons",
  },
  {
    icon: <MdPermMedia className="text-lg" />,
    onClickText: "mediaUpdater",
    buttonName: "Media",
  },
  {
    icon: <FaRegListAlt className="text-lg" />,
    onClickText: "listUpdater",
    buttonName: " List",
  },
  {
    icon: <MdProductionQuantityLimits className="text-lg" />,
    onClickText: "singleProductUpdater",
    buttonName: "Single Product",
  },
  {
    icon: <MdProductionQuantityLimits className="text-lg" />,
    onClickText: "multiProductUpdater",
    buttonName: "Multi Products",
  },
  {
    icon: <TbTemplate className="text-lg" />,
    onClickText: "textUpdater",
    buttonName: "Template",
  },
  {
    icon: <FaUserNurse className="text-lg" />,
    onClickText: "contactUpdater",
    buttonName: "Request Intervention",
  },
];

const Sidebar = ({ onAddNode }) => {
  return (
    <div className="shadow w-80 bg-white px-4 py-3 rounded-md  flex flex-col overflow-y-auto">
      <div className="text-center font-normal">Content Block</div>
      <div className="divider divider-neutral mt-1 mb-1 opacity-[0.3] h-1" />

      {nodeData.map((item) => (
        <Tab
          icon={item.icon}
          onClickText={item.onClickText}
          buttonName={item.buttonName}
          onAddNode={onAddNode}
        />
      ))}
    </div>
  );
};

export default Sidebar;

function Tab({ icon, onClickText, buttonName, onAddNode }) {
  return (
    <div className="flex">
      <div
        className="btn border-none text-black font-normal bg-transparent shadow-none hover:bg-transparent"
        onClick={() => onAddNode(onClickText)}
      >
        <div className="bg-gray-200 text-[#0a464cc5] w-7 h-7 rounded flex items-center justify-center">
          {icon}
        </div>
        {buttonName}
      </div>
    </div>
  );
}
