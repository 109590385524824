import { useCallback } from "react";
import { Handle, Position } from "reactflow";
import { TiFlowSwitch } from "react-icons/ti";
import DeleteNode from "../deleteNode/DeleteNode";

const handleStyle = { left: "220px", top: "20px" };

function CustomNodeTB({ id, data, isConnectable }) {
  const onChange = useCallback(
    (evt) => {
      const { name, value } = evt.target;
      if (data.onInputChange) {
        data.onInputChange(id, name, value);
      }
    },
    [data, id]
  );

  return (
    <div className="bg-white shadow w-60 h-80 p-2 rounded-lg relative">
      <DeleteNode id={id} />
      <Handle
        type="source"
        position={Position.Top}
        style={handleStyle}
        isConnectable={isConnectable}
      />
      <div className="">
        <label className="bg-[#E9EDEF] rounded-md py-1 px-2 mb-2 text-[#008069] flex items-center">
          <div className="font-bold text-base">
            <TiFlowSwitch />
          </div>
          <div className="ms-2">{data.label || "Flow Start"}</div>
        </label>

        <div className="p-1 rounded-md bg-[#EAE6DF]">
          <input
            id="text"
            name="keyword"
            value={data.keyword || ""}
            onChange={onChange}
            placeholder="Add keyword to start chat"
            className="nodrag w-full bg-white mb-1 text-black rounded-md p-2 focus:outline-none block"
          />

          {data.buttons?.map((btn, index) => (
            <div
              key={index}
              className="btn btn-sm w-full mb-1 text-black border-none bg-white text-center hover:bg-white"
            >
              {btn}
            </div>
          ))}

          <div className="btn btn-sm w-full mb-1 text-black border-none bg-white text-center hover:bg-white">
            +Add Keyword
          </div>
          <div className="btn btn-sm w-full mt-1 text-black border-none bg-white text-center hover:bg-white">
            Choose Template
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomNodeTB;
