import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import useGoogleShet from "../../hooks/useGoogleShet";

const GoogleSheet = () => {
  const [sheetUrl, setSheetUrl] = useState("");
  const { loading, googleSheet } = useGoogleShet();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Define the data to send
    const data = {
      googleSheetUrl: sheetUrl,
    };

    await googleSheet(data);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#0A474C]">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-lg w-full">
        <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">
          Submit Google Sheets URL
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="sheetUrl"
              className="block text-gray-700 font-medium mb-2"
            >
              Google Sheets URL
            </label>
            <input
              type="url"
              id="sheetUrl"
              name="sheetUrl"
              value={sheetUrl}
              onChange={(e) => setSheetUrl(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded bg-gray-100 focus:bg-white focus:outline-none focus:border-teal-500"
              placeholder="Enter Google Sheets URL"
              required
            />
          </div>
          <button
            disabled={loading}
            type="submit"
            className="w-full bg-teal-600 text-white font-bold py-3 rounded hover:bg-teal-700 focus:outline-none transition duration-300"
          >
            {loading ? (
              <spna className="loading loading-dots"></spna>
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default GoogleSheet;
