import QuickGuilde from "../../../components/QuickGuilde";
import GoBackHeader from "../../../components/GlobalComponets/GoBackHeader";
import { Link as LinkRRD, Outlet } from "react-router-dom";

import React, { useState } from "react";

import { IoLogoStackoverflow } from "react-icons/io5";
import SidebarOfEcom from "../Components/GlobalCmp/SidebarOfEcom";
import StoreSetup from "../Components/shopify/StoreSetup";
import { useGetStoreDetails } from "../../../hooks/integrationHooks/useShopify";

export default function ShopifySS() {
  useGetStoreDetails();

  const GoBackHeaderData = {
    heading: "App Store",
    link: "/integration",
    logo: "https://logosandtypes.com/wp-content/uploads/2020/11/shopify.svg",
  };

  const Tab = {
    link1: "/integration/appstore/shopify/storeSetup",
    link2: "/integration/appstore/shopify/setupMessages",
    link3: "/integration/appstore/shopify/shopifyCheckouts",
    link4: "/integration/appstore/shopify/AbandonedCart",
    link5: "/integration/appstore/shopify/ConfirmationFlow",
    link6: "/integration/appstore/shopify/OrderFlow",
    link7: "/integration/appstore/shopify/ShopifyAnalytics",
  };

  return (
    <>
      <GoBackHeader data={GoBackHeaderData} />
      <div className="flex relative">
        <SidebarOfEcom Tab={Tab} />
        <Outlet />
      </div>
    </>
  );
}
