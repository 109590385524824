import { Button, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { UseIntervence, UseOverTakeChat } from "../../../hooks/useAgenst";

const OverTake = ({ currentauthuser, selectedContact, authUser }) => {
  const [input, setInput] = useState("");
  const { isintervence, isloading, overTakeChat } = UseOverTakeChat();

  console.log("current authusers:", currentauthuser);
  console.log("selected contact", selectedContact);

  const hadleOnSubmit = async (e) => {
    if (authUser.user_Role === "Agent") {
    }
    overTakeChat({
      contactId: selectedContact,
      agentId: currentauthuser,
    });
    e.preventDefault();
  };

  return (
    <form className="px-4 z-50">
      <div className="w-full bg-white rounded-md mb-2 relative py-4 px-5 text-center">
        {/* <div className="font-bold text-base">This chat is expired</div> */}
        {/* <div className="font- mt-0.5 text-gray-900">
          Chats are marked as expired 24 hours after the last received customer
          message. WhatsApp allows only template messages to be sent in such
          chats.
        </div> */}
        <Tooltip
          title={
            authUser.user_Role === "Agent"
              ? "You do not have permission to perform this action."
              : ""
          }
          arrow
          placement="top"
        >
          <span>
            <Button
              variant="outlined"
              onClick={(e) => {
                hadleOnSubmit(e);
              }}
              disabled={authUser.user_Role === "Agent"}
              type="submit"
            >
              OverTake
            </Button>
          </span>
        </Tooltip>
      </div>
    </form>
  );
};

export default OverTake;
