import { useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";

import { Link, useLocation } from "react-router-dom";
import { tokens } from "../../../theme";
import {
  useTheme,
  Box,
  Typography,
  IconButton,
  Paper,
  Popper,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EarbudsIcon from "@mui/icons-material/Earbuds";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { TiFlowMerge } from "react-icons/ti";
import CampaignIcon from "@mui/icons-material/Campaign";
import { PersonOutlineOutlined } from "@mui/icons-material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { CgLogOut } from "react-icons/cg";
import LogoutIcon from "@mui/icons-material/Logout";
import { BiLogOutCircle } from "react-icons/bi";
import useLogOut from "../../../hooks/useLogOut";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { useFetchAllTemp } from "../../../hooks/useManagetemplate";
import WebhookIcon from "@mui/icons-material/Webhook";
import useGetConversations from "../../../hooks/useGetConversations";
import { FaUsersBetweenLines } from "react-icons/fa6";
import { Usegetagents } from "../../../hooks/useAgenst";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { UseAuthContext } from "../../../context/AuthContext";
import useNewUnseenMessages from "../../../hooks/useNewUnseenMessages";
import useSessionActive from "../../../hooks/useSession";
import { IoIosPricetags } from "react-icons/io";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const MyProSidebar = () => {
  //to fetch all templates when the user enter on dashboard
  useFetchAllTemp();
  //to fetch all contacts and conversations
  useGetConversations();
  // to get all agents
  Usegetagents();
  //to notification sound when msg from user
  useNewUnseenMessages();
  // to check session is active or not
  useSessionActive();

  const { loading, logOut } = useLogOut();

  const onHadleLogOut = async () => {
    await logOut();
  };

  const { authUser } = UseAuthContext();
  console.log("agenst auth data looks likes this :", authUser);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  const expandedStyles = {
    "& .menu-anchor": {
      color: "inherit !important",
      backgroundColor: "transparent !important",
      paddingX: "5px",
      paddingY: "5px",
    },

    "& .menu-anchor .menu-icon": {
      marginRight: "2px !important",
    },

    "& .menu-anchor .menu-icon .css-havevq-MuiSvgIcon-root": {
      fontWeight: 600,
      fontSize: "24px",
    },

    "& .menu-anchor .menu-label .css-17w9904-MuiTypography-root": {
      fontFamily: "system-ui",
      fontWeight: 600,
      fontSize: "0.975rem",
    },
  };
  const collapsedStyles = {
    "& .menu-item ": {
      width: "70px",
      marginLeft: "5px !important",
    },
    "& .menu-anchor": {
      color: "inherit !important",
      backgroundColor: "transparent !important",
      paddingX: "5px !important",
      paddingY: "5px !important",
      display: "block !important",
      paddingTop: "0 !important",
      height: "fit-content !important",
    },
    "& .menu-anchor .menu-icon": {
      display: "flow-root !important",
      textAlign: "center !important",
    },
    "& .menu-anchor .sc-iBYQkv": {
      width: "45px !important",
      minWidthidth: "35px !important",
      height: "27px !important",
    },
    "& .menu-anchor .menu-icon .MuiSvgIcon-fontSizeMedium": {
      margin: "0px 0 0 16px !important",
    },

    "& .menu-anchor .menu-label .MuiTypography-body1": {
      fontFamily: "system-ui !important",
      fontWeight: 600,
      fontSize: "12px !important",
      textAlign: "center !important",
      wordWrap: "break-word !important",
      overflowWrap: "break-word !important", // Alternative for better compatibility
      whiteSpace: "normal !important", // Allows text to wrap to the next line
      marginTop: "3px",
    },
  };
  return (
    <>
      <Box
        sx={{
          position: "sticky",
          display: "flex",
          height: "100vh",
          top: 0,
          bottom: 0,
          color: "white",
          zIndex: 10000,
          "& .sidebar": {
            border: "none",
          },
          "& .menu-icon": {
            backgroundColor: "transparent !important",
          },
          "& .MuiMenuItem-root": {
            // padding: "5px 35px 5px 20px !important",
            // marginRight: "10px !important",
            backgroundColor: "transparent !important",
          },

          ...(collapsed ? collapsedStyles : expandedStyles),

          "& .menu-item": {
            color: "#657583 !important",
            backgroundColor: "transparent !important",
            borderRadius: "5px",
            marginY: "2px",
          },
          "& .menu-item:hover": {
            color: `${colors.blueAccent[500]} !important`,
            backgroundColor: "#F6F7F8 !important",
          },
          "& .menu-item.active": {
            color: `${colors.blueAccent[500]} !important`,
            backgroundColor: "#e1f4ee !important",
          },
        }}
      >
        <Sidebar breakPoint="md" backgroundColor={colors.primary[400]}>
          <Menu iconshape="square">
            {!collapsed && (
              <Box
                style={{
                  width: "100%",
                  padding: "10px 0 10px 4px",
                  color: "red !important",
                  position: "sticky",
                  top: 1,
                  zIndex: 10000,
                }}
                display="flex"
                justifyContent="start"
                alignItems="start"
                sx={{ background: "#FFFFFF !important" }}
                // mt="20px"
              >
                {/* <Typography variant="h3">KoreGrow</Typography> */}
                <IconButton onClick={() => toggleSidebar()}>
                  {/* <CloseOutlinedIcon /> */}
                  <img className="w-12 mx-auto" src="/assets/Logo.png" alt="" />
                </IconButton>
              </Box>
            )}
            {collapsed && (
              // <MenuItem
              // style={{
              //   margin: "10px 0 10px 0",
              //   color: "transparent !important",
              //   background: "transparent !important",
              // }}
              //   icon={collapsed ? <MenuOutlinedIcon /> : undefined}
              //   onClick={() => {
              //     collapseSidebar();
              //   }}
              // ></MenuItem>
              <Box
                style={{
                  width: "100%",
                  padding: "10px 0 10px 4px",
                  color: "red !important",
                  position: "sticky",
                  top: 1,
                  zIndex: 10000,
                }}
                display="flex"
                justifyContent="start"
                alignItems="start"
                sx={{ background: "#FFFFFF !important" }}
                // mt="20px"
                onClick={() => {
                  collapseSidebar();
                }}
              >
                {/* <Typography variant="h3">KoreGrow</Typography> */}
                <IconButton onClick={() => toggleSidebar()}>
                  {/* <CloseOutlinedIcon /> */}
                  <img className="w-12 mx-auto" src="/assets/Logo.png" alt="" />
                </IconButton>
              </Box>
            )}

            {authUser?.user_Role === "Agent" ? (
              <Box paddingLeft={collapsed ? undefined : "10%"}>
                <Item
                  title="Dashboard"
                  to="/"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  collapsed={collapsed}
                />

                <Item
                  title="Live Chat"
                  to="/livechat"
                  icon={<ForumOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  collapsed={collapsed}
                />
                {/* History */}
                {/* <Item
               title="History"
               to="/history"
               icon={<RestoreOutlinedIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
                {/* <Item
               title="Contacts"
               to="/contacts"
               icon={<ContactsOutlinedIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}

                {/* <Item
               title="Campaigns"
               to="/campaigns"
               icon={<CampaignIcon />}
               selected={selected}
               setSelected={setSelected}
             />
  */}
                {/* <Typography
               variant="h6"
               color={colors.grey[700]}
               sx={{ m: "15px 0 5px 20px" }}
             >
               Pages
             </Typography> */}
                {/* <Item
               title="Profile"
               to="/profile"
               icon={<PersonOutlineOutlined />}
               selected={selected}
               setSelected={setSelected}
             /> */}
                {/* <Item
               title="Calendar"
               to="/calendar"
               icon={<CalendarTodayOutlinedIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
                {/* <Item
               title="FAQ Page"
               to="/faq"
               icon={<HelpOutlineOutlinedIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}

                {/* <Typography
               variant="h6"
               color={colors.grey[700]}
               sx={{ m: "15px 0 5px 20px" }}
             >
               Manage
             </Typography> */}
                {/* <Item
               title="Create Template"
               to="/template"
               icon={<PolylineOutlinedIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
                {/* <Item
               title="Opt-In/Out"
               to="/optSystem"
               icon={<FilterAltOffIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
                {/* <Item
               title="Agent"
               to="/Agent"
               icon={<FaUsersBetweenLines className="text-2xl" />}
               selected={selected}
               setSelected={setSelected}
             /> */}

                {/* <Item
               title="User Concern"
               to="/UserConcerns"
               icon={<VerifiedUserIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
                {/* 
             <Item
               title="Biling & Usage"
               to="/biling"
               icon={<AccountBalanceWalletIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
                {/* <Item
               title="Flow"
               to="/flow"
               icon={<TiFlowMerge />}
               selected={selected}
               setSelected={setSelected}
             /> */}
                {/* <Item
               title="Integration "
               to="/integration"
               icon={<WebhookIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
                {/* <Item
               title="Analytics"
               to="/analytics"
               icon={<QueryStatsOutlinedIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
                {/* <Item
               title="API Key"
               to="/apikey"
               icon={<VpnKeyIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
                {/* <Item
               title="API Key"
               to="/apikey"
               icon={<VpnKeyIcon />}
               selected={selected}
               setSelected={setSelected}
             /> */}
                <MenuItem
                  disabled={loading}
                  onClick={onHadleLogOut}
                  style={{ color: colors.grey[100] }}
                  icon={<LogoutIcon />}
                >
                  {loading ? (
                    <span className="loading loading-dots"></span>
                  ) : (
                    <Typography>Log Out</Typography>
                  )}
                </MenuItem>
              </Box>
            ) : (
              <Box
                paddingLeft={collapsed ? undefined : "5%"}
                paddingRight={collapsed ? undefined : "5%"}
              >
                {!collapsed && (
                  <Typography
                    variant="subtitle2"
                    color={colors.grey[700]}
                    sx={{
                      m: " 5px 5px 5px 10px",
                      fontWeight: "700",
                      fontSize: "0.6875rem",
                    }}
                  >
                    OVERVIEW
                  </Typography>
                )}
                <Item
                  title="Dashboard"
                  to="/"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  collapsed={collapsed}
                />

                <Item
                  title="Live Chat"
                  to="/livechat"
                  icon={<ForumOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  collapsed={collapsed}
                />
                {/* History */}
                <Item
                  title="Contacts"
                  to="/contacts"
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  collapsed={collapsed}
                />

                <Item
                  title="Campaigns"
                  to="/campaigns"
                  icon={<CampaignIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  collapsed={collapsed}
                  // nestedItems={[
                  //   { title: "Sub Item 1", to: "/subitem1" },
                  //   { title: "Sub Item 2", to: "/subitem2" },
                  // ]}
                />

                {/* <Typography
              variant="h6"
              color={colors.grey[700]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography> */}
                <Item
                  title="Profile"
                  to="/profile"
                  icon={<PersonOutlineOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  collapsed={collapsed}
                />
                {/* <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
                {/* <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

                {!collapsed && (
                  <Typography
                    variant="subtitle2"
                    color={colors.grey[700]}
                    sx={{
                      m: " 5px 5px 5px 10px",
                      fontWeight: "700",
                      fontSize: "11px",
                    }}
                  >
                    MANAGEMENT
                  </Typography>
                )}
                <Item
                  title="Manage Template"
                  to="/template/message/Marketing"
                  icon={<PolylineOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  collapsed={collapsed}
                />
                <Item
                  title="Opt-In/Out"
                  to="/optSystem"
                  icon={<FilterAltOffIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  collapsed={collapsed}
                />
                <Item
                  title="Agent"
                  to="/Agent"
                  icon={<SupportAgentIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  collapsed={collapsed}
                />
                <Item
                  title="Manage Tag"
                  to="/ManageTag"
                  icon={<ManageAccountsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  collapsed={collapsed}
                />

                {/* <Item
              title="User Concern"
              to="/UserConcerns"
              icon={<VerifiedUserIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
                {/* 
            <Item
              title="Biling & Usage"
              to="/biling"
              icon={<AccountBalanceWalletIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
                <Item
                  title="Flow"
                  to="/flow"
                  icon={<EarbudsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  collapsed={collapsed}
                />
                <Item
                  title="Integration "
                  to="/integration"
                  icon={<WebhookIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  collapsed={collapsed}
                />
                <Item
                  title="Analytics"
                  to="/analytics"
                  icon={<QueryStatsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  collapsed={collapsed}
                />

                {/* <Item
              title="API Key"
              to="/apikey"
              icon={<VpnKeyIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
                {/* <Item
              title="API Key"
              to="/apikey"
              icon={<VpnKeyIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
                <MenuItem
                  disabled={loading}
                  onClick={onHadleLogOut}
                  style={{ color: colors.grey[100] }}
                  icon={<LogoutIcon />}
                >
                  {loading ? (
                    <span className="loading loading-dots"></span>
                  ) : (
                    <Typography>Log Out</Typography>
                  )}
                </MenuItem>
              </Box>
            )}

            {/* <button
            disabled={loading}
            className="btn w-full border-none rounded-none bg-[#122D30] text-white font-bold p-2 pe-20"
            onClick={onHadleLogOut}
          >
            {loading ? (
              <span className="loading loading-dots"></span>
            ) : (
              <>
                <span className="text-xl text-red-500">
                  <BiLogOutCircle />
                </span>
                Log Out
              </>
            )}
          </button> */}
          </Menu>
        </Sidebar>
        {!broken && (
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              // backgroundColor: "red",
              height: "10px",
              position: "sticky",
              top: 10,
              right: -10,
              zIndex: 11000,
              width: "10px",
              marginLeft: "-40px",
            }}
          >
            {!collapsed ? (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  collapseSidebar();
                }}
                sx={{
                  backgroundColor: "#FFFFFF",
                  border: "0.5px solid lightgray",
                  "&:hover": {
                    backgroundColor: "#F4F6F8", // Lighter shade on hover
                  },
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
            ) : (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  collapseSidebar();
                }}
                sx={{
                  backgroundColor: "#FFFFFF",
                  border: "0.5px solid lightgray",
                  "&:hover": {
                    backgroundColor: "#F4F6F8", // Lighter shade on hover
                  },
                }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          // backgroundColor: "gray",
          // opacity:"0.5",
          width: "10px",
          height: "20px",
          textAlign: "center",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 11000,
        }}
        // onClick={collapsed ? toggleSidebar : collapseSidebar}
      >
        {broken && (
          <IconButton
            onClick={
              broken & !collapsed
                ? () => toggleSidebar()
                : () => collapseSidebar()
            }
            sx={{
              flexGrow: 1,
              p: "2px",
              backgroundColor: "transparent",
              height: "20px",
              position: "fixed",
              top: 10,
              left: 10,
              zIndex: 11000,
            }}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
};

const Item = ({
  title,
  to,
  icon,
  selected,
  setSelected,
  nestedItems,
  collapsed,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const PathName = useLocation().pathname;
  const [anchorEl, setAnchorEl] = useState(null); // For hover behavior in collapsed mode
  const [isOpen, setIsOpen] = useState(false); // For dropdown behavior in non-collapsed mode

  const isActiveTab = to !== "/" ? PathName.includes(to) : PathName === to;

  const handleMouseEnter = (event) => {
    if (collapsed) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMouseLeave = () => {
    if (collapsed) {
      setAnchorEl(null);
    }
  };

  const toggleDropdown = () => {
    if (!collapsed) {
      setIsOpen((prev) => !prev); // Toggle dropdown only in non-collapsed mode
    }
  };

  // console.log("collapsed:", collapsed);

  return (
    <Box
      position="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Main Menu Item */}
      <MenuItem
        active={isActiveTab}
        style={{
          color: colors.grey[100],
          fontSize: "18px",
        }}
        onClick={() => {
          setSelected(title);
          toggleDropdown(); // Toggle dropdown in non-collapsed mode
        }}
        icon={icon}
        routerLink={<Link to={to} />}
      >
        <Box
          display="flex"
          justifyContent={collapsed ? "center" : "space-between"}
          alignItems="center"
          width="100%"
          position="relative"
        >
          {nestedItems && collapsed && (
            <KeyboardArrowRightIcon
              sx={{
                position: "absolute",
                right: "-2px",
                top: "-18px",
              }}
            />
          )}
          <Typography>{title}</Typography>
          {nestedItems && (
            <Typography style={{ fontSize: "16px", color: colors.grey[300] }}>
              {collapsed ? (
                ""
              ) : isOpen ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}{" "}
              {/* Dropdown arrow */}
            </Typography>
          )}
        </Box>
      </MenuItem>

      {/* Nested Items (for collapsed mode - Popper) */}
      {nestedItems && collapsed && (
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="right-start"
          style={{ zIndex: 12000 }}
        >
          <Paper
            elevation={3}
            style={{
              background: "linear-gradient(204deg, #e0eef2, #ffffff, #f5eae7)",
              padding: "6px",
              borderRadius: "12px",
              minWidth: "100px",
              maxWidth: "180px",
            }}
            sx={{
              "& .menu-item .menu-anchor": {
                color: `${colors.grey[400]}`,
                fontSize: "16px",
                borderRadius: "6px",
                height: "35px !important",
                marginBottom: "4px",
                backgroundColor: "transparent !important",
              },
              "& .menu-item": {
                color: `${colors.grey[400]}`,
                fontSize: "16px",
                borderRadius: "6px",
                height: "35px !important",
                marginBottom: "4px",
                backgroundColor: "transparent !important",
              },
              "& .menu-item .menu-anchor:hover": {
                color: `${colors.grey[400]} !important`,
                backgroundColor: "rgb(0 0 0 / 5%) !important",
              },
              "& .menu-item.active": {
                color: `${colors.grey[100]} !important`,
                backgroundColor: "rgb(0 0 0 / 7%) !important",
              },
              "& .menu-item.cxRqFw.active": {
                color: `${colors.grey[100]} !important`,
                backgroundColor: "rgb(0 0 0 / 7%) !important",
              },
              "& .menu-item .menu-anchor.active": {
                color: `${colors.grey[100]} !important`,
                backgroundColor: "rgb(0 0 0 / 7%) !important",
              },
            }}
          >
            {nestedItems.map((nestedItem, index) => (
              <MenuItem
                key={index}
                onClick={() => setSelected(nestedItem.title)}
                routerLink={<Link to={nestedItem.to} />}
                active={PathName.includes(nestedItem.to)}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "system-ui",
                  }}
                >
                  {nestedItem.title}
                </Typography>
              </MenuItem>
            ))}
          </Paper>
        </Popper>
      )}

      {/* Nested Items (for non-collapsed mode - Dropdown below) */}
      {nestedItems && !collapsed && isOpen && (
        <Box
          style={{
            backgroundColor: colors.grey[900],
            padding: "8px 16px",
            borderRadius: "4px",
            marginTop: "4px",
          }}
        >
          {nestedItems.map((nestedItem, index) => (
            <MenuItem
              key={index}
              style={{
                color: colors.grey[100],
                fontSize: "16px",
                marginLeft: "16px", // Indent for nested items
              }}
              onClick={() => setSelected(nestedItem.title)}
              routerLink={<Link to={nestedItem.to} />}
            >
              <Typography>{nestedItem.title}</Typography>
            </MenuItem>
          ))}
        </Box>
      )}
    </Box>
  );
};
export default MyProSidebar;
