import React from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { useAnaCam } from "../../hooks/useCampaign";
import TabBar from "../Topbar";

import { MdOutlineExplore } from "react-icons/md";
import { IoMdMailOpen } from "react-icons/io";
import { MdOutlineTimelapse } from "react-icons/md"; //penidng logo
import { MdCheckCircleOutline } from "react-icons/md"; //approvalLogo
import { PiWarningOctagonLight } from "react-icons/pi"; //warningLogo
import { FaCircleNotch } from "react-icons/fa6";

//logo
import { FaCheckDouble } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { MdSmsFailed } from "react-icons/md";
import Campaign from "./Campaign";
import Overview from "../../pages/campaigns/Overview";
import GoBackHeader from "../GlobalComponets/GoBackHeader";
import { FaReplyAll } from "react-icons/fa";

const AnaCamp = () => {
  const { id } = useParams();
  const { loading, camAna } = useAnaCam(id);

  console.log("camp looks likes   :", camAna);
  // date format shorting function
  const handleFormatDate = (date) => {
    const getdate = new Date(date);
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const newFormatedDate = getdate.toLocaleString("en-US", options);
    return newFormatedDate;
  };

  const handleTabButton = {
    width: "full",
    // span1: "explore",
    span1: "Overview",
    span2: "sent",
    span3: "Delivered",
    span4: "read",
    // span5: "Approval",

    span5: "Replied",
    span6: "failed",

    Links: {
      link1: "camp/overview",
      link2: "camp/sent",
      link3: "camp/Delivered",
      link4: "camp/read",
      link5: "camp/replied",
      link6: "camp/failed",
    },

    Logos: {
      logo1: <MdOutlineExplore className="text-xl" />,
      logo2: <FaCheck className="text-xl" />,
      logo3: <FaCheckDouble className="text-xl" />,
      logo4: <FaCheckDouble className="text-xl text-blue-500" />,
      logo5: <FaReplyAll className="text-xl text-green-600" />,
      logo6: <MdSmsFailed className="text-xl text-red-500" />,
    },
  };

  const data = {
    heading: " Campaigns",
    link: "/campaigns",
  };
  return (
    <>
      <GoBackHeader data={data} />
      <TabBar data={handleTabButton} />
      <Outlet />
    </>
  );
};

export default AnaCamp;
