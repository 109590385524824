import React, { useCallback } from "react";
import { IoIosClose } from "react-icons/io";
import { useReactFlow } from "reactflow";

function DeleteNode({id}) {
  //this function use to delete a node
  const { deleteElements } = useReactFlow();
  const onClick = useCallback(() => {
    deleteElements({ nodes: [{ id }] });
  }, [id, deleteElements]);

  return (
    <>
      <div
        className="absolute top-[-5px] left-[-5px]  bg-gray-400 bg-opacity-[0.5] rounded-full text-lg hover:cursor-pointer"
        onClick={onClick}
      >
        <IoIosClose />
      </div>
    </>
  );
}

export default DeleteNode;
