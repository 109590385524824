import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { UseAuthContext } from "../context/AuthContext";
import useAgentsZus from "../zustand/useAgents.Zus";
import useZusConversation from "../zustand/useZusConversation";
const notificationSound = "/assets/sounds/notification.mp3"; // Direct path from public directory

const Usecreateagents = () => {
  const [iscreateAgent, setCreateagent] = useState("");
  const [isloader, setloader] = useState(false);

  const uploadDataAgents = async (updatedata) => {
    try {
      setloader(true);
      const response = await axios.post("api/auth/agentCreate", updatedata);
      console.log("data from backend looks likes this :", response);
      if (response.status === 201) {
        setCreateagent(response?.data);
        toast.success("user updated");
      }
      console.log("this is the resposne :", response);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setloader(false);
    }
  };

  return { iscreateAgent, isloader, uploadDataAgents };
};

const useAgentLogin = () => {
  const [agentloading, setLoading] = useState(false);

  const { setAuthUser } = UseAuthContext();

  const agentlogin = async ({ username, password }) => {
    const success = haldeInputError({ username, password });

    if (!success) return;

    setLoading(true);

    try {
      const res = await axios.post("/api/auth/agentLogin", {
        username,
        password,
      });

      const data = res.data;

      console.log(data);

      //set auth user in localstorage

      localStorage.setItem("authUser", JSON.stringify(data));
      setAuthUser(data);
      toast.success("Login Successfully!");
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
      //set auth user in localstorage
      setAuthUser(null);
      localStorage.removeItem("authUser");
    } finally {
      setLoading(false);
    }
  };

  return { agentloading, agentlogin };
};

const haldeInputError = ({ username, password }) => {
  if (!username || !password) {
    toast.error("Please fill the fields!");
    return false;
  }

  return true;
};

const Usegetagents = () => {
  // const [agents, setAgents] = useState("");
  // const [loadingAgent, setLoadingAgent] = useState(false);
  const { loadingAgent, setLoadingAgent, agents, setAgents } = useAgentsZus();

  const getagents = async () => {
    try {
      setLoadingAgent(true);

      const response = await axios.get("api/agent/getAllAgents");
      console.log("response agents from backend looks like this:", response);

      if (!response) {
        toast.error("no data got from backend");
      }

      if (response?.status === 200) {
        setAgents(response?.data?.agents);
      }
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoadingAgent(false);
    }
  };

  // Call getagents when the component mounts
  useEffect(() => {
    getagents();
  }, []); // Empty dependency array ensures this runs only once when the hook is first used

  // const refetchusername = ()=>{
  //   getagents();
  // }

  return { agents, loadingAgent, refetchusername: getagents };
};
//this hook is to transfer agent
const UseTransferAgent = () => {
  const { conversations, setConversations } = useZusConversation();
  const { selectedConversation, setSelectedConversation } =
    useZusConversation();
  const { agents, setAgents } = useAgentsZus();
  const { authUser, setAuthUser } = UseAuthContext();

  const [istransferAgent, settransderAgent] = useState("");
  const [isloadingAgent, setloadingAgent] = useState(false);

  const transferAgent = async (updatedata) => {
    try {
      setloadingAgent(true);
      // console.log("transferAgent:", updatedata);

      const resposne = await axios.post(`api/agent/transferAgent`, updatedata);

      console.log("resposne looks likes this :", resposne.data);
      const data = resposne.data;

      const latestConatcts = conversations.map((item) =>
        item._id === data.contactId
          ? { ...item, agentId: data.updatedAgentId }
          : item
      );

      setConversations(latestConatcts);

      const updatedSelectedConversation = data.updatedContact;

      // Check if the updated contact matches the currently selected conversation
      if (data.contactId === selectedConversation._id) {
        setSelectedConversation(updatedSelectedConversation); // Set it directly
      } else {
        setSelectedConversation(null);
      }
      // Update agents
      const latestAgents = agents.map((item) => {
        if (item._id === data.newAgent._id) {
          return { ...item, IntervenedChat: data.newAgent.IntervenedChat };
        }
        if (item._id === data.oldAgent._id) {
          return { ...item, IntervenedChat: data.oldAgent.IntervenedChat };
        }
        return item;
      });
      setAgents(latestAgents);

      // Update the authenticated user if applicable
      if (authUser._id === data.newAgent._id) {
        setAuthUser({
          ...authUser,
          IntervenedChat: data.newAgent.IntervenedChat,
        });
      } else if (authUser._id === data.oldAgent._id) {
        setAuthUser({
          ...authUser,
          IntervenedChat: data.oldAgent.IntervenedChat,
        });
      }

      // to play sounf on trasfer agent
      const sound = new Audio(notificationSound);
      sound.play();
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setloadingAgent(false);
    }
  };

  return { istransferAgent, isloadingAgent, transferAgent };
};

const UseEditAgent = () => {
  const [isEditedAgent, setEditedagent] = useState("");
  const [isloader, setloader] = useState(false);

  const handleEditAgent = async (updatedata) => {
    try {
      setloader(true);
      console.log("updatedata looks likes this :", updatedata);
      const response = await axios.post("/api/agent/editAgent", updatedata);
      console.log("response looks likes this:", response);

      if (response?.status === 200) {
        setEditedagent(response?.data);
        toast.success("updated..");
      }
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setloader(false);
    }
  };

  return { isEditedAgent, isloader, handleEditAgent };
};

const Useresolvebutton = () => {
  const { conversations, setConversations } = useZusConversation();
  const { selectedConversation, setSelectedConversation } =
    useZusConversation();
  const { agents, setAgents } = useAgentsZus();
  const { authUser, setAuthUser } = UseAuthContext();
  const [isresolved, setresloved] = useState("");
  const [isresolvedloader, setresolveloader] = useState(false);

  const updateresolvedata = async (updatedata) => {
    try {
      setresolveloader(true);

      const res = await axios.post("api/agent/resolve", updatedata);

      const data = res.data;

      const latestConatcts = conversations.map((item) =>
        item._id === data.contactId
          ? { ...item, agentId: data.updatedAgentId || null }
          : item
      );
      setConversations(latestConatcts);

      const updatedSelectedConversation = data.updatedContact;

      // Check if the updated contact matches the currently selected conversation
      if (data.contactId === selectedConversation._id) {
        setSelectedConversation(updatedSelectedConversation); // Set it directly
      }

      // Update agents
      const latestAgents = agents.map((item) => {
        if (item._id === data.oldAgent._id) {
          return { ...item, IntervenedChat: data.oldAgent.IntervenedChat };
        }
        return item;
      });
      setAgents(latestAgents);

      // Update the authenticated user if applicable
      if (authUser._id === data.oldAgent._id) {
        setAuthUser({
          ...authUser,
          IntervenedChat: data.oldAgent.IntervenedChat,
        });
      }

      toast.success(data.message);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setresolveloader(false);
    }
  };
  return { isresolved, isresolvedloader, updateresolvedata };
};

const UseIntervence = () => {
  const { conversations, setConversations } = useZusConversation();
  const { selectedConversation, setSelectedConversation } =
    useZusConversation();
  const { agents, setAgents } = useAgentsZus();
  const { authUser, setAuthUser } = UseAuthContext();

  const [isintervence, setintervence] = useState("");
  const [isloading, setloading] = useState(false);

  const updateintervence = async ({ contactId, agentId }) => {
    try {
      setloading(true);

      console.log("updateintervence", contactId, agentId);

      const resposne = await axios.post("api/agent/replaceAgent", {
        contactId,
        agentId,
      });

      const data = resposne.data;

      console.log("updateintervenceRes", data);

      const latestConatcts = conversations.map((item) =>
        item._id === data.contactId
          ? {
              ...item,
              agentId: data.updatedAgentId,
              requesting: data.requesting,
            }
          : item
      );

      setConversations(latestConatcts);

      const updatedSelectedConversation = data.updatedContact;

      console.log("test:", updatedSelectedConversation);

      // Check if the updated contact matches the currently selected conversation
      if (data.contactId === selectedConversation._id) {
        setSelectedConversation(updatedSelectedConversation); // Set it directly
      }
      // Update agents
      const latestAgents = agents.map((item) => {
        if (item._id === data.newAgent._id) {
          return { ...item, IntervenedChat: data.newAgent.IntervenedChat };
        }
        return item;
      });
      setAgents(latestAgents);

      // Update the authenticated user if applicable
      if (authUser._id === data.newAgent._id) {
        setAuthUser({
          ...authUser,
          IntervenedChat: data.newAgent.IntervenedChat,
        });
      }

      toast.success(data.message);

      // console.log("response looks likes this :", resposne);
      // if (resposne.status === 200 || resposne.status === 201) {
      //   setintervence(resposne.data);
      //   toast.success("intervence succesfully");
      // }
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setloading(false);
    }
  };

  return { updateintervence };
};

const UseOverTakeChat = () => {
  const { conversations, setConversations } = useZusConversation();
  const { selectedConversation, setSelectedConversation } =
    useZusConversation();
  const { agents, setAgents } = useAgentsZus();
  const { authUser, setAuthUser } = UseAuthContext();

  const [isintervence, setintervence] = useState("");
  const [isloading, setloading] = useState(false);

  const overTakeChat = async ({ contactId, agentId }) => {
    try {
      setloading(true);

      console.log("updateintervence", contactId, agentId);

      const resposne = await axios.post("api/agent/overTakeChat", {
        contactId,
        agentId,
      });
      const data = resposne.data;

      const latestConatcts = conversations.map((item) =>
        item._id === data.contactId
          ? { ...item, agentId: data.updatedAgentId }
          : item
      );

      setConversations(latestConatcts);

      const updatedSelectedConversation = data.updatedContact;

      // Check if the updated contact matches the currently selected conversation
      if (data.contactId === selectedConversation._id) {
        setSelectedConversation(updatedSelectedConversation); // Set it directly
      } else {
        setSelectedConversation(null);
      }
      // Update agents
      const latestAgents = agents.map((item) => {
        if (item._id === data.newAgent._id) {
          return { ...item, IntervenedChat: data.newAgent.IntervenedChat };
        }
        if (item._id === data.oldAgent._id) {
          return { ...item, IntervenedChat: data.oldAgent.IntervenedChat };
        }
        return item;
      });
      setAgents(latestAgents);

      // Update the authenticated user if applicable
      if (authUser._id === data.newAgent._id) {
        setAuthUser({
          ...authUser,
          IntervenedChat: data.newAgent.IntervenedChat,
        });
      } else if (authUser._id === data.oldAgent._id) {
        setAuthUser({
          ...authUser,
          IntervenedChat: data.oldAgent.IntervenedChat,
        });
      }

      // console.log("response looks likes this :", resposne);
      // if (resposne.status === 200 || resposne.status === 201) {
      //   setintervence(resposne.data);
      //   toast.success("intervence succesfully");
      // }
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setloading(false);
    }
  };

  return { overTakeChat };
};

const UseDeleteAgent = () => {
  const [isDeleteagent, setDeleteAgent] = useState("");
  const [isloaderdelete, setloaderdelete] = useState(false);

  const handledeleteAgent = async ({ agentId }) => {
    try {
      setloaderdelete(true);

      const resposne = await axios.delete(`api/agent/deleteAgent/${agentId}`, {
        params: {
          agentId: agentId,
        },
      });
      //  console.log(resposne);

      if (resposne?.status === 200) {
        setDeleteAgent(resposne?.data);
        toast.success("successfully deleted..");
      }
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setloaderdelete(false);
    }
  };

  return { isDeleteagent, isloaderdelete, handledeleteAgent };
};

export {
  Usecreateagents,
  Usegetagents,
  useAgentLogin,
  UseTransferAgent,
  Useresolvebutton,
  UseIntervence,
  UseOverTakeChat,
  UseEditAgent,
  UseDeleteAgent,
};
