// import React from "react";
// import { FixedSizeList as List } from "react-window";
// import useZusConversation from "../../../zustand/useZusConversation";
// import Conversation from "./Conversation"; // Assuming Conversation is a separate component

// const ConversationList = () => {
//   const { loadingCNV, conversations } = useZusConversation();

//   return (
//     <>
//       {loadingCNV && (
//         <div className="h-[100vh] w-full flex justify-center items-center opacity-55 text-xl">
//           <div>
//             Loading <span className="loading loading-dots"></span>
//           </div>
//         </div>
//       )}

//       <List
//         height={600} // Adjust height according to your UI
//         itemCount={conversations.length}
//         itemSize={80} // Approximate height of a single conversation item
//         width={"100%"}
//       >
//         {({ index, style }) => (
//           <div style={style}>
//             <Conversation
//               conversation={conversations[index]}
//               lastRow={index === conversations.length - 1}
//             />
//           </div>
//         )}
//       </List>
//     </>
//   );
// };

// export default ConversationList;

import React, { useMemo } from "react";
import { FixedSizeList as List } from "react-window";
import Conversation from "./Conversation";
import useZusConversation from "../../../zustand/useZusConversation";
import { UseAuthContext } from "../../../context/AuthContext";

const ConversationsList = ({ searchQuery, agentQuery }) => {
  const { loadingCNV, conversations } = useZusConversation();
  const { authUser } = UseAuthContext();

  const currentChatIntervedUser = authUser?.agent_id
    ? authUser?.agent_id
    : authUser._id;

  const filteredConversations = useMemo(() => {
    const sortedConversations = [...(conversations || [])].sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );

    if (searchQuery) {
      return sortedConversations.filter(
        (conversation) =>
          conversation.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          conversation.mobile_Number
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
      );
    }

    if (agentQuery.heading === "requesting") {
      return sortedConversations.filter(
        (conversation) => conversation?.requesting === true
      );
    } else if (agentQuery.heading === "all") {
      return sortedConversations;
    } else if (agentQuery.heading === "active") {
      return sortedConversations.filter((conversation) => {
        const now = Date.now();
        const expiresAt = new Date(conversation?.expiresAt).getTime();
        return now <= expiresAt;
      });
    } else if (agentQuery.heading === "expired") {
      return sortedConversations.filter((conversation) => {
        const now = Date.now();
        const expiresAt = new Date(conversation?.expiresAt).getTime();
        return now > expiresAt;
      });
    } else if (agentQuery.heading === "unread") {
      return sortedConversations.filter((conversation) => {
        return conversation.unseenMessages > 0;
      });
    } else if (agentQuery.heading === "intervened") {
      if (currentChatIntervedUser === agentQuery.subHeading) {
        return sortedConversations.filter(
          (conversation) =>
            conversation?.agentId &&
            conversation.agentId === agentQuery.subHeading
        );
      } else if (agentQuery.subHeading === "any") {
        return sortedConversations.filter(
          (conversation) => conversation?.agentId !== null
        );
      } else {
        return sortedConversations.filter(
          (conversation) =>
            conversation?.agentId &&
            conversation.agentId === agentQuery.subHeading
        );
      }
    }

    return sortedConversations;
  }, [conversations, searchQuery, agentQuery, currentChatIntervedUser]);

  return (
    <div className="py-2 mx-4 mt-2 flex flex-col overflow-auto">
      {loadingCNV && (
        <div className="h-[100vh] w-full flex justify-center items-center opacity-55 text-xl">
          <div>
            Loading <span className="loading loading-dots"></span>
          </div>
        </div>
      )}

      {!loadingCNV && filteredConversations?.length === 0 && (
        <div className="h-[100vh] w-full flex justify-center items-center opacity-55 text-xl">
          <div>No Conversations!</div>
        </div>
      )}

      {!loadingCNV && filteredConversations?.length > 0 && (
        <List
          height={600}
          itemCount={filteredConversations.length}
          itemSize={65}
          width={"100%"}
        >
          {({ index, style }) => (
            <div style={style}>
              <Conversation
                conversation={filteredConversations[index]}
                lastRow={index === filteredConversations.length - 1}
              />
            </div>
          )}
        </List>
      )}
    </div>
  );
};

export default ConversationsList;
