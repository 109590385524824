import { Handle, Position } from "reactflow";
import DeleteNode from "../deleteNode/DeleteNode";
import { FaRegListAlt } from "react-icons/fa";

const handleStyle = { left: "220px", top: "20px" };
const handle1Style = { left: "0px" };

function ListCustomNode({ id, data, isConnectable }) {
  return (
    <div className="bg-white shadow w-60   p-2 rounded-lg">
      <DeleteNode id={id} />
      <Handle
        type="source"
        position={Position.Top}
        style={handleStyle}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        id="c"
        position={Position.Left}
        style={handle1Style}
        isConnectable={isConnectable}
      />
      <div className="">
        <label className="bg-[#E9EDEF] rounded-md py-1 px-2 mb-2 text-[#008069] flex items-center">
          <div className="font-bold text-base">
            <FaRegListAlt />
          </div>
          <div className="ms-2">List</div>
        </label>

        <div className="p-1 rounded-md bg-[#EAE6DF]">
          <div className="w-full rounded-md overflow-hidden mb-1">
            <div className="w-full p-2 bg-white">
              Please select an option to continue -
            </div>
          </div>
          <div className="w-full text-base py-2 border border-[#008069] rounded-md text-[#387ADF] mb-1 bg-white text-center">
            View All Products
          </div>
          <div className="w-full text-base py-2 border border-[#008069] rounded-md text-[#387ADF] mb-1 bg-white text-center">
            Hot Deals!
          </div>

          <div className="btn btn-sm w-full mb-1 text-black border-none bg-white text-center hover:bg-white">
            +Add Button
          </div>
        </div>
        <div className="btn btn-sm w-full mt-1 border-gray-300 text-gray-500 bg-white text-center hover:bg-white">
          +Add Content
        </div>
      </div>

      {/* <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={handleStyle}
        isConnectable={isConnectable}
      /> */}
    </div>
  );
}

export default ListCustomNode;
