import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, TextField, Select, MenuItem } from "@mui/material";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  defs,
} from "recharts";
import { UseAnalytics, UseAnalyticsAgents } from "../../hooks/useAnalytics";
import { Usegetagents } from "../../hooks/useAgenst";

// Sample Data
const initialData = [
  { date: "30 Nov", user: 54, business: 474, chatbot: 18 },
  { date: "01 Dec", user: 150, business: 10, chatbot: 50 },
  { date: "02 Dec", user: 300, business: 600, chatbot: 100 },
  { date: "03 Dec", user: 50, business: 0, chatbot: 30 },
];

const agentData = [
  { agentName: "sagar ", intervenedCount: 4, resolvedCount: 3 },
  { agentName: "zoro", intervenedCount: 4, resolvedCount: 3 },
  { agentName: "luffy", intervenedCount: 4, resolvedCount: 3 },
];

const AnalyticsChart = ({ data, total, title, totalchatsupports }) => (
  <Box>
    <div className="font-normal text-xl ">{title}</div>

    {/* <Typography variant="h6" 
       className="font-normal text-2xl"
      sx={{ marginBottom: 2 }}>    
        user   {total?.user}
      </Typography>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        business {total?.business}
      </Typography>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Chat bot {total?.chatbot}
      </Typography> */}
    {total && (
      <div className="flex justify-between mt-4 w-[900px]">
        {Object.entries(total).map(([key, value], index) => (
          <div key={index}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              <span>
                {" "}
                {key} : {value}
              </span>
            </Typography>
          </div>
        ))}
      </div>
    )}
    {totalchatsupports && (
      <div className="flex justify-between mt-4 w-[900px] ">
        {Object.entries(totalchatsupports).map(([key, value], index) => (
          <div key={index}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              <span>
                {" "}
                {key} : {value}
              </span>
            </Typography>
          </div>
        ))}
      </div>
    )}

    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={data}>
        {/* Gradient for Smooth Background */}
        <defs>
          <linearGradient id="colorUser" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#8884d8" stopOpacity={0.9} />
            <stop offset="100%" stopColor="#8884d8" stopOpacity={0.1} />
          </linearGradient>
          <linearGradient id="colorBusiness" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#BEC001" stopOpacity={0.9} />
            <stop offset="100%" stopColor="#BEC001" stopOpacity={0.1} />
          </linearGradient>
          <linearGradient id="colorChatbot" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#ffc658" stopOpacity={0.9} />
            <stop offset="100%" stopColor="#ffc658" stopOpacity={0.1} />
          </linearGradient>
        </defs>

        {/* Grid and Axis */}
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis domain={["auto", "auto"]} />
        <Tooltip />

        {/* Area Chart Lines */}
        <Area
          type="monotone"
          dataKey="user"
          stroke="#8884d8"
          strokeWidth={2.5}
          fill="url(#colorUser)"
        />
        <Area
          type="monotone"
          dataKey="business"
          stroke="#BEC001"
          strokeWidth={2.5}
          fill="url(#colorBusiness)"
        />
        <Area
          type="monotone"
          dataKey="chatbot"
          stroke="#ffc658"
          strokeWidth={2.5}
          fill="url(#colorChatbot)"
        />

        <Area
          type="monotone"
          dataKey="resolved"
          stroke="#8884d8"
          strokeWidth={2.5}
          fill="url(#colorChatbot)"
        />

        <Area
          type="monotone"
          dataKey="intervened"
          stroke="#ffc658"
          strokeWidth={2.5}
          fill="url(#colorChatbot)"
        />
      </AreaChart>
    </ResponsiveContainer>
  </Box>
);

const App = () => {
  const { isanalytics, isloading, updatetime } = UseAnalytics();
  const { isagentanalyticsloader, getagentanalytics, isanalyticsagent } =
    UseAnalyticsAgents();

  // console.log("today looks likes this :",today);

  // const handleFormatDate = useCallback((date) => {
  //   const getdate = new Date(date);

  //   console.log("get data looks :",getdate);
  //   return  getdate.toISOString().split("T")[0]; // Extracts the date part
  // }, []);

  const formatDates = () => {
    const today = new Date();
    const sevenDaysAgo = new Date();

    // Adjust the sevenDaysAgo date by subtracting 7 days
    sevenDaysAgo.setDate(today.getDate() - 7);

    // Format both dates to "YYYY-MM-DD"
    const formattedToday = today.toISOString().split("T")[0];
    const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];

    return { formattedToday, formattedSevenDaysAgo };
  };

  const today = new Date();
  const { formattedToday, formattedSevenDaysAgo } = formatDates();
  // const initialvalue = handleFormatDate(today);

  console.log(
    "formated date looks likes this ",
    formattedToday,
    formattedSevenDaysAgo
  ); // Output: "2024-12-11"
  const [data, setData] = useState(isanalytics); // Chart data

  console.log("data looks likes this :", data);
  const [range, setRange] = useState("7 days"); // Default range
  const [fromDate, setFromDate] = useState(formattedSevenDaysAgo); // Default from date
  const [toDate, setToDate] = useState(formattedToday); // Default to date
  // console.log("from data :", fromDate);
  // console.log("to data :", toDate);

  console.log("analytics data looks likes this :", isanalyticsagent);

  const [istotalmessage, settotalmessage] = useState([]);

  console.log("istotal message looks likes this :", istotalmessage);
 
  // const total = isanalytics?.reduce(
  //   (acc, curr) => {
  //     acc.user += curr.user;
  //     acc.business += curr.business;
  //     acc.chatbot += curr.chatbot;
  //     return acc;
  //   },
  //   { user: 0, business: 0, chatbot: 0 }
  // );

  // console.log(total);

  // Helper to filter data based on range
  const filterData = (newFromDate, newToDate) => {
    // Assuming real dates are available in your data
    const filteredData = isanalytics?.filter((entry) => {
      const entryDate = new Date(
        `2024-${entry.date.split(" ")[1]}-${entry.date.split(" ")[0]}`
      );
      return (
        entryDate >= new Date(newFromDate) && entryDate <= new Date(newToDate)
      );
    });
    setData(filteredData);
  };

  const { agents: isAllagents } = Usegetagents();

  const allChatsupport = [
    { name: "All", user_Name: "all" }, // Default option
    ...isAllagents.map((data) => ({
      name: data?.name,
      user_Name: data?.user_Name,
    })),
  ];

  console.log("all chat supports look like this:", allChatsupport);

  // Handle manual date changes
  const handleFromDateChange = (e) => {
    const newValue = e.target.value;
    setFromDate(newValue);
    filterData(newValue, toDate);
    setRange("Custom Range");

    // console.log("From Date:", newValue);
  };

  const handleRangeChange = async (selectedRange) => {
    const today = new Date();
    let newFromDate = "";
    let newToDate = today.toISOString().split("T")[0]; // Default: today's date
  
    switch (selectedRange) {
      case "Today": {
        // Yesterday's date
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
  
        newFromDate = yesterday.toISOString().split("T")[0]; // From yesterday
        newToDate = today.toISOString().split("T")[0];       // To today
        break;
      }
  
      case "Yesterday": {
        // Yesterday's date
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
  
        // Day before yesterday
        const dayBeforeYesterday = new Date(today);
        dayBeforeYesterday.setDate(today.getDate() - 2);
  
        newFromDate = dayBeforeYesterday.toISOString().split("T")[0]; // From day before yesterday
        newToDate = yesterday.toISOString().split("T")[0];           // To yesterday
        break;
      }
  
      case "7 days":
      case "7 Days": {
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 7);
        newFromDate = sevenDaysAgo.toISOString().split("T")[0];
        break;
      }
  
      case "30 days":
      case "30 Days": {
        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(today.getDate() - 30);
        newFromDate = thirtyDaysAgo.toISOString().split("T")[0];
        break;
      }
  
      default:
        newFromDate = today.toISOString().split("T")[0];
    }
  
    // Update the state with calculated from and to dates
    setFromDate(newFromDate);
    setToDate(newToDate);
  
    // Call filterData with the updated date range
    filterData(newFromDate, newToDate);
  };
  
  

  const handleToDateChange = async (e) => {
    const newValue = e.target.value;
    setToDate(newValue);
    filterData(fromDate, newValue);
    setRange("Custom Range");
    // const updateData = {
    //   startDate: fromDate,
    //   endDate: toDate,
    // };

    // console.log("update data in range looks likes this :", updateData);
    //  await updatetime(updateData);
    // await handlefetchData(updateData);
    // await handlefetchData(fromDate,toDate);
    // console.log("To Date:", newValue);
  };
  const [isselectedChatsupport, seselectedChatsupport] = React.useState("all");
  console.log("selected data looks likes this :", isselectedChatsupport);

  useEffect(() => {
    const fetchData = async () => {
      if (fromDate && toDate) {
        await handlefetchData(fromDate, toDate);
      }

      if (isselectedChatsupport && fromDate && toDate) {
        await handleupdateData(fromDate, toDate, isselectedChatsupport);
      }
    };
    fetchData();
  }, [fromDate, toDate, isselectedChatsupport]);
  const handlefetchData = async (fromDate, toDate) => {
    const updateData = {
      startDate: fromDate,
      endDate: toDate,
    };
    await updatetime(updateData);
  };

  const chatTitle = "Chats (per day)";
  const chatsupporttitle = "Agent Activity (per day)";

  const handleupdateData = async (fromDate, toDate, isselectedChatsupport) => {
    const updateData = {
      startDate: fromDate,
      endDate: toDate,
      agentName: isselectedChatsupport,
    };
    console.log("this function is triggred to updateagent graph");
    await getagentanalytics(updateData);
  };

  function combineDataByDate(data) {
    // Map to store grouped data
    const groupedData = new Map();

    // Loop through each object in the array
    data.forEach((item) => {
      const { date, intervened, resolved } = item;

      // If the date is already in the map, update the values
      if (groupedData.has(date)) {
        const existing = groupedData.get(date);
        groupedData.set(date, {
          date: date,
          intervened: existing.intervened + intervened,
          resolved: existing.resolved + resolved,
        });
      } else {
        // If the date is not in the map, add it
        groupedData.set(date, {
          date: date,
          intervened: intervened,
          resolved: resolved,
        });
      }
    });

    // Convert the map values back to an array
    return Array.from(groupedData.values());
  }

  const [isagentgraph, setagentgraph] = useState();
  useEffect(() => {
    if (
      isanalyticsagent?.activityByDate &&
      isanalyticsagent.activityByDate.length > 0
    ) {
      let allobject = [];
      if (
        Array.isArray(isanalyticsagent.activityByDate) &&
        isanalyticsagent.activityByDate.length > 1
      ) {
        isanalyticsagent.activityByDate.forEach((outerData) => {
          // Check if 'activityByDate' exists and is an array
          if (Array.isArray(outerData.activityByDate)) {
            // Loop through the inner array and push into allobject
            outerData.activityByDate.forEach((innerData) => {
              allobject.push(innerData);
            });
          }
        });
        const data = combineDataByDate(allobject);
        console.log("all object data looks likes this :", data);
        setagentgraph(data);
      } else {
        setagentgraph(isanalyticsagent.activityByDate[0]?.activityByDate);
        console.log(
          "selected inside the state look likes this :",
          isanalyticsagent.activityByDate[0]?.activityByDate
        );
      }
    } else {
      setagentgraph(null); // Default or fallback value
    }
  }, [isanalyticsagent]);



  useEffect(() => {
    if (!isanalytics || !Array.isArray(isanalytics)) {
      console.error("isanalytics is not valid:", isanalytics);
      return;
    }

    const total = isanalytics.reduce(
      (acc, curr) => {
        acc.user += curr.user;
        acc.business += curr.business;
        acc.chatbot += curr.chatbot;
        return acc;
      },
      { user: 0, business: 0, chatbot: 0 }
    );

    settotalmessage(total);
  }, [isanalytics]);


  // console.log("all agents looks likes this :", isanalytics);
  console.log("all agents looks likes this :", isagentgraph);

  const totalchatsupports = isagentgraph?.reduce(
    (acc, curr) => {
      acc.intervened += curr.intervened;
      acc.resolved += curr.resolved;
      // acc.chatbot += curr.chatbot;
      return acc;
    },
    { intervened: 0, resolved: 0 }
  );







  console.log("total counts looks likes this :", totalchatsupports);

  return (
    <>
      <Box sx={{ padding: 4 }}>
        {/* Filters */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 3,
          }}
        >
          {/* Dropdown for Quick Ranges */}
          <Select
            value={range}
            onChange={(e) => {
              setRange(e.target.value);
              handleRangeChange(e.target.value);
            }}
            size="small"
            sx={{ width: 150 }}
          >
            <MenuItem value="Today">Today</MenuItem>
            <MenuItem value="Yesterday">Yesterday</MenuItem>
            <MenuItem value="7 days">7 Days</MenuItem>
            <MenuItem value="30 days">30 Days</MenuItem>
            {range === "Custom Renge" && (
              <MenuItem value="Custom Renge">Custom Range</MenuItem>
            )}
          </Select>

          <TextField
            label="From"
            type="date"
            size="small"
            value={fromDate}
            onClick={(e) => e.target.showPicker()} // Show the date picker on input click
            onChange={handleFromDateChange}
            inputProps={{
              max: toDate, // Prevent selecting future dates
            }}
            sx={{ width: 150 }}
          />

          <TextField
            label="To"
            type="date"
            size="small"
            value={toDate}
            onClick={(e) => e.target.showPicker()} // Show the date picker on input click
            onChange={handleToDateChange}
            inputProps={{
              max: new Date().toISOString().split("T")[0], // Prevent selecting future dates
              min: fromDate, // Prevent selecting a date before "From"
            }}
            sx={{ width: 150 }}
          />
        </Box>

        {/* Analytics Chart */}
        <AnalyticsChart
          data={isanalytics}
          total={istotalmessage}
          title={chatTitle}
        />
      </Box>

      <Box sx={{ padding: 4 }}>
        {/* Filters */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 3,
          }}
        >
          <div className="w-full flex ">
            {/* Dropdown for Quick Ranges */}
            <Select
              value={isselectedChatsupport}
              onChange={(e) => seselectedChatsupport(e.target.value)}
              size="small"
              sx={{ width: 150 }}
            >
              {allChatsupport.map((data, index) => (
                <MenuItem key={index} value={data?.user_Name}>
                  {data?.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </Box>

        {/* Analytics Chart */}
        <AnalyticsChart
          data={isagentgraph}
          totalchatsupports={totalchatsupports}
          title={chatsupporttitle}
        />
      </Box>
    </>
  );
};

export default App;

// import React from "react";
// import { Box, Typography, TextField } from "@mui/material";
// import {
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
//   defs,
// } from "recharts";

// const data = [
//   { date: "30 Nov", user: 54, business: 474, chatbot: 18 },
//   { date: "01 Dec", user: 150, business: 10, chatbot: 50 },
//   { date: "02 Dec", user: 300, business: 600, chatbot: 100 },
//   { date: "03 Dec", user: 50, business: 0, chatbot: 30 },
// ];

// const AnalyticsChart = () => (
//   <Box>
//     <Typography variant="h6" sx={{ marginBottom: 2 }}>
//       Chats (per day)
//     </Typography>
//     <ResponsiveContainer width="100%" height={300}>
//       <AreaChart data={data}>
//         {/* Gradient for Smooth Background */}
//         <defs>
//           <linearGradient id="colorUser" x1="0" y1="0" x2="0" y2="1">
//             <stop offset="0%" stopColor="#8884d8" stopOpacity={0.8} />
//             <stop offset="100%" stopColor="#8884d8" stopOpacity={0} />
//           </linearGradient>
//           <linearGradient id="colorBusiness" x1="0" y1="0" x2="0" y2="1">
//             <stop offset="0%" stopColor="#BEC001" stopOpacity={0.8} />
//             <stop offset="100%" stopColor="#BEC001" stopOpacity={0} />
//           </linearGradient>
//           <linearGradient id="colorChatbot" x1="0" y1="0" x2="0" y2="1">
//             <stop offset="0%" stopColor="#ffc158" stopOpacity={0.8} />
//             <stop offset="100%" stopColor="#ffc658" stopOpacity={0} />
//           </linearGradient>
//         </defs>

//         {/* Grid and Axis */}
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="date" />
//         <YAxis domain={[0, "dataMax"]} />
//         <Tooltip />

//         {/* Area Chart Lines */}
//         <Area
//           type="monotone"
//           dataKey="user"
//           stroke="#8884d8"
//           strokeWidth={2.5}
//           fill="url(#colorUser)"
//         />
//         <Area
//           type="monotone"
//           dataKey="business"
//           stroke="#BEC001"
//           strokeWidth={2.5}
//           fill="url(#colorBusiness)"
//         />
//         <Area
//           type="monotone"
//           dataKey="chatbot"
//           stroke="#ffc658"
//           strokeWidth={2.5}
//           fill="url(#colorChatbot)"
//         />
//       </AreaChart>
//     </ResponsiveContainer>
//   </Box>
// );

// const App = () => {
//   return (
//     <Box sx={{ padding: 4 }}>
//       {/* Filters */}
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "space-between",
//           marginBottom: 3,
//         }}
//       >
//         <TextField
//           label="From"
//           variant="outlined"
//           size="small"
//           type="date"
//           defaultValue="2024-11-30"
//           sx={{ width: 150 }}
//         />
//         <TextField
//           label="To"
//           variant="outlined"
//           size="small"
//           type="date"
//           defaultValue="2024-12-07"
//           sx={{ width: 150 }}
//         />
//       </Box>

//       {/* Analytics Chart */}
//       <AnalyticsChart />
//     </Box>
//   );
// };

// export default App;
