import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, Outlet, Navigate, Router } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";

import Dashboard from "./pages/dashboard";
import LiveChat from "./pages/liveChat";
import Contacts from "./pages/contacts";
// import Form from "./pages/form";
import Calendar from "./pages/calendar";
import FAQ from "./pages/faq";
import SignUp from "./pages/signUp";
import Login from "./pages/login";
import Flow from "./pages/flow";
import Campaigns from "./pages/campaigns";
import ECommerce from "./pages/integration";
import AnalyzeBar from "./pages/analyzeBar";
import ApiKey from "./pages/apiKey";
import Template from "./pages/template";
import Explore from "./pages/template/Explore";
import AllList from "./pages/template/AllList";
import Draft from "./pages/template/Draft";
import ActionRequird from "./pages/template/ActionRequird";
import Pending from "./pages/template/Pending";
import Approval from "./pages/template/Approval";
import Profile from "./pages/profile";
import CreateTemplate from "./components/template/CreateTemplate";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import "./toast.css";
import { UseAuthContext } from "./context/AuthContext";
import { useEffect } from "react";
import Biling from "./pages/biling/Biling";
import OTP from "./pages/otp";
import Marketing from "./pages/template/Marketing";
import Utility from "./pages/template/Utility";
import Authentication from "./pages/template/Authentication";
import OptSystem from "./pages/MangeOpt/OptSystem";
import UserConcerns from "./pages/UserConcern";
import { useGetAuthDetails } from "./hooks/useLogin";
import Overview from "./pages/campaigns/Overview";
import Sent from "./pages/campaigns/Sent";
import Delivered from "./pages/campaigns/Delivered";
import Read from "./pages/campaigns/Read";
import Failed from "./pages/campaigns/Failed";
import GoogleSheet from "./pages/googleSheet";
import Integration from "./pages/integration";
import Replied from "./pages/campaigns/Replied";
import AppStore from "./pages/integration/AppStore";
import AnaCamp from "./components/Campaign/AnaCamp";
import WooCommerceSS from "./pages/integration/WooCommerce";
import { SetupCatalog } from "./pages/integration/FaceBookCatLog";
import ShopifySS from "./pages/integration/Shopify";
import StoreSetupSPF from "./pages/integration/Components/shopify/StoreSetup";
import AbandonedCart from "./pages/integration/Components/shopify/AbandonedCart";
import CartFlowpage from "./pages/integration/Components/GlobalCmp/CartFlowpage";
import ConfirmationFlow from "./pages/integration/Components/shopify/ConfirmationFlow";
import OrderFlow from "./pages/integration/Components/shopify/OrderFlow";
import ComingSoonPage from "./components/GlobalComponets/ComingSoonPage";
import SetUpMesssageCard from "./pages/integration/Components/shopify/setupMessages";
import Agent from "./pages/Agent";
import useAgentsZus from "./zustand/useAgents.Zus";
import useLogOut from "./hooks/useLogOut";
import ManageTag from "./pages/Manage/manage tag";
import ShopifyAnalytics from "./pages/integration/Components/shopify/ShopifyAnalytics";
const MainDash = () => (
  <MyProSidebarProvider>
    <div style={{ height: "100%", width: "100%" }}>
      <main>
        <Outlet />
      </main>
    </div>
  </MyProSidebarProvider>
);

// Utility function to check if a cookie exists
const checkCookieExists = (cookieName) => {
  const cookies = document.cookie.split(";");

  // console.log(cookies);
  // Iterate over all cookies
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Log each cookie being checked
    if (cookie.startsWith(`${cookieName}=`)) {
      return true;
    }
  }
  return false;
};

const App = () => {
  const { authUser, setAuthUser } = UseAuthContext();
  const { authLoading, getAuthDetails } = useGetAuthDetails();
  const [theme, colorMode] = useMode();

  useEffect(() => {
    if (!checkCookieExists("THEBOTMODE_CHECK")) {
      localStorage.removeItem("authUser");
      setAuthUser(null);  
    }
    if (!authUser?.agnet_id) {
      getAuthDetails();
    }
  }, []); // Empty dependency array ensures this runs once on mount

  // To check if agent exists
  const { loadingAgent, agents } = useAgentsZus();
  const { logOut } = useLogOut();
  useEffect(() => {
    if (authUser?.agnet_id && agents && agents.length > 0) {
      // Check if the authUser's agent_id exists in agents
      const checkuser = agents.some((user) => user?._id === authUser?.agnet_id);

      if (!checkuser && agents && !loadingAgent) {
        logOut();
        toast.success("User session terminated.");
      }
    }
  }, [agents, logOut, loadingAgent]); // Added logOut as a dependencyS

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {!authLoading && (
            <Routes>
              <Route
                path="/signup"
                element={authUser ? <Navigate to={"/"} /> : <SignUp />}
              />
              <Route
                path="/login"
                element={authUser ? <Navigate to={"/"} /> : <Login />}
              />
              <Route
                path="/*"
                element={authUser ? <MainDash /> : <Navigate to={"/login"} />}
              >
                <Route path="" element={<Dashboard />} />
                <Route path="livechat" element={<LiveChat />} />
                <Route path="contacts" element={<Contacts />} />
                <Route path="campaigns" element={<Campaigns />} />
                <Route path="campaigns/analytics/:id/*" element={<AnaCamp />}>
                  <Route path="camp/overview" element={<Overview />} />
                  <Route path="camp/sent" element={<Sent />} />
                  <Route path="camp/Delivered" element={<Delivered />} />
                  <Route path="camp/read" element={<Read />} />
                  <Route path="camp/replied" element={<Replied />} />
                  <Route path="camp/failed" element={<Failed />} />
                </Route>
                <Route path="apikey" element={<ApiKey />} />
                <Route path="profile" element={<Profile />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="calendar" element={<Calendar />} />
                <Route path="flow" element={<Flow />} />
                <Route path="analytics" element={<AnalyzeBar />} />
                <Route path="template/create" element={<CreateTemplate />} />
                <Route path="optSystem" element={<OptSystem />} />
                <Route path="Agent" element={<Agent />} />
                <Route path="ManageTag" element={<ManageTag/>}/>
                <Route path="UserConcerns" element={<UserConcerns />} />

                <Route path="template/*" element={<Template />}>
                  {/* <Route path="" element={<Explore />} /> */}
                  <Route path="message/Marketing" element={<Marketing />} />
                  <Route path="message/Utility" element={<Utility />} />
                  {/* <Route path="message/all" element={<AllList />} />   */}
                  {/* <Route path="message/draft" element={<Draft />} /> */}
                  <Route
                    path="message/Authentication"
                    element={<Authentication />}
                  />
                  <Route path="message/pending" element={<Pending />} />
                  <Route path="message/approval" element={<Approval />} />
                  <Route path="message/action" element={<ActionRequird />} />
                </Route>

                <Route path="/*" element={<Integration />}>
                  <Route path="integration" element={<AppStore />} />
                  <Route
                    path="integration/appstore/SetupCatalog"
                    element={<SetupCatalog />}
                  />
                  <Route
                    path="integration/appstore/shopify/*"
                    element={<ShopifySS />}
                  >
                    <Route path="storeSetup" element={<StoreSetupSPF />} />
                    <Route
                      path="setupMessages"
                      element={<SetUpMesssageCard />}
                    />
                    <Route
                      path="shopifyCheckouts"
                      element={<ComingSoonPage />}
                    />
                    <Route path="AbandonedCart" element={<AbandonedCart />} />
                    <Route
                      path="ConfirmationFlow"
                      element={<ConfirmationFlow />}
                    />
                    <Route path="OrderFlow" element={<OrderFlow />} />
                    <Route path="ShopifyAnalytics" element={<ShopifyAnalytics />} />
                    {/* CartFlowpage for reuseable components */}
                    <Route path=":title/:subtitle" element={<CartFlowpage />} />
                  </Route>
                  <Route
                    path="integration/appstore/woocommerce"
                    element={<WooCommerceSS />}
                  />
                </Route>

                <Route path="biling" element={<Biling />} />
              </Route>

              <Route
                path="/forgetPass"
                element={authUser ? <Navigate to={"/"} /> : <OTP />}
              />

              <Route path="/api/auth/google" element />
              <Route path="/googleSheet" element={<GoogleSheet />} />
            </Routes>
          )}
        </ThemeProvider>
      </ColorModeContext.Provider>

      {/* toaster */}
      {/* <Toaster /> */}
      <div>
        {/* Global Toaster */}
        <Toaster
          toastOptions={{
            style: {
              zIndex: 9999, // Very high to ensure it stays on top
            },
          }}
        >
          {(t) => (
            <ToastBar
              toast={t}
              style={{
                ...t.style,
                zIndex: 9999, // Very high to ensure it stays on top
              }}
            />
          )}
        </Toaster>
      </div>
    </>
  );
};

export default App;
