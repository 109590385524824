import React, { useState } from "react";
import {
  Box,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Utility to generate random tag styles
const getTagStyle = (index) => {
  const colors = [
    { bg: "#FDE2FF", text: "#9A00B7" },
    { bg: "#E0F9F4", text: "#007A6A" },
    { bg: "#FFF5D9", text: "#B78200" },
    { bg: "#E8F3FF", text: "#0057B7" },
  ];
  return colors[index % colors.length];
};

const TagsCell = ({ tags }) => {
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);

  return (
    <>
      <Box display="flex" alignItems="center">
        {/* Inline tags */}
        {tags.slice(0, 2).map((tag, index) => {
          const style = getTagStyle(index);
          return (
            <Tooltip key={index} title={tag}>
              <Box
                sx={{
                  backgroundColor: style.bg,
                  color: style.text,
                  borderRadius: "16px",
                  padding: "4px 8px",
                  marginRight: "5px",
                  fontWeight: "bold",
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
              >
                {tag}
              </Box>
            </Tooltip>
          );
        })}

        {/* More Option */}
        {tags.length > 2 && (
          <Button
            size="small"
            onClick={handleOpenDialog}
            sx={{
              color: "#000",
              fontWeight: "bold",
              textDecoration: "underline",
              padding: 0,
              minWidth: "auto",
            }}
          >
            +{tags.length - 2} more
          </Button>
        )}
      </Box>

      {/* Dialog for all tags */}
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          All Tags
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <List>
            {tags.map((tag, index) => {
              const style = getTagStyle(index);
              return (
                <ListItem
                  key={index}
                  sx={{
                    backgroundColor: style.bg,
                    color: style.text,
                    borderRadius: "16px",
                    padding: "6px 12px",
                    marginBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  {tag}
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TagsCell;
