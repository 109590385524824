import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import useZusConversation from "../zustand/useZusConversation";

const useContact = () => {
  const { loadingCNV, setLoadingCNV, conversations, setConversations } =
    useZusConversation();

  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCr, setLoadingCr] = useState(false);
  const [loadingSheet, setLoadingSheet] = useState(false);

  const uploadContacts = async (contacts) => {
    if (!contacts || !Array.isArray(contacts)) {
      toast.error("No contacts provided or invalid format.");
      return;
    }

    // Validate all contacts; if any are invalid, stop execution
    for (const contact of contacts) {
      if (!handleInputError(contact)) {
        return; // Stop further execution if validation fails
      }
    }

    setLoading(true);

    try {
      const res = await axios.post("/api/contact/bulk/upload", contacts);
      const data = res.data;
      // console.log("uploadContacts:", data.created);

      const newCreated = data.newCreated || [];

      setConversations([...conversations, ...newCreated]);

      // if (data.skipped_to_avoid_repeatation > 0) {
      //   toast(data.message, {
      //     icon: "👏",
      //     style: {
      //       borderRadius: "10px",
      //       background: "#333",
      //       color: "#fff",
      //     },
      //   });
      // }

      toast.success(
        <div>
          {data.created !== 0 && (
            <p className="text-green-500">
              <b style={{ color: "green" }}>{data.created || 0}</b> contact(s){" "}
              <b>saved successfully</b>.
            </p>
          )}
          {data.skipped !== 0 && (
            <p className="text-red-500">
              <b style={{ color: "red" }}>{data.skipped || 0}</b> contact(s){" "}
              <b>could not be saved</b> due to existing contacts.
            </p>
          )}
        </div>
      );
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  const CreateContact = async ({ name, tags, source }, number) => {
    let status = false;
    const success = handleInputError({
      Name: name,
      Mobile_Number: number,
      tags,
      source,
    });

    if (!success) return;

    try {
      setLoadingCr(true);
      const res = axios.post("/api/contact/single/upload", {
        name,
        source,
        mobile_Number: number,
        tags,
      });
      const data = await res;

      const newConatact = data.data.contact;

      if (newConatact) {
        setConversations([...conversations, { ...newConatact }]);
      }

      toast.success(data.data.message);

      status = true;
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoadingCr(false);
    }

    return status;
  };

  const deleteContacts = async (contacts) => {
    if (!contacts || !Array.isArray(contacts)) {
      toast.error("No contacts provided!");
      return;
    }
    if (contacts.length === 0) {
      toast.error("No contacts provided!");
      return;
    }

    console.log("filteredData:", contacts);

    try {
      setLoadingDelete(true);

      // Use data property instead of params
      const res = await axios.delete("/api/contact/delete", {
        data: { contacts }, // Send contacts in the body
      });
      const data = res.data;
      const deleted = data.deleted || []; // Fallback to empty array if not provided

      if (Array.isArray(conversations)) {
        const newData = conversations.filter(
          (item) => !deleted.includes(item._id) // Exclude deleted IDs
        );

        setConversations(newData);
        toast.success(data.message);
      } else {
        console.error("Conversations is not an array!");
      }
      console.log("uploadContacts:", data);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
      console.error("error:", error);
    } finally {
      setLoadingDelete(false);
    }
  };

  const refreshSheetData = async () => {
    try {
      setLoadingSheet(true);

      const res = await axios.get("/api/sheets/data");
      const data = res.data;

      const latestConatcts = data.latestConatcts || [];

      if (Array.isArray(latestConatcts) && latestConatcts.length > 0) {
        setConversations(latestConatcts);
      }

      console.log("refreshSheetDataFn:", res.data);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoadingSheet(false);
    }
  };

  // to check for number
  const handleInputError = (contact) => {
    const { Name, Mobile_Number } = contact;

    console.log("contact:", contact);

    if (!Name) {
      toast.error(
        "A row contains blank spaces. Please ensure no row has empty fields."
      );
      return false;
    }
    if (!Mobile_Number) {
      toast.error(
        "Mobile Number is required. or A row contains blank spaces. Please ensure no row has empty fields."
      );
      return false;
    }

    // const indianPhoneNumberRegex = /^(?:\+91|91)?[6-9]\d{9}$/;

    // if (!indianPhoneNumberRegex.test(Mobile_Number)) {
    //   toast.error(`"${Name}": Invalid number format.
    //     Should be 10 digits or +91 followed by 10 digits.`);
    //   return false;
    // }

    return true; // Validation passed
  };

  return {
    loading,
    uploadContacts,
    loadingCr,
    CreateContact,
    loadingDelete,
    deleteContacts,
    loadingSheet,
    refreshSheetData,
  };
};

export default useContact;
