import React, { useState, useEffect } from "react";
import "./ClientProfile.css";
import { MdOutlineShowChart } from "react-icons/md";
import { RiCheckDoubleLine } from "react-icons/ri";
import useZusConversation from "../../../zustand/useZusConversation";
import { formatDate } from "../../../utils/extractTime";
import { useChangeOptin } from "../../../hooks/useOptin";
import toast from "react-hot-toast";
import { IoIosClose } from "react-icons/io";
import { MenuItem, Select } from "@mui/material";
import {
  UsecreateAndAddTag,
  UsegetAlltags,
  useRemoveTags,
} from "../../../hooks/useTags";
import useAllTags from "../../../zustand/useAllTags.Zus";

const ClientProfile = () => {
  const { selectedConversation } = useZusConversation();
  const { loading, changeOptStatus } = useChangeOptin();
  const { isloading, RemoveContact } = useRemoveTags();

  const { getalltags } = UsegetAlltags();
  const { AllListTags, loadingAllTags } = useAllTags();

  console.log("get all the tags :", AllListTags);
  const { iscreatedandAddedtag, isloader, createandAddtags } =
    UsecreateAndAddTag();
  // Initialize the state with a default value
  const [optin, setOptin] = useState(false);

  useEffect(() => {
    if (selectedConversation?.OptedIn instanceof Promise) {
      selectedConversation.OptedIn.then((result) => {
        setOptin(result);
      }).catch((error) => {
        console.error("Error fetching OptedIn:", error);
      });
    } else {
      setOptin(selectedConversation?.OptedIn || false);
    }
  }, [selectedConversation]);

  console.log("joinedOn:", selectedConversation);

  const handleChangeOptin = async (e) => {
    const isChecked = e.target.checked;
    console.log("from front:", isChecked);

    const userConfirmed = window.confirm(
      `Are you sure you want to change the opt-in status to ${
        isChecked ? "enabled" : "disabled"
      }?`
    );

    console.log("userConfirmed:", userConfirmed);
    console.log("from front after confirm:", isChecked);

    if (userConfirmed) {
      await changeOptStatus(selectedConversation?._id, isChecked);
      setOptin(isChecked);
    } else {
      // Revert the checkbox state
      e.target.checked = optin;
    }
  };

  const lastActiveDate = formatDate(selectedConversation?.last_Active);
  const [isCollapse, setIsCollapse] = useState(false);
  const [isCollapse1, setIsCollapse1] = useState(false);
  const [isCollapse2, setIsCollapse2] = useState(false);
  const [isCollapse3, setIsCollapse3] = useState(false);
  const [isCollapse4, setIsCollapse4] = useState(false);
  const [istags, settags] = useState([]);
  const [istagsinput, settagsinput] = useState("");
  console.log("is tags looks likes this :", istags);

  const [iscreateAdd, setcreateAdd] = useState(false);
  const [isaddtag, setaddtag] = useState(true);

  const [isselectedChatsupport, seselectedChatsupport] = React.useState("");
  console.log("selected data looks likes this :", isselectedChatsupport);

  const [allChatsupport, setAllChatsupport] = useState([
    // { name: "All", user_Name: "all" }, // Default option
    // { name: "yellow", user_Name: "yellow" },
    // { name: "red ", user_Name: "red" },
  ]);

  useEffect(() => {
    if (!loadingAllTags && AllListTags[0]?.tags?.length > 0) {
      const formattedData = transformTagsToData(
        AllListTags[0].tags,
        AllListTags[0].createdAt
      );
      setAllChatsupport(formattedData);
    }
  }, [loadingAllTags]);

  const transformTagsToData = (tagsArray, createdAt) => {
    console.log("transformTagsToData:", tagsArray, createdAt);

    return tagsArray.map((tag) => ({
      name: tag,
      user_Name: tag,
    }));
  };

  let randomcolor = [
    { text: "text-blue-800", bg: "bg-blue-100" },
    { text: "text-red-800", bg: "bg-red-100" },
    { text: "text-green-800", bg: "bg-green-100" },
    { text: "text-yellow-800", bg: "bg-yellow-100" },
    { text: "text-purple-800", bg: "bg-purple-100" },
    { text: "text-pink-800", bg: "bg-pink-100" },
    { text: "text-teal-800", bg: "bg-teal-100" },
    { text: "text-indigo-800", bg: "bg-indigo-100" },
    { text: "text-gray-800", bg: "bg-gray-100" },
    { text: "text-orange-800", bg: "bg-orange-100" },
    { text: "text-cyan-800", bg: "bg-cyan-100" },
    { text: "text-lime-800", bg: "bg-lime-100" },
    { text: "text-rose-800", bg: "bg-rose-100" },
    { text: "text-amber-800", bg: "bg-amber-100" },
    { text: "text-violet-800", bg: "bg-violet-100" },
  ];

  // const addTag = () => {
  //   if (istagsinput.trim()) {
  //     const randomIndex = Math.floor(Math.random() * randomcolor.length);
  //     const selectedColor = randomcolor[randomIndex];

  //     settags([...istags, { text: istagsinput.trim(), color: selectedColor }]);

  //     settagsinput(""); // Clear input field
  //   }
  // };

  // useEffect(()=>{
  //   setalltags([...selectedConversation?.tags, ])
  // },[selectedConversation,isgetalltags]);

  // useEffect(() => {
  //   if (selectedConversation?.newTag) { // Assuming `newTag` is the tag text you want to add
  //     const randomIndex = Math.floor(Math.random() * isgetalltags.length);
  //     const selectedColor = isgetalltags[randomIndex]; // Select a random color from isgetalltags

  //     setalltags((prevTags) => [
  //       ...prevTags,
  //       { text: selectedConversation.newTag.trim(), color: selectedColor }
  //     ]);
  //   } else {
  //     setalltags([...selectedConversation?.tags]); // Initialize with tags from selectedConversation
  //   }
  // }, [selectedConversation, isgetalltags]);

  // useEffect(() => {
  //   if (selectedConversation?.tags) { // Assuming `newTag` is the tag you want to add
  //     const randomIndex = Math.floor(Math.random() * randomcolor.length);
  //     const selectedColor = randomcolor[randomIndex];

  //     setalltags((prevTags) => [
  //       ...prevTags,
  //       { text: selectedConversation?.tags.trim(), color: selectedColor }
  //     ]);

  //   }
  // }, [selectedConversation, randomcolor, setalltags]);

  const [isgetalltags, setalltags] = useState([]);

  // useEffect(() => {
  //   if (Array.isArray(selectedConversation?.tags) && selectedConversation.tags.length > 0) {
  //     const updatedTags = selectedConversation.tags.map((tag) => {
  //       const randomIndex = Math.floor(Math.random() * randomcolor.length);
  //       const selectedColor = randomcolor[randomIndex];
  //       return { text: tag.trim(), color: selectedColor };
  //     });

  //     setalltags((prevTags) => {
  //       const newTags = updatedTags.filter(
  //         (newTag) => !prevTags.some((prevTag) => prevTag.text === newTag.text)
  //       );
  //       if (newTags.length === 0) {
  //         return prevTags; // Prevent unnecessary state updates
  //       }
  //       return [...prevTags, ...newTags];
  //     });
  //   }
  // }, [selectedConversation?.tags, randomcolor]);

  useEffect(() => {
    if (
      Array.isArray(selectedConversation?.tags) &&
      selectedConversation.tags.length > 0
    ) {
      // Map tags to stable colors based on their index
      const updatedTags = selectedConversation?.tags.map((tag, index) => {
        const selectedColor = randomcolor[index % randomcolor.length];
        return { text: tag?.trim(), color: selectedColor };
      })||[];

      // Avoid unnecessary state updates
      setalltags((prevTags) => {
        const hasChanged =
          JSON.stringify(prevTags) !== JSON.stringify(updatedTags);
        return hasChanged ? updatedTags : prevTags;
      });
    } else {
      setalltags((prevTags) => (prevTags.length > 0 ? [] : prevTags)); // Clear only if tags exist
    }
  }, [selectedConversation?.tags, randomcolor]);

  console.log("is all tags from conversation looks likes this :", isgetalltags);

  const handletags = async () => {
    let updateData = {};
    if (isselectedChatsupport) {
      updateData = {
        contactId: selectedConversation?._id,
        tag: isselectedChatsupport,
      };
    } else {
      updateData = {
        contactId: selectedConversation?._id,
        tag: istagsinput,
      };
    }
    await createandAddtags(updateData);

    settagsinput("");
  };

  const deleteTag = async (tagtext) => {
    const updateData = {
      contactId: selectedConversation?._id,
      tag: tagtext,
    };
    await RemoveContact(updateData);
  };

  return (
    <div
      className={`border-none w-1/4 bg-[#F8F8F8] flex flex-col text-black ${
        !selectedConversation && "blur-sm pointer-events-none"
      }`}
    >
      <>
        {/* HEADER */}
        <div className="bg-[#0A474C] text-white text-base font-medium text-center px-4 py-4 mb-2">
          Chat Profile
        </div>
        <div className="overflow-auto">
          <div className="flex justify-center my-5 items-center">
            <div className="w-14 h-14 me-2 bg-[#FFA500] flex items-center justify-center text-3xl font-normal text-white rounded-full">
              {selectedConversation?.name.charAt(0)}
            </div>
            <div className="font-medium ms-2">
              <div className="text-lg">{selectedConversation?.name}</div>
              <div>+{selectedConversation?.mobile_Number}</div>
            </div>
          </div>

          <div className="divider divider-neutral opacity-20 w-[16vw] mx-auto mt-1" />
          <div className="bg-[#EBF5F3] text-xs rounded-lg mx-4 p-4 font-medium">
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Status</h6>
              <p className="w-1/2">Active</p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Last Active</h6>
              <p className="w-1/2 text-xs font-normal">{lastActiveDate}</p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Template Messages</h6>
              <p className="w-1/2">
                {selectedConversation?.templateSendCount || 0}
              </p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Source</h6>
              <p className="w-1/2">{selectedConversation?.source}</p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">First Message</h6>
              <p className="w-1/2">...</p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Incoming</h6>
              <p className="w-1/2">Allowed</p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Chat expiresAt</h6>
              <p className="w-1/2 text-xs font-normal">
                {formatDate(selectedConversation?.expiresAt)}
              </p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Joined On</h6>
              <p className="w-1/2 text-xs font-normal">
                {formatDate(selectedConversation?.createdAt)}
              </p>
            </div>
            <div className="flex mb-1">
              <h6 className="w-1/2 me-2 text-[#4A4A4A]">Opted In</h6>
              <input
                type="checkbox"
                disabled={loading}
                checked={optin}
                onChange={(e) => handleChangeOptin(e)}
                className="toggle toggle-sm toggle-success"
              />
            </div>
          </div>

          <div className="px-4">
            <div
              className="collapse collapse-arrow rounded-md bg-white mt-4"
              onClick={() => {
                setIsCollapse2(!isCollapse2);
              }}
            >
              <input type="radio" name="my-accordion-3" checked={isCollapse2} />
              <div className="collapse-title textarea-md font-medium">
                Attributes
              </div>
              <div className="collapse-content text-gray-600 font-medium">
                <p>Coming soon...</p>
              </div>
            </div>
            <div
              className="collapse collapse-arrow rounded-md bg-white mt-4"
              onClick={() => {
                setIsCollapse3(!isCollapse3);
              }}
            >
              <input type="radio" name="my-accordion-4" checked={isCollapse3} />
              <div className="collapse-title textarea-md font-medium">Tags</div>

              <div className="collapse-content text-gray-600 font-medium">
                <div
                  className="space-y-2 flex gap-2 flex-wrap items-center"
                  onClick={(e) => e.stopPropagation()}
                >
                  {isgetalltags.map((tag, index) => (
                    <div
                      key={index}
                      className={`max-h-8 flex gap-1 w-fit rounded-lg p-2 mt-1 items-center cursor-pointer ${tag.color.bg} ${tag.color.text}`}
                    >
                      {tag.text}
                      <IoIosClose
                        className="text-xl"
                        onClick={() => deleteTag(tag.text)}
                      />
                    </div>
                  ))}
                </div>

                {iscreateAdd && (
                  <div
                    className="flex gap-3 items-center justify-center mt-2"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      type="text"
                      placeholder=" Tag Name"
                      className="input input-xs input-bordered w-[100px] h-auto p-2 bg-[#F0F0F0] text-black  placeholder:font-medium font-normal"
                      maxLength={20}
                      onChange={(e) => {
                        settagsinput(e.currentTarget.value);
                      }}
                      onClick={(e) => e.stopPropagation()} // Prevent event bubbling
                      value={istagsinput}
                    />
                    <button
                      className="btn btn-outline btn-sm   w-[100px] text-green-600"
                      onClick={() => {
                        // addTag();
                        handletags();
                        seselectedChatsupport("");
                      }}
                    >
                      + Create{" "}
                    </button>
                  </div>
                )}

                {isaddtag && (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="gap-2 flex mt-1"
                  >
                    <Select
                      value={isselectedChatsupport}
                      onChange={(e) => seselectedChatsupport(e.target.value)}
                      size="small"
                      sx={{ width: 100 }}
                      placeholder="select tags"
                      displayEmpty
                      renderValue={(selected) =>
                        selected === "" ? "Select tags" : selected
                      }
                    >
                      {allChatsupport.length > 0 &&
                        allChatsupport.map((data, index) => (
                          <MenuItem key={index} value={data?.user_Name}>
                            {data?.name}
                          </MenuItem>
                        ))}
                    </Select>

                    <button
                      className="btn btn-outline btn-sm   w-[100px] text-green-600"
                      onClick={() => {
                        // addTag();
                        handletags();
                      }}
                      disabled={isloader}
                    >
                      + add{" "}
                    </button>
                  </div>
                )}

                {isaddtag && (
                  <div
                    className="btn btn-sm p-0 w-auto mt-2"
                    onClick={(e) => {
                      setcreateAdd(true);
                      setaddtag(false);
                      e.stopPropagation();
                      seselectedChatsupport("");
                    }}
                  >
                    create & add+
                  </div>
                )}

                {iscreateAdd && (
                  <div
                    className="btn btn-sm p-0  w-auto mt-2"
                    onClick={(e) => {
                      setcreateAdd(false);
                      setaddtag(true);
                      e.stopPropagation();
                      settagsinput("");
                    }}
                  >
                    cancel
                  </div>
                )}

                {/* <p>{selectedConversation?.tags}</p> */}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ClientProfile;
