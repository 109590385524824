import React, { useState } from "react";
import useContact from "../../hooks/useContact";
import Papa from "papaparse";
import { Toaster } from "react-hot-toast";

function ImportCon() {
  const schema = [
    "Name",
    "Mobile_Number",
    "Tags",
    " Last_Active",
    "Joined_On",
    "Source",
  ];

  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [mappings, setMappings] = useState({});

  const { loading, uploadContacts } = useContact();

  const useHandleFileChange = (event) => {
    const file = event.target.files[0];
    // console.log("upload", file);
    if (file) {
      document.getElementById("my_modal_2").showModal();
      Papa.parse(file, {
        complete: (results) => {
          // console.log(results);
          setData(results.data);
          setHeaders(results.meta.fields);
        },
        header: true, // Ensure CSV data is parsed into objects
      });
    }

    event.target.value = "";
  };

  const hadleSubmit = async () => {
    const newdata = data.map((val) => {
      const newMappData = {};
      for (const [schemaHeader, Key] of Object.entries(mappings)) {
        newMappData[schemaHeader] = val[Key];
      }
      return newMappData;
    });

    console.log("new Data", newdata);
    await uploadContacts(newdata);
  };
  return (
    <>
      <div>
        <input
          type="file"
          id="import"
          accept=".csv"
          onChange={useHandleFileChange}
          onClick={useHandleFileChange}
          disabled={loading}
        />
        <label
          htmlFor="import"
          className="btn btn-sm bg-transparent text-[#0A474C] border-[#0A474C] hover:bg-[#EDF0F0] rounded-md "
        >
          {loading ? <span className="loading loading-dots"></span> : "Import"}
        </label>
      </div>
      {/* dialog box to for metching contacts fields */}

      <dialog id="my_modal_2" className="modal">
        <div className="modal-box min-w-[42vw] p-10 bg-white text-black">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm border-none outline-none btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <div className="text-xl">Please Match Contacts Flieds</div>
          <div className="mt-10  p-5">
            {schema.map((schemaItem) => {
              return (
                <div
                  key={schemaItem}
                  className="flex justify-between items-center mb-5"
                >
                  <label htmlFor="name" className="text-lg font-medium">
                    {schemaItem}
                  </label>
                  <select
                    id="name"
                    type="text"
                    className="bg-white input input-bordered input-sm w-full max-w-xs"
                    onChange={(e) => {
                      setMappings({
                        ...mappings,
                        [schemaItem]: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Your Header</option>
                    {headers.map((headerVal) => (
                      <option key={headerVal} value={headerVal}>
                        {headerVal}
                      </option>
                    ))}
                  </select>
                </div>
              );
            })}
          </div>
          <form method="dialog">
            <button
              className="btn btn-sm w-full hover:bg-gray-400 text-black bg-gray-300 border-none text-end"
              onClick={hadleSubmit}
            >
              Add Contacts
            </button>
          </form>
        </div>

        <Toaster
          toastOptions={{
            style: {
              zIndex: 9999, // Ensure toast stays above the dialog
            },
          }}
        />
      </dialog>
    </>
  );
}

export default ImportCon;
