import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import QuickGuilde from "../../components/QuickGuilde";
import GlobalHeader from "../../components/GlobalComponets/GlobalHeader";
import SearchBar from "../../components/GlobalComponets/SearchBar";
import TabBar from "../../components/Topbar";

import { MdOutlineExplore } from "react-icons/md";
import { IoMdMailOpen } from "react-icons/io";
import { MdOutlineTimelapse } from "react-icons/md"; //penidng logo
import { MdCheckCircleOutline } from "react-icons/md"; //approvalLogo
import { PiWarningOctagonLight } from "react-icons/pi"; //warningLogo
import { FaCircleNotch } from "react-icons/fa6";
import { useRefreshTemplates } from "../../hooks/useManagetemplate";

function Template() {
  const Templateheader = {
    Title: "Template Message ",
    EndButton: "+ New",
    Link: "create",
  };

  const handleTabButton = {
    width: "full",
    // span1: "explore",
    span1: "Marketing",
    span2: "Utility",
    span3: "Authentication",
    span4: "Pending",
    // span5: "Approval",
    span5: "Action Required",

    Links: {
      link1: "/template/message/Marketing",
      link2: "/template/message/Utility",
      link3: "/template/message/Authentication",
      link4: "/template/message/pending",
      // link5: "/template/message/approval",
      link5: "/template/message/action",
    },

    Logos: {
      logo1: <MdOutlineExplore className="text-xl" />,
      logo2: <FaCircleNotch className="text-xl" />,
      logo3: <IoMdMailOpen className="text-xl" />,
      logo4: <MdOutlineTimelapse className="text-xl" />,
      logo5: <MdCheckCircleOutline className="text-xl" />,
      // logo6: <PiWarningOctagonLight className="text-xl" />,
    },
  };

  const quickReplyObj = {
    width: "full",
    text1:
      "You can Initiate a Conversation with users on WhatsApp using these template messages.",
    // text2:
    //   "Launch a campaign now to initiate new conversations with users on WhatsApp.",
    link1: "How to create Whatsapp Template Message?",
    link2: "Use Chatbot parameter for leads ",
    MoreLink: {
      link3: "Add Quick Reply to Whatsapp Template Message ",
      link4: "Message formatting guildline (bold ,italic & more)",
    },
  };

  const { isloader, refreshData } = useRefreshTemplates();

  // Define the refresh button handler outside useEffect
  const handleRefreshButton = async () => {
    await refreshData();
    console.log("Refresh function has been called");
  };

  return (
    <>
      <GlobalHeader item={Templateheader} />
      {/* <QuickGuilde data={quickReplyObj} /> */}
      <div className="">
        <SearchBar
          width="full"
          handlebutton={handleRefreshButton}
          isloader={isloader}
        />
      </div>
      {/* <SelectBar /> */}
      {/* <HeaderTemplate items = {SelectBarTemplate}/> */}
      <TabBar data={handleTabButton} />

      {/* // all below components are done and dusted */}
      <Outlet />
    </>
  );
}

export default Template;
