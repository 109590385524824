import { Box, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";

function DateRange({ initialData }) {
  const formatDates = () => {
    const today = new Date();
    const sevenDaysAgo = new Date();

    // Adjust the sevenDaysAgo date by subtracting 7 days
    sevenDaysAgo.setDate(today.getDate() - 7);

    // Format both dates to "YYYY-MM-DD"
    const formattedToday = today.toISOString().split("T")[0];
    const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];

    return { formattedToday, formattedSevenDaysAgo };
  };

  const { formattedToday, formattedSevenDaysAgo } = formatDates();

  console.log(
    "formated date looks likes this ",
    formattedToday,
    formattedSevenDaysAgo
  ); // Output: "2024-12-11"

  const [range, setRange] = React.useState("7 days");
  const [fromDate, setFromDate] = useState(formattedSevenDaysAgo); // Default from date
  const [toDate, setToDate] = useState(formattedToday); //Default to date

  const [data, setData] = useState(initialData); // Chart data

  const filterData = (newFromDate, newToDate) => {
    // Assuming real dates are available in your data
    const filteredData = initialData?.filter((entry) => {
      const entryDate = new Date(
        `2024-${entry.date.split(" ")[1]}-${entry.date.split(" ")[0]}`
      );
      return (
        entryDate >= new Date(newFromDate) && entryDate <= new Date(newToDate)
      );
    });
    setData(filteredData);
  };

  // Handle manual date changes
  const handleFromDateChange = (e) => {
    const newValue = e.target.value;
    setFromDate(newValue);
    filterData(newValue, toDate);
    setRange("Custom Range");

    // console.log("From Date:", newValue);
  };

  const handleRangeChange = async (selectedRange) => {
    const today = new Date();
    let newFromDate = today.toISOString().split("T")[0]; // Today's date in YYYY-MM-DD

    switch (selectedRange) {
      case "Today":
        newFromDate = today.toISOString().split("T")[0];
        break;
      case "Yesterday":
        newFromDate = new Date(today.setDate(today.getDate() - 1))
          .toISOString()
          .split("T")[0];
        break;
      case "7 days":
      case "7 Days":
        newFromDate = new Date(today.setDate(today.getDate() - 7))
          .toISOString()
          .split("T")[0];
        break;
      case "30 days":
      case "30 Days":
        newFromDate = new Date(today.setDate(today.getDate() - 30))
          .toISOString()
          .split("T")[0];
        break;
      default:
        newFromDate = today.toISOString().split("T")[0];
    }

    setFromDate(newFromDate);
    setToDate(new Date().toISOString().split("T")[0]);
    filterData(newFromDate, new Date().toISOString().split("T")[0]);

    console.log("from data looks likes this :", fromDate);
    console.log("to data looks likes this :.", toDate);

    // await handlefetchData(fromDate,toDate);
    // console.log("updatdated data:",updateData)
    // await  updatetime(updateData);
  };

  const handleToDateChange = async (e) => {
    const newValue = e.target.value;
    setToDate(newValue);
    filterData(fromDate, newValue);
    setRange("Custom Range");
    const updateData = {
      startDate: fromDate,
      endDate: toDate,
    };

    console.log("update data in range looks likes this :", updateData);
    //  await updatetime(updateData);
    // await handlefetchData(updateData);
    // await handlefetchData(fromDate,toDate);
    console.log("To Date:", newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (fromDate && toDate) {
        await handlefetchData(fromDate, toDate);
      }

      //   if (isselectedChatsupport && fromDate && toDate) {
      //     await handleupdateData(fromDate, toDate, isselectedChatsupport);
      //   }
    };
    fetchData();
  }, [fromDate, toDate]);
  const handlefetchData = async (fromDate, toDate) => {
    const updateData = {
      startDate: fromDate,
      endDate: toDate,
    };
    // await updatetime(updateData);
  };

  //   function combineDataByDate(data) {
  //     // Map to store grouped data
  //     const groupedData = new Map();

  //     // Loop through each object in the array
  //     data.forEach((item) => {
  //       const { date, intervened, resolved } = item;

  //       // If the date is already in the map, update the values
  //       if (groupedData.has(date)) {
  //         const existing = groupedData.get(date);
  //         groupedData.set(date, {
  //           date: date,
  //           intervened: existing.intervened + intervened,
  //           resolved: existing.resolved + resolved,
  //         });
  //       } else {
  //         // If the date is not in the map, add it
  //         groupedData.set(date, {
  //           date: date,
  //           intervened: intervened,
  //           resolved: resolved,
  //         });
  //       }
  //     });

  //     // Convert the map values back to an array
  //     return Array.from(groupedData.values());
  //   }

  //   const [isagentgraph, setagentgraph] = useState();
  //   useEffect(() => {
  //     if (
  //       isanalyticsagent?.activityByDate &&
  //       isanalyticsagent.activityByDate.length > 0
  //     ) {
  //       let allobject = [];
  //       if (
  //         Array.isArray(isanalyticsagent.activityByDate) &&
  //         isanalyticsagent.activityByDate.length > 1
  //       ) {
  //         isanalyticsagent.activityByDate.forEach((outerData) => {
  //           // Check if 'activityByDate' exists and is an array
  //           if (Array.isArray(outerData.activityByDate)) {
  //             // Loop through the inner array and push into allobject
  //             outerData.activityByDate.forEach((innerData) => {
  //               allobject.push(innerData);
  //             });
  //           }
  //         });
  //         const data = combineDataByDate(allobject);
  //         console.log("all object data looks likes this :", data);
  //         setagentgraph(data);
  //       } else {
  //         setagentgraph(isanalyticsagent.activityByDate[0]?.activityByDate);
  //         console.log(
  //           "selected inside the state look likes this :",
  //           isanalyticsagent.activityByDate[0]?.activityByDate
  //         );
  //       }
  //     } else {
  //       setagentgraph(null); // Default or fallback value
  //     }
  //   }, [isanalyticsagent]);

  return (
    <>
      <Box sx={{ padding: 4 }}>
        {/* Filters */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 3,
          }}
        >
          {/* Dropdown for Quick Ranges */}
          <Select
            value={range}
            onChange={(e) => {
              setRange(e.target.value);
              handleRangeChange(e.target.value);
            }}
            size="small"
            sx={{ width: 150 }}
          >
            <MenuItem value="Today">Today</MenuItem>
            <MenuItem value="Yesterday">Yesterday</MenuItem>
            <MenuItem value="7 days">7 Days</MenuItem>
            <MenuItem value="30 days">30 Days</MenuItem>
            {range === "Custom Range" && (
              <MenuItem value="Custom Range">Custom Range</MenuItem>
            )}
          </Select>
          <TextField
            label="From"
            type="date"
            size="small"
            value={fromDate}
            onClick={(e) => e.target.showPicker()} // Show the date picker on input click
            onChange={handleFromDateChange}
            inputProps={{
              max: toDate, // Prevent selecting future dates
            }}
            sx={{ width: 150 }}
          />

          <TextField
            label="To"
            type="date"
            size="small"
            value={toDate}
            onClick={(e) => e.target.showPicker()} // Show the date picker on input click
            onChange={handleToDateChange}
            inputProps={{
              max: new Date().toISOString().split("T")[0], // Prevent selecting future dates
              min: fromDate, // Prevent selecting a date before "From"
            }}
            sx={{ width: 150 }}
          />
        </Box>

        {/* Analytics Chart */}
        {/* <AnalyticsChart
          data={isanalytics}
          total={istotalmessage}
          title={chatTitle}
        /> */}
      </Box>
    </>
  );
}

export default DateRange;

// const App = () => {
//   const { isanalytics, isloading, updatetime } = UseAnalytics();
//   const { isagentanalyticsloader, getagentanalytics, isanalyticsagent } =
//     UseAnalyticsAgents();

//   const formatDates = () => {
//     const today = new Date();
//     const sevenDaysAgo = new Date();

//     // Adjust the sevenDaysAgo date by subtracting 7 days
//     sevenDaysAgo.setDate(today.getDate() - 7);

//     // Format both dates to "YYYY-MM-DD"
//     const formattedToday = today.toISOString().split("T")[0];
//     const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];

//     return { formattedToday, formattedSevenDaysAgo };
//   };

//   const { formattedToday, formattedSevenDaysAgo } = formatDates();
//   // const initialvalue = handleFormatDate(today);

//   console.log(
//     "formated date looks likes this ",
//     formattedToday,
//     formattedSevenDaysAgo
//   ); // Output: "2024-12-11"

//   console.log("data looks likes this :", data);
//   const [range, setRange] = useState("7 days"); // Default range
//   const [fromDate, setFromDate] = useState(formattedSevenDaysAgo); // Default from date
//   const [toDate, setToDate] = useState(formattedToday); // Default to date
//   // console.log("from data :", fromDate);
//   // console.log("to data :", toDate);

//   // console.log("all agents looks likes this :", isanalytics);
//   console.log("all agents looks likes this :", isagentgraph);

//   const totalchatsupports = isagentgraph?.reduce(
//     (acc, curr) => {
//       acc.intervened += curr.intervened;
//       acc.resolved += curr.resolved;
//       // acc.chatbot += curr.chatbot;
//       return acc;
//     },
//     { intervened: 0, resolved: 0 }
//   );

//   console.log("total counts looks likes this :", totalchatsupports);

//   return (
//     <>
//       <Box sx={{ padding: 4 }}>
//         {/* Filters */}
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             marginBottom: 3,
//           }}
//         >
//           {/* Dropdown for Quick Ranges */}
//           <Select
//             value={range}
//             onChange={(e) => {
//               setRange(e.target.value);
//               handleRangeChange(e.target.value);
//             }}
//             size="small"
//             sx={{ width: 150 }}
//           >
//             <MenuItem value="Today">Today</MenuItem>
//             <MenuItem value="Yesterday">Yesterday</MenuItem>
//             <MenuItem value="7 days">7 Days</MenuItem>
//             <MenuItem value="30 days">30 Days</MenuItem>
//             {range === "Custom Renge" && (
//               <MenuItem value="Custom Renge">Custom Range</MenuItem>
//             )}
//           </Select>

//           <TextField
//             label="From"
//             type="date"
//             size="small"
//             value={fromDate}
//             onClick={(e) => e.target.showPicker()} // Show the date picker on input click
//             onChange={handleFromDateChange}
//             inputProps={{
//               max: toDate, // Prevent selecting future dates
//             }}
//             sx={{ width: 150 }}
//           />

//           <TextField
//             label="To"
//             type="date"
//             size="small"
//             value={toDate}
//             onClick={(e) => e.target.showPicker()} // Show the date picker on input click
//             onChange={handleToDateChange}
//             inputProps={{
//               max: new Date().toISOString().split("T")[0], // Prevent selecting future dates
//               min: fromDate, // Prevent selecting a date before "From"
//             }}
//             sx={{ width: 150 }}
//           />
//         </Box>

//         {/* Analytics Chart */}
//         <AnalyticsChart
//           data={isanalytics}
//           total={istotalmessage}
//           title={chatTitle}
//         />
//       </Box>
//     </>
//   );
// };
