import React from "react";
import { IconButton, Tooltip, Badge } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Link } from "react-router-dom";
// import "daisyui/dist/full.css"; // Daisy UI for Tailwind component classes

const FlowTable = ({ data }) => {
  return (
    <div className="bg-gray-100 w-11/12 mx-auto mt-10">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="table w-full text-left">
          <thead>
            <tr>
              <th className="p-4 bg-gray-50 text-gray-700 font-medium">
                Flow Name
              </th>
              <th className="p-4 bg-gray-50 text-gray-700 font-medium">
                Campaign
              </th>
              <th className="p-4 bg-gray-50 text-gray-700 font-medium">
                Status
              </th>
              <th className="p-4 bg-gray-50 text-gray-700 font-medium">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="border-t">
                <td className="p-4">{item.flowName}</td>
                <td className="p-4">{item.campaign}</td>
                <td className="p-4">
                  {item.enabled ? (
                    <Badge
                      badgeContent={"ENABLED"}
                      color="success"
                      className="px-4 py-1"
                    ></Badge>
                  ) : (
                    <Badge
                      badgeContent={"DISABLED"}
                      color="default"
                      className="px-4 py-1"
                    >
                      {/* <span className="text-gray-500">DISABLED</span> */}
                    </Badge>
                  )}
                </td>
                <td className="p-4 flex space-x-2">
                  <Link to={item.link}>
                    <Tooltip title="Edit">
                      <IconButton>
                        <EditIcon className="text-gray-600" />
                      </IconButton>
                    </Tooltip>
                  </Link>
                  <Tooltip title="View Stats">
                    <IconButton>
                      <BarChartIcon
                        className={`${
                          item.enabled ? "text-gray-600" : "text-gray-400"
                        }`}
                      />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FlowTable;
