

import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { IoDocumentText, IoImageSharp, IoPlayCircle } from "react-icons/io5";

const InterActive = ({ interactive }) => {
  console.log("test:", interactive?.headerContent?.link);

  // Determine the content based on the header type
  let content = null;
  const isHeader = interactive?.headerType?.toUpperCase();

  switch (isHeader) {
    case "IMAGE":
      content = interactive?.headerContent?.link ? (
        <img
          src={interactive.headerContent.link}
          className="w-full object-cover h-auto"
          alt="template image"
        />
      ) : (
        <IoImageSharp className="h-40 w-80 bg-orange-300 rounded-md" />
      );
      break;
    case "VIDEO":
      content = interactive?.headerContent?.link ? (
        <video width={300} controls muted>
          <source src={interactive?.headerContent?.link} />
        </video>
      ) : (
        <IoPlayCircle className="h-40 w-72 text-blue-600" />
      );
      break;
    case "DOCUMENT":
      content = interactive?.headerContent?.link ? (
        <iframe
          src={interactive?.headerContent?.link}
          title="Document Preview"
          className="w-full h-40"
          frameBorder="0"
        ></iframe>
      ) : (
        <IoDocumentText className="h-40 w-72 text-red-400" />
      );
      break;
    case "LOCATION":
      content = (
        <FaLocationDot className="h-40 w-72 text-red-600 rounded-md" />
      );
      break;
    case "TEXT":
      content = (
        <div className="w-full bg-white">
          <p className="font-bold ml-2 text-gray-800">
            {interactive?.headerContent?.text || "Text Content"}
          </p>
        </div>
      );
      break;
    default:
      content = null;
  }

  const interactiveButtons = interactive?.buttons || [];

  return (
    <div className="bg-transparent w-[65%]">
      <div className="p-2 rounded-md bg-white shadow-md w-auto">
        {/* Media Content Section */}
        <div className="rounded-md overflow-hidden">
          <figure className="mb-2">{content}</figure>
          {interactive?.bodyText && (
            <div className="p-2 text-sm bg-white font-normal overflow-hidden whitespace-pre-wrap">
              <p className="font-whatsapp text-gray-800">{interactive?.bodyText}</p>
            </div>
          )}
        </div>
      </div>

      {/* Buttons Section */}
      {interactiveButtons.length > 0 && (
        <div className="space-y-2">
          {interactiveButtons.map((button, index) => (
            <div
              key={index}
              className="h-9 w-full mt-1 bg-white rounded shadow text-[#4C9EDC] flex justify-center items-center gap-2"
            >
              {button.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default InterActive;
