import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const useChangePass = () => {
  const navigate = useNavigate();

  const [loading1, setLoading] = useState(false);

  const changePassword = async ({ username, otp, password, confirmPass }) => {
    try {
      setLoading(true);

      const success = hadleInputError({ username, otp, password, confirmPass });

      if (!success) {
        return;
      }

      const res = await axios.post("/api/auth/changePassword", {
        username,
        otp,
        newPassword: password,
      });

      const data = res.data;
      console.log(data);

      navigate("/login");

      toast.success(data.message);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading1, changePassword };
};

export default useChangePass;

const hadleInputError = ({ username, otp, password, confirmPass }) => {
  if (!username || !otp || !password || !confirmPass) {
    toast.error("Please fill the all fields!");
    return false;
  }

  if (password !== confirmPass) {
    toast.error("Password do not match!");
    return false;
  }

  if (password.length < 8) {
    toast.error("Password must be at least 8 characters");
    return;
  }

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (!passwordRegex.test(password)) {
    toast.error(
      "Password must include uppercase, lowercase, number, and special character."
    );
    return false;
  }

  if (isNaN(otp)) {
    toast.error("OTP should be in Number!");
    return false;
  }

  return true;
};
