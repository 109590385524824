// import { useEffect, useState } from "react";
// import useZusConversation from "../zustand/useZusConversation";
// import toast from "react-hot-toast";
// import axios from "axios";

// const useGetMessage = () => {
//   const [loading, setLoading] = useState(false);
//   const { messages, setMessages, selectedConversation } = useZusConversation();

//   useEffect(() => {
//     const getMessage = async () => {
//       try {
//         setLoading(true);
//         const res = axios.get(
//           `/api/client/messages/${selectedConversation._id}`
//         );

//         const data = (await res).data;

//         setMessages(data);
//       } catch (error) {
//         toast.error(error.response?.data?.error || error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (selectedConversation?._id) getMessage();
//   }, [setMessages, selectedConversation?._id]);

//   return { loading, messages };
// };

// export default useGetMessage;
import { useEffect, useState } from "react";
import useZusConversation from "../zustand/useZusConversation";
import toast from "react-hot-toast";
import axios from "axios";
import useAllTemplateZus from "../zustand/useAllTemplate";

const useGetMessage = () => {
  const [loading, setLoading] = useState(false);
  const [olderLoading, setOlderLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if more messages are available
  const [skip, setSkip] = useState(0); // Track how many messages have been loaded
  const limit = 10;

  const { messages, setMessages, selectedConversation } = useZusConversation();

  const { allTemplate } = useAllTemplateZus();

  console.log("allTemplate", allTemplate);

  function mergeArraysByTemplateId(primaryArray, secondaryArray) {
    return primaryArray.map((item) => {
      const matchedObject = secondaryArray.find(
        (secondaryItem) => secondaryItem._id === item.templateId
      );
      if (matchedObject) {
        // Replace the entire item with the matched object
        return {
          ...item,
          templateId: matchedObject, // Replace or merge as needed
        };
      }
      return item; // Return the original item if no match
    });
  }
  // Initial message load
  useEffect(() => {
    const fetchInitialMessages = async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          `/api/client/messages/${selectedConversation._id}`,
          { params: { skip: 0, limit } }
        );

        const data = res.data;

        let optimizedArrObjOfMsg = mergeArraysByTemplateId(data, allTemplate);

        console.log("filter:", optimizedArrObjOfMsg);

        setMessages(optimizedArrObjOfMsg); // Set initial messages
        setSkip(data.length); // Update skip count
        setHasMore(data.length === limit); // If less than limit, no more messages
      } catch (error) {
        toast.error(error.response?.data?.error || error.message);
      } finally {
        setLoading(false);
      }
    };

    if (selectedConversation?._id) fetchInitialMessages();
  }, [selectedConversation?._id]);

  // Load older messages
  const fetchOlderMessages = async () => {
    if (!hasMore || olderLoading) return; // Prevent unnecessary calls

    try {
      setOlderLoading(true);
      const res = await axios.get(
        `/api/client/messages/${selectedConversation._id}`,
        { params: { skip, limit } }
      );

      const data = res.data;

      let optimizedArrObjOfMsg = mergeArraysByTemplateId(data, allTemplate);
      console.log("fetchOlderMessages", data);

      // Add older messages at the beginning of the array
      setMessages([...optimizedArrObjOfMsg, ...messages]);
      setSkip(skip + data.length); // Update skip count
      setHasMore(data.length === limit); // If less than limit, no more messages
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setOlderLoading(false);
    }
  };

  console.log("res prince", messages);

  return { loading, messages, olderLoading, fetchOlderMessages, hasMore };
};

export const useReadMessage = () => {
  const readMessages = async (id) => {
    try {
      const res = await axios.get(`/api/client/messages/read/${id}`);

      const data = res.data;
      console.log(data);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
    }
  };

  return { readMessages };
};

export default useGetMessage;
