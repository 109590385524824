import toast from "react-hot-toast";
import useZusConversation from "../zustand/useZusConversation";
import axios from "axios";
import { useState } from "react";

const useSendMessage = () => {
  const [loading, setloading] = useState(false);
  const { messages, setMessages, selectedConversation } = useZusConversation();

  const sendMessage = async (message) => {
    const success = handleInputCheck(message);

    if (!success) return;

    setloading(true);
    try {
      const res = axios.post(
        `api/client/messages/send/${selectedConversation._id}`,
        { message: message }
      );

      const data = (await res).data;

      setMessages([...messages, data]);

      // toast.success(data.message);
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setloading(false);
    }
  };

  return { loading, sendMessage };
};

export default useSendMessage;

const handleInputCheck = (message) => {
  if (!message) {
    toast.error("fill the input field!");
    return false;
  }
  return true;
};
