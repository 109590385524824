import { create } from "zustand";

const useZusConversation = create((set) => ({
  selectedConversation: null,
  setSelectedConversation: (selectedConversation) =>
    set({ selectedConversation }),

  messages: [],
  setMessages: (messages) => set({ messages }),

  loadingCNV: false,
  setLoadingCNV: (loadingCNV) => set({ loadingCNV }),

  conversations: [],
  setConversations: (conversations) =>
    set({ conversations: conversations || [] }), // Fallback to empty array
}));

export default useZusConversation;
