import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useMetaUserDetails from "../zustand/useMetaUserDetails";

const useProfile = () => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState([]);
  const { setMetaUserDetails } = useMetaUserDetails();

  const upProfile = async (data) => {
    try {
      setLoading(true);

      const Inputvalid = checkInputs(data);
      if (!Inputvalid) {
        return;
      }

      const res = await axios.post("/whatsapp/details/whatsappProfile", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(res.data);
      setMetaUserDetails(res.data);
      toast.success("WhatsApp profile updated!");
    } catch (error) {
      const errorMessage =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message;
      toast.error(errorMessage);

      console.error("error in profile update", errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const checkInputs = (data) => {
    const about = data.get("about");
    const description = data.get("description");
    const email = data.get("email");
    const address = data.get("address");
    const website1 = data.get("website1");
    const vertical = data.get("vertical");

    // Validation for 'about' field (only if value exists)
    if (about && about.length > 139) {
      toast.error("About us field should be under 139 characters.");
      return false;
    }

    // Validation for 'description' field (only if value exists)
    if (description && description.length > 512) {
      toast.error("Description field should be under 512 characters.");
      return false;
    }

    // Validation for 'email' field (only if value exists)
    if (email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      toast.error("Please enter a valid email address.");
      return false;
    }

    // Validation for 'address' field (only if value exists)
    if (address && address.length > 256) {
      toast.error("Address field should be under 256 characters.");
      return false;
    }

    // Validation for 'website1' field (optional but should be a valid URL if provided)
    if (website1 && !/^https?:\/\/[\w-]+(\.[\w-]+)+.*$/.test(website1)) {
      toast.error("Please enter a valid URL for Website 1.");
      return false;
    }

    // Validation for 'vertical' field (only if value exists)
    if (!vertical) {
      toast.error("Vertical field is required!");
      return false;
    }

    // All validations passed
    return true;
  };

  return { loading, profile, upProfile };
};

const useGetProfile = () => {
  const [loading, setloading] = useState(false);
  const { metaUserDetails, setMetaUserDetails } = useMetaUserDetails();
  const [refreshData, setRefreshData] = useState(false);

  const getMetaUserDetails = async () => {
    try {
      setloading(true);

      const res = await axios.get("/whatsapp/details/whatsappProfile/get");

      console.log("data", res.data);
      setMetaUserDetails(res.data);
    } catch (error) {
      // toast.error(error.response?.data?.error || error.message);
      console.log(error.response?.data?.error || error.message);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    getMetaUserDetails();
  }, [refreshData]);

  return { refreshData, setRefreshData };
};

export { useProfile, useGetProfile };
