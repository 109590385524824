import * as React from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { FaGoogle } from "react-icons/fa";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useLogin from "../../hooks/useLogin";
import { Link } from "react-router-dom";
import { useAgentLogin } from "../../hooks/useAgenst";

const Login = () => {
  const [isAgentLogin, setisAgentLogin] = React.useState(false);
  const [inputs, setInputs] = React.useState({
    username: "",
    password: "",
  });

  //useLog context For send data to api
  const { loading, login } = useLogin();

  // for agent login
  const { agentloading, agentlogin } = useAgentLogin();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const hadleSubmit = async (e) => {
    e.preventDefault();
    // console.log(inputs);

    if (isAgentLogin) {
      console.log("agent login");
      agentlogin(inputs);
      return;
    }
    // sending values to login hook
    await login(inputs);
  };


  const DOMAIN_WHATSAPP_API_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:5000"
    : "https://app.thebotmode.com";

  const handleGoogleAuth = async () => {
    window.location.href = `${DOMAIN_WHATSAPP_API_URL}/api/auth/google`;
  };

  const hdlAgent = () => {
    setisAgentLogin(!isAgentLogin);
  };

  return (
    <div className="flex box-border h-[100vh] relative">
      <div className="w-[42%] bg-[#EBF5F3]">
        <div className="h-full flex justify-center items-center">
          <img
            src="/assets/login&signupLogo.png"
            alt=""
            width={300}
            height={200}
          />
        </div>
        {/* <div className="pt-14 ps-10 text-4xl font-semibold tracking-wider">
          <span className="text-green-500">T</span>
          <span className="text-gray-700">HE</span>
          <span className="text-green-500">B</span>
          <span className="text-gray-700">OT</span>
          <span className="text-green-500">M</span>
          <span className="text-gray-700">ODE</span>
        </div>
        <div className="mt-11 px-10">
          <h1 className="text-[#0A474C] text-[28px] font-semibold">
            Send personalized campaigns on WhatsApp
          </h1>
        </div>
        <div>
          <img src="/assets/kukkastore.png" alt="" />
        </div> */}
      </div>

      <div className="w-[58%] bg-white shadow-sm rounded py-10 overflow-y-auto text-center relative">
        <div className="w-1/2 mx-auto">
          <div className="text-gray-500 absolute top-14 right-16">
            Not a member yet?{" "}
            <Link to="/signup">
              <span className="font-bold cursor-pointer text-[#0A474C]">
                Sign Up
              </span>
            </Link>
          </div>

          <div className="w-full mx-auto mt-24 mb-12">
            <p className="text-gray-500 text-sm text-start">WELCOME BACK</p>
            <h1 className="text-2xl text-start font-bold leading-7 tracking-wide">
              Log in to THEBOTMODE
            </h1>
          </div>

          <button
            className="btn w-full text-lg bg-[#2A75F3] border-none text-white hover:bg-blue-700"
            onClick={handleGoogleAuth}
          >
            <span>
              <FaGoogle />
            </span>
            Continue with Google
          </button>
          <div className="divider  divider-neutral opacity-[0.3]">
            <span className="font-medium text-sm text-black">OR</span>
          </div>
          <form action="">
            <div className="mb-5">
              <TextField
                value={inputs.username}
                onChange={(e) => {
                  setInputs({ ...inputs, username: e.target.value });
                }}
                sx={{ colors: "#808080", width: "100%" }}
                id="outlined-basic"
                label="Username / Email"
                variant="outlined"
              />
            </div>

            <div className="mb-5">
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  value={inputs.password}
                  onChange={(e) => {
                    setInputs({ ...inputs, password: e.target.value });
                  }}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </div>
            <div className="mb-4 relative">
              <label className="flex justify-center items-center gap-1">
                <input
                  className="checkbox checkbox-xs checkbox-success"
                  type="checkbox"
                  id="terms"
                  name="terms"
                  required
                  value={isAgentLogin}
                  onChange={hdlAgent}
                />
                <div className="text-gray-500">I am an agent.</div>
              </label>
            </div>

            <button
              className="btn w-full bg-[#0A474C] text-white tracking-wider hover:bg-[#073135] mb-5"
              onClick={(e) => {
                hadleSubmit(e);
              }}
              disabled={loading || agentloading}
            >
              {loading ? (
                <span className="loading loading-dots"></span>
              ) : (
                "Continue"
              )}
            </button>
          </form>
          <div className="text-xs text-[#0A474C] my-4 hover:cursor-pointer hover:text-gray-400">
            <Link to="/forgetPass">Forgot Password?</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
