import { UseAuthContext } from "../context/AuthContext";

import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

const useSignUp = () => {
  const [loading, setLoading] = useState(false);

  //Use Context file for import fucntion from AuthContext
  const { setAuthUser } = UseAuthContext();

  const signup = async ({ fullName, email, number, password, confirmPass }) => {
    const success = handleInputError({
      fullName,
      email,
      number,
      password,
      confirmPass,
    });
    if (!success) return;

    setLoading(true);

    try {
      const res = await axios.post("/api/auth/signup", {
        fullName,
        email,
        personal_Whatsapp: number,
        password,
      });

      const data = res.data;

      // console.log(data);

      //set the local storage for AuthUser
      localStorage.setItem("authUser", JSON.stringify(data));
      setAuthUser(data);
      toast.success("SignIn Successfully!");
    } catch (error) {
      toast.error(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, signup };
};

export default useSignUp;

const handleInputError = ({
  fullName,
  email,
  number,
  password,
  confirmPass,
}) => {
  if (!fullName || !email || !number || !password || !confirmPass) {
    toast.error("Please fill in all fields");
    return;
  }

  // const indianPhoneNumberRegex = /^(\+?91[\-\s]?)?[6-9]\d{9}$/;
  const indianPhoneNumberRegex = /^(?:\+91|91)?[\-\s]?[6-9]\d{9}$/;

  // const indianPhoneNumberRegex = /^(\+91[\-\s]?)?[6-9]\d{9}$/;

  console.log(indianPhoneNumberRegex.test(number));
  if (!indianPhoneNumberRegex.test(number)) {
    console.log(
      "Invalid phone number format (Must be +CountryCode followed by 10 digits)"
    );
    console.log(indianPhoneNumberRegex.test(number));

    toast.error(
      "Invalid phone number format (Must be +CountryCode followed by 10 digits)"
    );
    return false;
  }

  if (password !== confirmPass) {
    toast.error("Password do not match");
    return;
  }

  if (password.length < 8) {
    toast.error("Password must be at least 8 characters");
    return;
  }

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  if (!passwordRegex.test(password)) {
    toast.error(
      "Password must include uppercase, lowercase, number, and special character."
    );
    return;
  }

  return true;
};
