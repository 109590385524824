// import React from "react";
// import useZusConversation from "../../../zustand/useZusConversation";
// import { useSocketContext } from "../../../context/SocketContext";
// import { useReadMessage } from "../../../hooks/useGetMessage";

// const Conversation = ({ conversation, lastRow }) => {
//   const {
//     conversations,
//     setConversations,
//     selectedConversation,
//     setSelectedConversation,
//   } = useZusConversation();

//   // console.log("conversations from:", conversations);
//   const { socket } = useSocketContext(); // Access the socket from context

//   //to read all messages of selected user
//   const { readMessages } = useReadMessage();

//   const isSelected = selectedConversation?._id === conversation._id;

//   const hadleSeletedConversation = (conversation) => {
//     // Prevent duplicate state updates
//     if (selectedConversation?._id === conversation._id) {
//       setSelectedConversation(null);
//       return;
//     }

//     console.log("conversation test:", conversation);

//     // Cleanup unseen messages for the selected conversation
//     const updatedConversations = conversations.map((item) =>
//       item._id === conversation._id ? { ...item, unseenMessages: 0 } : item
//     );

//     // Reset unseen messages in state
//     setConversations(updatedConversations);

//     // Read messages for the selected conversation
//     readMessages(conversation._id);

//     // Set the selected conversation (debounced/throttled if needed)
//     setSelectedConversation(conversation);

//     // Emit the joinConversation event to notify the backend
//     if (socket && conversation?._id) {
//       socket.emit("leaveConversation", selectedConversation?._id); // Leave previous conversation (if needed)
//       socket.emit("joinConversation", conversation._id);
//     }
//   };

//   return (
//     <>
//       <div
//         className={`flex gap-2 items-center ${
//           isSelected && "bg-[#e9e5e5]"
//         } hover:bg-[#e9e5e5] rounded p-2 py-1 cursor-pointer `}
//         onClick={() => {
//           hadleSeletedConversation(conversation);
//         }}
//       >
//         <div className="online">
//           <div className="w-12 h-12 bg-orange-100 flex items-center justify-center text-2xl font-normal text-[#0A474C] rounded-full">
//             {conversation.name.charAt(0)}
//           </div>
//         </div>
//         <div className="flex flex-col flex-1 relative">
//           <div className="flex-col gap-3 justify-between">
//             <p className=" text-black capitalize">{conversation.name}</p>
//             <p className=" text-gray-500 text-xs">
//               {conversation?.lastMessage}...
//             </p>
//           </div>
//           {conversation.unseenMessages !== 0 && (
//             <div className="absolute right-0 top-2 w-7 h-7 rounded-full flex justify-center items-center text-xs font-medium text-white bg-[#0A474C]">
//               {conversation.unseenMessages + "+"}
//             </div>
//           )}
//         </div>
//       </div>

//       {lastRow && <div className="divider my-0 py-0 h-1" />}
//     </>
//   );
// };

// export default Conversation;

import React from "react";
import useZusConversation from "../../../zustand/useZusConversation";
import { useSocketContext } from "../../../context/SocketContext";
import { useReadMessage } from "../../../hooks/useGetMessage";

// Memoizing the Conversation component to prevent unnecessary re-renders
const Conversation = React.memo(({ conversation, lastRow }) => {
  const {
    conversations,
    setConversations,
    selectedConversation,
    setSelectedConversation,
    setMessages,
  } = useZusConversation();

  const { socket } = useSocketContext(); // Access the socket from context
  const { readMessages } = useReadMessage(); // To read all messages of the selected user

  const isSelected = selectedConversation?._id === conversation._id;

  const handleSelectedConversation = (conversation) => {
    if (selectedConversation?._id === conversation._id) {
      setSelectedConversation(null);
      return;
    }
    setSelectedConversation(null);

    setMessages([]);

    // Update unseen messages
    console.log("conversation test:", conversation);

    // Cleanup unseen messages for the selected conversation
    const updatedConversations = conversations.map((item) =>
      item._id === conversation._id ? { ...item, unseenMessages: 0 } : item
    );

    setConversations(updatedConversations);
    readMessages(conversation._id);
    setSelectedConversation(conversation);

    if (socket && conversation?._id) {
      socket.emit("leaveConversation", selectedConversation?._id);
      socket.emit("joinConversation", conversation._id);
    }
  };

  return (
    <div
      className={`flex gap-2 items-center ${
        isSelected && "bg-[#e9e5e5]"
      } hover:bg-[#e9e5e5] rounded p-2 py-1 cursor-pointer`}
      onClick={() => handleSelectedConversation(conversation)}
    >
      <div className="online">
        <div className="w-12 h-12 bg-orange-100 flex items-center justify-center text-2xl font-normal text-[#0A474C] rounded-full">
          {conversation.name.charAt(0)}
        </div>
      </div>
      <div className="flex flex-col flex-1 relative">
        <div className="flex-col gap-3 justify-between">
          <p className=" text-black capitalize">{conversation.name}</p>
          <p className=" text-gray-500 text-xs">
            {conversation?.lastMessage?.length > 15
              ? `${conversation.lastMessage.slice(0, 15)}...`
              : conversation.lastMessage}
          </p>
        </div>
        {conversation.unseenMessages !== 0 && (
          <div className="absolute right-0 top-2 w-7 h-7 rounded-full flex justify-center items-center text-xs font-medium text-white bg-[#0A474C]">
            {conversation.unseenMessages + "+"}
          </div>
        )}
      </div>
      {lastRow && <div className="divider my-0 py-0 h-1" />}
    </div>
  );
});

export default Conversation;
