import React, { useEffect, useRef, useState } from "react";
import { TextComponent } from "../../components/template/CreateTemplate";
import { Autocomplete, TextField } from "@mui/material";
import { RiWhatsappFill } from "react-icons/ri";
// import "./index.css";
import "./contact.css";
import { IoDocumentText, IoImageSharp, IoPlayCircle } from "react-icons/io5";
import {
  FaArrowRightLong,
  FaArrowUpRightFromSquare,
  FaTruckArrowRight,
} from "react-icons/fa6";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaArrowLeft } from "react-icons/fa";
import { LuForward } from "react-icons/lu";
import { CgSmileMouthOpen } from "react-icons/cg";
import { BiLinkExternal } from "react-icons/bi";
import { MdOutlineCameraAlt } from "react-icons/md";
import { MdDownload } from "react-icons/md";
import { MdVisibility } from "react-icons/md";
import {
  MdCheckCircle,
  MdDeleteForever,
  MdOutlineCall,
  MdOutlineCloudUpload,
  MdPermMedia,
} from "react-icons/md";
import { BiVideo } from "react-icons/bi";
import { IoEllipsisVertical } from "react-icons/io5";
// import { CgSmileMouthOpen } from "react-icons/cg";
import { BsPaperclip } from "react-icons/bs";
// import { MdOutlineCall } from "react-icons/md";
import { CgMailReply } from "react-icons/cg";
import { IoMdMic } from "react-icons/io";

import { useFetchStatus, useBulkMessage } from "../../hooks/useManagetemplate";
import { HiLink } from "react-icons/hi2";
import { json } from "react-router-dom";
import {
  useFetchMedia,
  usePostmedia,
  useDeleteMedia,
} from "../../hooks/usemediadata";
import toast, { Toaster } from "react-hot-toast";
import { FaLocationDot } from "react-icons/fa6";
import zIndex from "@mui/material/styles/zIndex";
import CsvDisplay from "../../components/CsvDisplay";
import { UseAuthContext } from "../../context/AuthContext";
// import template from "../../../../BackendMain/models/template.model";
const TheadingData = [
  { label: "None", id: 1 },
  { label: "Text", id: 2 },
  { label: "Image", id: 3 },
  { label: "Video", id: 4 },
  { label: "Document", id: 5 },
  { label: "Location", id: 6 },
];

const formatText = (text) => {
  return text
    .replace(/\*(.*?)\*/g, "<b>$1</b>") // Bold
    .replace(/_(.*?)_/g, "<i>$1</i>") // Italic
    .replace(/~(.*?)~/g, "<s>$1</s>"); // Strikethrough
};

const Broadcast = ({ mobileNumbers, selectedList }) => {
  const { isstatus: AcceptedTemplate, load: loadingAcceptedTemplate } =
    useFetchStatus("APPROVED");

  // const { metaUserDetails } = useMetaUserDetails();

  const [isheader, setheader] = useState("");
  const [isMediaFetch, setMediaFetch] = useState(false);
  const [isbody, setbody] = useState("");
  const [isfooter, setfooter] = useState("");
  const [buttons, setbuttons] = useState([]);
  const [istitle, setTitle] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedmedia, setselectedmedia] = useState(null);
  const [istemplateid, settemplateid] = useState("");
  const [islatitude, setlatitude] = useState("");
  const [islongitude, setlongitue] = useState("");
  const [islocatioName, setlocationName] = useState("");
  const [isaddress, setaddress] = useState("");
  const [iscardData, setcardData] = useState();
  const [mediaUrls, setmediaUrls] = useState([]);

  console.log("media url in broadcasting looks likes this :", mediaUrls);

  console.log("location value looks likes :", islatitude);
  console.log("location value looks likes :", islongitude);
  console.log("location value looks likes :", islocatioName);
  console.log("location value looks likes :", isaddress);
  const [iscamp, setcamp] = useState("");

  console.log("camp  name is :", iscamp);
  console.log("selectedmedia:", selectedmedia);

  const handleTypeChange = (event, newValue) => {
    const selectedTemplateName = newValue ? newValue.name : null;
    setSelectedType(selectedTemplateName);
    console.log("Selected template looks like this:", selectedType);

    if (selectedTemplateName) {
      const templateData = AcceptedTemplate.find(
        (template) => template.configData.name === selectedTemplateName
      );

      if (templateData) {
        const components = templateData?.configData?.components;
        let templateFormat = "";
        let headerText = "";
        let bodyText = "";
        let footer = "";
        let totalbutton = [];
        let cardData;

        for (let j = 0; j < components.length; j++) {
          const component = components[j];

          console.log("Component looks like this:", component);

          if (component.type === "HEADER" && component.format) {
            templateFormat = component.format;

            if (component.format === "Text") {
              headerText = component.text;
              console.log(
                "Header looks like this in map function:",
                headerText
              );
            }
          } else if (component.type === "BODY" && component.text) {
            bodyText = component.text;
          } else if (component.type === "FOOTER" && component.text) {
            footer = component.text;
          } else if (component.type === "BUTTONS" && component.buttons) {
            totalbutton = totalbutton.concat(component.buttons);
          } else if (component.type === "CAROUSEL") {
            cardData = component.cards;
          }
        }

        // Update the state with the extracted values
        setMediaFetch(true);
        setheader(templateFormat);
        setbody(bodyText);
        setfooter(footer);
        setbuttons(totalbutton);
        setTitle(headerText); // Assuming Title is set from headerText
        settemplateid(templateData.id);
        setcardData(cardData);
      }
    } else {
      // Reset states if no template is selected
      setheader("");
      setbody("");
      setfooter("");
      setbuttons([]);
      setTitle("");
      settemplateid("");
      setcardData("");
    }
  };

  const handlHoverTemplate = (event, newValue) => {
    const selectedTemplateName = newValue ? newValue.name : null;
    setSelectedType(selectedTemplateName);
    console.log("Selected template looks like this:", selectedType);

    if (selectedTemplateName) {
      const templateData = AcceptedTemplate.find(
        (template) => template.configData.name === selectedTemplateName
      );

      if (templateData) {
        const components = templateData.configData.components;
        let templateFormat = "";
        let headerText = "";
        let bodyText = "";
        let footer = "";
        let totalbutton = [];
        let cardData;

        for (let j = 0; j < components.length; j++) {
          const component = components[j];

          console.log("Component looks like this:", component);

          if (component.type === "HEADER" && component.format) {
            templateFormat = component.format;

            if (component.format === "Text") {
              headerText = component.text;
              console.log(
                "Header looks like this in map function:",
                headerText
              );
            }
          } else if (component.type === "BODY" && component.text) {
            bodyText = component.text;
          } else if (component.type === "FOOTER" && component.text) {
            footer = component.text;
          } else if (component.type === "BUTTONS" && component.buttons) {
            totalbutton = totalbutton.concat(component.buttons);
          } else if (component.type === "CAROUSEL") {
            cardData = component.cards;
          }
        }

        // Update the state with the extracted values
        setMediaFetch(false);
        setheader(templateFormat);
        setbody(bodyText);
        setfooter(footer);
        setbuttons(totalbutton);
        setTitle(headerText); // Assuming Title is set from headerText
        settemplateid(templateData.id);
        setcardData(cardData);
      }
    } else {
      // Reset states if no template is selected
      setheader("");
      setbody("");
      setfooter("");
      setbuttons([]);
      setTitle("");
      settemplateid("");
      setcardData("");
      setmediaUrls([]);
    }
  };

  const handleMouseLeave = (event, option) => {
    setSelectedType(null);
    setselectedmedia(null);

    // setmediaUrls("")
  };
  console.log("selected list is :", selectedList);
  console.log("selected type is :", selectedType);
  console.log("selecetd template id is :", istemplateid);
  let content;
  if (isheader.toUpperCase() === "IMAGE") {
    content = <IoImageSharp className="h-40  w-52 bg-orange-300 rounded-md" />;
  } else if (isheader.toUpperCase() === "VIDEO") {
    content = (
      <div className="text-4xl text-blue-600 h-40 w-52 rounded-md">
        <IoPlayCircle className="h-40  w-52" />
      </div>
    );
  } else if (isheader.toUpperCase() === "DOCUMENT") {
    content = <IoDocumentText className="h-40 w-52 text-red-400 " />;
  } else if (isheader.toUpperCase() === "LOCATION") {
    content = (
      <div className="text-4xl text-red-600 h-40 w-52 rounded-md flex items-center justify-center">
        <FaLocationDot className=" text-2xl h-24 w-52 text-red-600" />
      </div>
    );
    // } else if (isheader.toUpperCase() === "TEXT") {
    //   content = (
    //     <div className=" w-full  bg-white">
    //       <p className="font-whatsapp-bold text-start font-bold ml-2">
    //         {istitle.toLowerCase()}
    //       </p>
    //     </div>
    //   );
  } else if (!isheader) {
    content = <div></div>;
  }
  console.log("isheader looks likes :", isheader);
  const [parentObjectsArray, setParentObjectsArray] = useState([]);

  const handleObjectsArrayChange = (newObjectsArray) => {
    setParentObjectsArray(newObjectsArray);
    console.log("Updated objectsArray in parent:", newObjectsArray);
  };

  console.log("parents parameterlooks like this :", parentObjectsArray);

  //function for getting the media from upload section
  const handlegetmediaurl = (selectedImage) => {
    setselectedmedia(selectedImage);
  };

  //this will remove images after template change
  // useEffect(() => {
  //   setselectedmedia(null);
  // }, [selectedType]);

  useEffect(() => {
    setselectedmedia(null);
    // setmediaUrls("")
  }, [
    selectedType,
    istemplateid,
    // iscardData
  ]);

  const { isSendTemplate, isloader, sendBulkMessage } = useBulkMessage();

  // let mediaurl123;

  // useEffect(() => {
  //   selectedmedia?.url.map((media) => {
  //     mediaurl123.push(media?.url);
  //   });
  // }, []);

  // for (let index = 0; index < selectedmedia.length; index++) {
  //   const media = selectedmedia[index];
  //   //  mediaurl123.push(media)
  //   console.log("data looks  likes this :",media)
  // }

  // Step 2: Use useEffect to extract URLs when selectedImages changes
  useEffect(() => {
    if (iscardData) {
      if (Array.isArray(selectedmedia)) {
        const urls = selectedmedia
          .map((image) => image.url)
          .filter((url) => url); // Filter out any undefined/null URLs
        setmediaUrls(urls); // Step 3: Update the state
      }
    } else {
      const urls = selectedmedia?.url;
      setmediaUrls(urls); // Step 3: Update the state
    }
    // Check if selectedImages is an array and map to extract URLs
  }, [selectedmedia]);
  // Dependency on selectedImages

  // For debugging: Check the extracted URLs
  console.log("Extracted URLs:", mediaUrls);
  console.log("url of carusol data looks likes :", iscardData);

  const handlebulkmessaging = async () => {
    try {
      let bodyparamter = [];
      if (Array.isArray(parentObjectsArray)) {
        bodyparamter = parentObjectsArray.map((parameter) =>
          parameter.parameterVal.trim()
        );
        console.log("strinded value looks like this :", bodyparamter);
        // return bodyparamter;
      }
      // Ensure these variables are defined in the scope
      const id = istemplateid;
      const contact = mobileNumbers;
      // const mediaurl = selectedmedia?.url;
      const mediaurl = mediaUrls;
      const campname = iscamp;
      const conatactId = selectedList;
      const parameters = bodyparamter;
      const latitude = islatitude;
      const longitude = islongitude;
      const address = isaddress;
      const name = islocatioName;

      if (!campname || campname.length === 0) {
        toast.error("camp name is required!!");
        return;
      }
      if (id.length === 0 || !id) {
        toast.error("template name is required!!");
        return;
      }

      console.log("isheader", isheader);

      if (
        isheader &&
        !["text", "location"].includes(isheader.toLocaleLowerCase())
      ) {
        if (!mediaUrls || mediaUrls.length === 0) {
          toast.error("Media is required!!");
          return;
        }
      }
      document.getElementById("broadcasting").close(); // Close the broadcasting element
      await sendBulkMessage({
        id,
        contact,
        mediaurl,
        campname,
        parameters,
        conatactId,
        latitude,
        longitude,
        address,
        name,
      });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  // console.log("card in brodcasting looks likes :",iscardData[0].components)

  return (
    <div>
      {/* {isloader && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
        </div>
      )} */}
      <div
        className="btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]"
        onClick={() => document.getElementById("broadcasting").showModal()}
      >
        BROADCAST
      </div>

      <dialog id="broadcasting" className="modal z-40 ">
        <div className="modal-box min-w-[60%] h-screen bg-white text-black z-40">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          {/* main code */}
          <div className="flex w-full justify-between gap-12 mt-10 ">
            <div className="mx-auto mt-3">
              <TextComponent heading="Camp Name" />
              <input
                type="text"
                placeholder="Enter camp name "
                className="input input-bordered w-full h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                onChange={(e) => {
                  setcamp(e.currentTarget.value);
                }}
                required
              />
              <TextComponent heading="Template Name" />

              <Autocomplete
                disablePortal
                id="TemplateLanguage"
                options={AcceptedTemplate.map((data) => ({
                  name: data.configData.name,
                }))}
                getOptionLabel={(option) => option.name}
                sx={{
                  width: "100%",
                  marginTop: "15px",
                  background: "#F0F0F0",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .css-z8b3ht-MuiFormLabel-root-MuiInputLabel-root": {
                    display: "none",
                  },
                  "& .MuiAutocomplete-input": {
                    padding: "7.5px 4px 7.5px 13px",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#A9A9A9",
                    fontSize: "medium",
                  },
                }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    onMouseEnter={(event) => handlHoverTemplate(event, option)} // Mouse move event
                    onMouseLeave={(event) => handleMouseLeave(event, option)}
                    // style={{
                    //   backgroundColor:
                    //     hoveredOption === option ? "#e0e0e0" : "white", // Highlight on hover
                    // }}
                  >
                    {option.name}
                  </li>
                )}
                onChange={handleTypeChange} // Keep original onChange for selection
                renderInput={(params) => (
                  <TextField {...params} label="Choose template" />
                )}
              />
              <div className="mt-10">
                <ParamField
                  isbody={isbody}
                  onObjectsArrayChange={handleObjectsArrayChange}
                />
              </div>

              {isheader.toUpperCase() === "TEXT" ||
              isheader.toUpperCase() === "" ||
              iscardData ||
              // null ||
              isheader === "Location" ? (
                <div></div>
              ) : (
                <div className="mt-10">
                  <TextComponent
                    heading="Media"
                    body1="Size < 5MB, Accepted formats - .png or .jpeg"
                  />
                  <div className="flex justify-between">
                    {selectedmedia?.url ? (
                      <input
                        type="text"
                        placeholder=""
                        value={selectedmedia?.url}
                        className="input input-xs input-bordered w-5/6 h-auto p-2  bg-[#F0F0F0] text-black mt-4 placeholder:font-medium font-normal"
                      />
                    ) : (
                      <input
                        type="text"
                        placeholder="Media URL"
                        className="input input-xs input-bordered w-5/6 h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                      />
                    )}

                    <span className="mt-4 mx-2">OR</span>

                    <div
                      className="btn btn-sm mt-2 bg-opacity-20 hover:bg-slate-200 shadow-none border-none font-normal text-black"
                      onClick={() =>
                        document.getElementById("mediaSelect").showModal()
                      }
                    >
                      <MdPermMedia />
                      Upload Media
                      {isMediaFetch && (
                        <SelectMedia
                          isheader={isheader}
                          onsend={handlegetmediaurl}
                          iscardData={iscardData}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* </div> */}

              <div>
                {isheader === "Location" && (
                  <div className="mt-7">
                    <input
                      type="text"
                      placeholder="latitude"
                      // value={selectedmedia?.url}
                      className="input input-xs input-bordered w-5/6 h-auto p-2  bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                      onChange={(e) => {
                        setlatitude(e.currentTarget.value);
                      }}
                    />
                    <input
                      type="text"
                      placeholder="longitude"
                      // value={selectedmedia?.url}
                      className="input input-xs input-bordered w-5/6 h-auto p-2  bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                      onChange={(e) => {
                        setlongitue(e.currentTarget.value);
                      }}
                    />
                    <input
                      type="text"
                      placeholder="name"
                      // value={selectedmedia?.url}
                      className="input input-xs input-bordered w-5/6 h-auto p-2  bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                      onChange={(e) => {
                        setlocationName(e.currentTarget.value);
                      }}
                    />
                    <input
                      type="text"
                      placeholder="address"
                      // value={selectedmedia?.url}
                      className="input input-xs input-bordered w-5/6 h-auto p-2  bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                      onChange={(e) => {
                        setaddress(e.currentTarget.value);
                      }}
                    />
                  </div>
                )}

                {iscardData && (
                  <div>
                    <div className="mt-10">
                      <TextComponent
                        heading="Media"
                        body1="Size < 5MB, Accepted formats - .png or .jpeg"
                      />
                      <div className="flex justify-between">
                        {/* {selectedmedia?.url ? (
                          <input
                            type="text"
                            placeholder=""
                            value={selectedmedia?.url}
                            className="input input-xs input-bordered w-5/6 h-auto p-2  bg-[#F0F0F0] text-black mt-4 placeholder:font-medium font-normal"
                          />
                        ) : (
                          <input
                            type="text"
                            placeholder="Media URL"
                            className="input input-xs input-bordered w-5/6 h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
                          />
                        )} */}

                        <span className="mt-4 mx-2">OR</span>

                        <div
                          className="btn btn-sm mt-2 bg-opacity-20 hover:bg-slate-200 shadow-none border-none font-normal text-black"
                          onClick={() =>
                            document.getElementById("mediaSelect").showModal()
                          }
                        >
                          <MdPermMedia />
                          Upload Media
                          {isMediaFetch && (
                            <SelectMedia
                              isheader={isheader}
                              onsend={handlegetmediaurl}
                              iscardData={iscardData}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div>
              {selectedType === null || undefined || !selectedType ? (
                <div className=" flex justify-center items-center  rounded-md  mb-2">
                  <div className="container  flex justify-center">
                    <div className="mockup-phone sm:w-[285px]  w-[285px]  lg:w-[285px]  ">
                      <div className="camera "></div>
                      <div className="display bg-white min-h-[450px] relative ">
                        <div className="flex flex-col min-h-[500px]  bg-gray-100">
                          <div className="bg-teal-700 text-white p-2  min-h-[45px] flex items-center">
                            <FaArrowLeft className="mr-2 text-sm text-white mt-4" />

                            <div className="w-8 h-8 bg-yellow-400 rounded-full flex items-center justify-center mr-2 mt-4">
                              <span className="text-black font-bold">T</span>
                            </div>
                            <div className="flex-grow">
                              <div className="font-semibold mt-3">
                                TheBotmode
                              </div>
                              <div className="text-xs  font-normal ">
                                online
                              </div>
                            </div>
                            <div className="flex items-center h-[45px] mt-3 ">
                              <BiVideo className="mx-2 text-lg text-white" />
                              <MdOutlineCall className="mx-1 text-lg text-white" />
                              <IoEllipsisVertical className="mx-1 text-lg text-white" />
                            </div>
                          </div>

                          {/* Chat area */}
                          <div className="flex-grow bg-gray-200 p-4 relative">
                            <img
                              src="https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png"
                              className="h-full w-full object-cover absolute inset-0"
                              alt="whatsapp background"
                            />
                            <div className="  h-[20px] w-[45px]  bg-slate-100 rounded-sm border-1   absolute top-1 left-[40%]  flex items-center justify-center z-[3] bg-opacity-55">
                              <span className="text-xs text-center  font-normal  text-[#54656F]">
                                Today
                              </span>
                            </div>
                            <p className="text-gray-500 absolute  top-14 left-[22%] text-sm">
                              select your template
                            </p>
                          </div>

                          {/* Input area */}
                          <div className=" w-full p-2 flex items-center mb-2 absolute bottom-1 left-0">
                            <div className="flex-grow bg-white rounded-full w-[80%]  py-2 flex items-center">
                              <CgSmileMouthOpen className="text-gray-600 mx-2 text-xl " />
                              <input
                                type="text"
                                placeholder="Type a message"
                                className="w-[60%] outline-none text-gray-600 bg-white"
                                readOnly
                              />
                              {/* <Paperclip className="text-gray-600 mx-2" /> */}
                              <BsPaperclip className="text-gray-600  mx-2 text-lg" />

                              {/* <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center"> */}
                              {/* <img src="/api/placeholder/32/32" alt="Camera icon" className="w-5 h-5" /> */}
                              <MdOutlineCameraAlt className="text-gray-600  mx-2 text-lg" />
                              {/* </div> */}
                            </div>
                            <div className="w-8 h-8 bg-teal-700 rounded-full flex items-center justify-center ml-1 ">
                              {/* <Mic className="text-white" /> */}
                              <IoMdMic className="text-white text-lg " />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : iscardData ? (
                <div className="container  flex justify-center">
                  <div className="mockup-phone sm:w-[285px]  w-[285px]  lg:w-[285px]  ">
                    <div className="camera "></div>
                    <div className="display bg-white min-h-[450px] relative ">
                      <div className="flex flex-col min-h-[500px]  bg-gray-100">
                        <div className="bg-teal-700 text-white p-2  min-h-[45px] flex items-center">
                          <FaArrowLeft className="mr-2 text-sm text-white mt-4" />

                          <div className="w-8 h-8 bg-yellow-400 rounded-full flex items-center justify-center mr-2 mt-4">
                            <span className="text-black font-bold">T</span>
                          </div>
                          <div className="flex-grow">
                            <div className="font-semibold mt-3">TheBotmode</div>
                            <div className="text-xs  font-normal ">online</div>
                          </div>
                          <div className="flex items-center h-[45px] mt-3 ">
                            <BiVideo className="mx-2 text-lg text-white" />
                            <MdOutlineCall className="mx-1 text-lg text-white" />
                            <IoEllipsisVertical className="mx-1 text-lg text-white" />
                          </div>
                        </div>

                        {/* Chat area */}
                        <div className="flex-grow bg-gray-200 p-4 relative">
                          <img
                            src="https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png"
                            className="h-full w-full object-cover absolute inset-0"
                            alt="whatsapp background"
                          />
                          <div className="  h-[20px] w-[45px]  bg-slate-100 rounded-sm border-1   absolute top-1 left-[40%]  flex items-center justify-center z-[3] bg-opacity-55">
                            <span className="text-xs text-center  font-normal  text-[#54656F]">
                              Today
                            </span>
                          </div>
                          <div className="absolute rounded-t-md top-15 left-3 w-10/12 mx-auto  max-h-[calc(100%-96px)] overflow-y-auto custom-scroll ">
                            <div className="bg-white shadow p-2 text-center overflow-hidden">
                              <div className="w-auto  bg-[#F8F8F8] rounded-md  font-normal text-start whitespace-pre-wrap ">
                                <span className="ml-2  whitespace-pre-wrap ">
                                  {" "}
                                  {isbody}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* <div className="flex"> */}
                          <div className="absolute rounded-t-md top-[5rem] left-3 w-10/12  mx-auto max-h-[calc(100%-96px)]  overflow-x-auto  overflow-y-auto overflow-hidden custom-scroll ">
                            <div className=" shadow p-2 text-center gap-2 flex">
                              {iscardData?.map((data, index) => (
                                <div key={index}>
                                  <div className="w-auto  bg-[#F8F8F8] rounded-md  font-normal text-start  ">
                                    {data.components?.map((Component, i) => (
                                      <div key={i}>
                                        {/* <div className="absolute rounded-t-md top-15 left-3 w-10/12 mx-auto max-h-[calc(100%-96px)]  overflow-y-auto custom-scroll "> */}
                                        {/* <div className="bg-white shadow p-2 text-center overflow-hidden"> */}
                                        {/* <div className="w-auto  bg-[#F8F8F8] rounded-md  font-normal text-start  mt-2"> */}
                                        {Component?.type === "HEADER" && (
                                          <div>
                                            {Component?.format === "IMAGE" && (
                                              <div className="flex justify-center items-center">
                                                {mediaUrls ? (
                                                  <figure className=" w-52">
                                                    <img
                                                      src={mediaUrls[index]}
                                                      alt="loading image"
                                                      className="h-40  w-52 object-cover  rounded-md cursor-pointer"
                                                    />
                                                  </figure>
                                                ) : (
                                                  <IoImageSharp className="h-40   w-44 bg-orange-300 rounded-md" />
                                                )}
                                              </div>
                                            )}
                                            {Component?.format === "VIDEO" && (
                                              <div className="">
                                                {mediaUrls ? (
                                                  <figure className=" w-52">
                                                    <video
                                                      src={mediaUrls[index]}
                                                      controls
                                                      className="h-40  w-52 object-cover  rounded-md cursor-pointer"
                                                    />
                                                  </figure>
                                                ) : (
                                                  <IoPlayCircle className="h-40   w-44  text-blue-600" />
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                        {/* </div> */}
                                        {Component.type === "BODY" && (
                                          <div className="font-normal text-sm mt-3 text-start overflow-hidden whitespace-pre-wrap">
                                            <div
                                              className="overflow-hidden  "
                                              dangerouslySetInnerHTML={{
                                                __html: formatText(
                                                  Component.text
                                                ),
                                              }}
                                            />
                                          </div>
                                        )}

                                        <div className="text-xs font-normal text-start text-blue-600 mt-4">
                                          {Component?.footer}
                                        </div>

                                        {Component.type === "BUTTONS" && (
                                          <div>
                                            {Component.buttons.map(
                                              (button, INDEX) => (
                                                <div key={INDEX}>
                                                  {button.type === "url" && (
                                                    <div className="templatebuttons flex gap-1 items-center justify-center border-blue border-1">
                                                      <FaArrowRightLong className="text-blue-600 text-sm" />{" "}
                                                      {button.text}
                                                    </div>
                                                  )}
                                                  {button.type ===
                                                    "PHONE_NUMBER" && (
                                                    <div className="templatebuttons flex gap-1 items-center justify-center border-blue border-1">
                                                      <MdOutlineCall className="text-blue-600 text-sm" />{" "}
                                                      {button.text}
                                                    </div>
                                                  )}
                                                  {button.type ===
                                                    "QUICK_REPLY" && (
                                                    <div className="templatebuttons flex gap-1 items-center justify-center border-blue border-1">
                                                      <HiLink className="text-blue-600 text-sm" />{" "}
                                                      {button.text}
                                                    </div>
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}
                                        {/* </div> */}
                                        {/* </div> */}
                                      </div>
                                    ))}
                                    {/* </div> */}
                                    {/* </div> */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          {/* </div> */}
                          <div className="h-6 w-6 rounded-full bg-gray-500 bg-opacity-30 absolute top-[35%] ml-1 right-1.5 flex items-center justify-center">
                            <LuForward className="text-white text-sm" />
                          </div>
                        </div>

                        {/* Input area */}
                        <div className=" w-full p-2 flex items-center mb-2 absolute bottom-1 left-0">
                          <div className="flex-grow bg-white rounded-full w-[80%]  py-2 flex items-center">
                            <CgSmileMouthOpen className="text-gray-600 mx-2 text-xl " />
                            <input
                              type="text"
                              placeholder="Type a message"
                              className="w-[60%] outline-none text-gray-600 bg-white"
                              readOnly
                            />
                            {/* <Paperclip className="text-gray-600 mx-2" /> */}
                            <BsPaperclip className="text-gray-600  mx-2 text-lg" />

                            {/* <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center"> */}
                            {/* <img src="/api/placeholder/32/32" alt="Camera icon" className="w-5 h-5" /> */}
                            <MdOutlineCameraAlt className="text-gray-600  mx-2 text-lg" />
                            {/* </div> */}
                          </div>
                          <div className="w-8 h-8 bg-teal-700 rounded-full flex items-center justify-center ml-1 ">
                            {/* <Mic className="text-white" /> */}
                            <IoMdMic className="text-white text-lg " />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="container  flex justify-center">
                    <div className="mockup-phone sm:w-[285px]  w-[285px]  lg:w-[285px]  ">
                      <div className="camera "></div>
                      <div className="display bg-white min-h-[450px] relative ">
                        <div className="flex flex-col min-h-[500px]  bg-gray-100">
                          <div className="bg-teal-700 text-white p-2  min-h-[45px] flex items-center">
                            <FaArrowLeft className="mr-2 text-sm text-white mt-4" />

                            <div className="w-8 h-8 bg-yellow-400 rounded-full flex items-center justify-center mr-2 mt-4">
                              <span className="text-black font-bold">T</span>
                            </div>
                            <div className="flex-grow">
                              <div className="font-semibold mt-3">
                                TheBotmode
                              </div>
                              <div className="text-xs  font-normal ">
                                online
                              </div>
                            </div>
                            <div className="flex items-center h-[45px] mt-3 ">
                              <BiVideo className="mx-2 text-lg text-white" />
                              <MdOutlineCall className="mx-1 text-lg text-white" />
                              <IoEllipsisVertical className="mx-1 text-lg text-white" />
                            </div>
                          </div>

                          {/* Chat area */}
                          <div className="flex-grow bg-gray-200 p-4 relative">
                            <img
                              src="https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png"
                              className="h-full w-full object-cover absolute inset-0"
                              alt="whatsapp background"
                            />
                            <div className="  h-[20px] w-[45px]  bg-slate-100 rounded-sm border-1   absolute top-1 left-[40%]  flex items-center justify-center z-[3] bg-opacity-55">
                              <span className="text-xs text-center  font-normal  text-[#54656F]">
                                Today
                              </span>
                            </div>
                            <div className="absolute rounded-t-md top-15 left-3 w-10/12 mx-auto max-h-[calc(100%-96px)] overflow-y-auto custom-scroll ">
                              <div className="bg-white shadow p-2 text-center overflow-hidden">
                                {/* {selectedType !== "None" && selectedType !== "Text" && ( */}
                                <div className="w-auto bg-[#F8F8F8] rounded-md flex gap-5 justify-center items-center">
                                  {selectedmedia?.url ? (
                                    selectedmedia?.mediaType === "image" ? (
                                      <figure>
                                        <img
                                          src={mediaUrls}
                                          alt="loading image"
                                          className="h-40 w-96 object-cover rounded-md cursor-pointer"
                                        />
                                      </figure>
                                    ) : selectedmedia?.mediaType === "video" ? (
                                      <figure>
                                        <video
                                          src={mediaUrls}
                                          controls
                                          className="h-40 w-80 object-cover cursor-pointer"
                                        />
                                      </figure>
                                    ) : selectedmedia?.mediaType ===
                                      "document" ? (
                                      selectedmedia?.url.endsWith(".csv") ? (
                                        <div className="w-96 h-40 overflow-auto">
                                          <CsvDisplay
                                            url={selectedmedia?.url}
                                          />
                                        </div>
                                      ) : selectedmedia?.url.endsWith(".doc") ||
                                        selectedmedia?.url.endsWith(".docx") ? (
                                        <div className="w-96 h-40">
                                          <iframe
                                            src={`https://docs.google.com/gview?url=${selectedmedia?.url}&embedded=true`}
                                            className="w-full h-full"
                                            title="Document Preview"
                                            frameBorder="0"
                                            scrolling="auto"
                                          ></iframe>
                                        </div>
                                      ) : (
                                        <div className="w-96 h-40">
                                          <iframe
                                            src={selectedmedia?.url}
                                            className="w-full h-full"
                                            title="Document Preview"
                                            frameBorder="0"
                                            scrolling="auto"
                                          ></iframe>
                                        </div>
                                      )
                                    ) : null
                                  ) : (
                                    <figure>{content}</figure>
                                  )}
                                </div>

                                {/* )} */}
                                <div className="text-sm  font-whatsapp-bold text-start mt-3">
                                  {istitle}
                                </div>
                                <div className="font-normal text-sm mt-3 text-start overflow-hidden whitespace-pre-wrap">
                                  <div
                                    className="overflow-hidden whitespace-pre-wrap  "
                                    dangerouslySetInnerHTML={{
                                      __html: formatText(isbody),
                                    }}
                                  />
                                </div>
                                <div className="text-xs font-normal text-start text-blue-600 mt-4">
                                  {isfooter}
                                </div>
                              </div>

                              {buttons.map((button, index) => (
                                <div key={index} className="">
                                  {button.type === "PHONE_NUMBER" && (
                                    <div className="templatebuttons flex gap-1 items-center justify-center border-blue border-1">
                                      <MdOutlineCall className="text-blue-600 text-sm" />
                                      {button.text}
                                    </div>
                                  )}
                                  {button.type === "url" && (
                                    <div className="templatebuttons flex gap-1 items-center justify-center border-blue border-1">
                                      <BiLinkExternal className="text-blue-600 text-sm" />{" "}
                                      {button.text}
                                    </div>
                                  )}
                                  {button.type === "QUICK_REPLY" && (
                                    <div className="templatebuttons rounded-b-md flex gap-1 items-center justify-center border-blue border-1">
                                      <CgMailReply className="text-blue-600 text-sm" />
                                      {button.text}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                            <div className="h-6 w-6 rounded-full bg-gray-500 bg-opacity-30 absolute top-[35%] ml-1 right-1.5 flex items-center justify-center">
                              <LuForward className="text-white text-sm" />
                            </div>
                          </div>

                          {/* Input area */}
                          <div className=" w-full p-2 flex items-center mb-2 absolute bottom-1 left-0">
                            <div className="flex-grow bg-white rounded-full w-[80%]  py-2 flex items-center">
                              <CgSmileMouthOpen className="text-gray-600 mx-2 text-xl " />
                              <input
                                type="text"
                                placeholder="Type a message"
                                className="w-[60%] outline-none text-gray-600 bg-white"
                                readOnly
                              />
                              {/* <Paperclip className="text-gray-600 mx-2" /> */}
                              <BsPaperclip className="text-gray-600  mx-2 text-lg" />

                              {/* <div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center"> */}
                              {/* <img src="/api/placeholder/32/32" alt="Camera icon" className="w-5 h-5" /> */}
                              <MdOutlineCameraAlt className="text-gray-600  mx-2 text-lg" />
                              {/* </div> */}
                            </div>
                            <div className="w-8 h-8 bg-teal-700 rounded-full flex items-center justify-center ml-1 ">
                              {/* <Mic className="text-white" /> */}
                              <IoMdMic className="text-white text-lg " />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="modal-action">
            {/* <form method="dialog"> */}
            <button
              className="btn btn-md bg-[#0A474C] text-white hover:bg-[#132f31] hover:text-white border-none w-40 me-5"
              onClick={() => {
                handlebulkmessaging(); // Invoke the function
              }}
            >
              SEND NOW
            </button>

            {/* if there is a button in form, it will close the modal */}
            <button
              className="btn"
              onClick={() => {
                document.getElementById(`broadcasting`).close();
              }}
            >
              Close
            </button>
            {/* </form> */}
          </div>
        </div>

        <Toaster
          toastOptions={{
            style: {
              zIndex: 9999, // Ensure toast stays above the dialog
            },
          }}
        />
      </dialog>
    </div>
  );
};

function SelectMedia({ isheader, onsend, iscardData }) {
  //hooks to delete media
  const { deletedMedia, isloading, deletemedia, setDeleteMedia } =
    useDeleteMedia();

  //hooks to post media
  const { isuploadmedia, ismediaLoader, uploadmedia, setuploadmedia, iserror } =
    usePostmedia();

  // const { authUser, setAuthUser } = UseAuthContext();

  console.log("error inside the page looks likes this :", iserror);
  const [selectedImage, setSelectedImage] = useState([]);

  const [ismultiselect, setmultiselect] = useState(false);
  let mediaquery;
  if (!iscardData) {
    mediaquery = isheader;
    // setmultiselect(false)
  } else {
    mediaquery = iscardData[0]?.components[0]?.format;
    // setmultiselect(true)
  }

  const { ismedia, isusedStoreage, isloader, refetch } = useFetchMedia(
    mediaquery.toLowerCase()
  );
  console.log("ismedia looks likes this :", ismedia);
  const [isupload, setupload] = useState("");
  const [Uploadloop, setuploadsloop] = useState(false);
  //function to upload media
  const handleuploadmedia = async (event) => {
    const file = event.target.files[0];

    setupload(file);

    try {
      setuploadsloop(true);
      const formData = new FormData();
      formData.append("file", file);
      console.log("data in formdata looks like this :", formData);
      await uploadmedia(
        formData // Directly using the file from the event
      );
      refetch();
    } catch (error) {
      // console.error("Error uploading template:", error);
      toast.error("Failed to upload template. Please try again.");
    } finally {
      setuploadsloop(false);
    }
  };
  console.log("uploaded file looks like this :", isupload);

  //function to delete media
  const handleDeletemedia = async () => {
    try {
      // const id = selectedImage?.id;
      // console.log("deletd media selected looks likes this:", id);

      let id;
      if (Array.isArray(selectedImage)) {
        id = selectedImage[0]?.id;
      } else {
        id = selectedImage?.id;
      }
      if (!id) {
        return toast.error("please select your media");
      }
      await deletemedia({ id });
      document.getElementById("deletemedia").close();
      setSelectedImage([]);
      refetch();
    } catch (error) {
      toast.error("something went wrong..");
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  console.log("media is :", ismedia);
  console.log(
    "selected images in  compoonent looks likes this :",
    selectedImage
  );

  console.log("media is multiple card is :", ismultiselect);

  useEffect(() => {
    if (iscardData) {
      setmultiselect(true); // Set single-select if no card data
    } else {
      setmultiselect(false); // Set multi-select if card data exists
    }
  }, [iscardData]);

  const handleClick = (data) => {
    if (ismultiselect === true) {
      // Multi-select logic
      setSelectedImage((prevSelected) => {
        // Ensure `prevSelected` is an array
        const isSelected = prevSelected.find(
          (image) => image?.url === data?.url
        );

        if (isSelected) {
          // Deselect if already selected (remove from array)
          return prevSelected.filter((image) => image.url !== data.url);
        } else {
          // Select new media (add to array)
          return [
            ...prevSelected,
            { url: data.url, mediaType: data.mediaType, id: data._id },
          ];
        }
      });
    } else {
      // Single-select logic
      setSelectedImage((prevSelected) => {
        // Ensure `prevSelected` is an object
        if (prevSelected?.url === data.url) {
          // Deselect if already selected
          return { url: null, mediaType: null, id: null };
        } else {
          // Select new media
          return { url: data.url, mediaType: data.mediaType, id: data._id };
        }
      });
    }
  };

  const handlesendmedia = () => {
    if (selectedImage) {
      onsend(selectedImage);
      // document.getElementById(`mediaSelect`).close();
    } else {
      toast.error("Please select an image first.");
    }
  };

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (isuploadmedia || deletedMedia) {
      // Show the message when upload or delete is successful
      setShowMessage(true);

      // Hide the message after 4 seconds
      const timer = setTimeout(() => {
        setShowMessage(false);

        // // Optionally reset states if needed
        if (isuploadmedia) {
          // Reset isuploadmedia if needed (optional based on your state logic)
          setuploadmedia(null); // Reset upload media state
        }

        if (deletedMedia) {
          // Reset deletedMedia if needed (optional based on your state logic)
          setDeleteMedia(null); // Reset deleted media state
        }
      }, 2000);

      // Clean up the timer when component unmounts or when media state changes
      return () => clearTimeout(timer);
    }
  }, [isuploadmedia, deletedMedia]);
  //carousol card handeling
  console.log("deleted media data looks likes this ", deletedMedia);

  // const [activeCardIndex, setActiveCardIndex] = useState(0);

  // // const handleNextCard = () => {
  //   if (activeCardIndex < iscardData?.cards.length - 1) {
  //     setActiveCardIndex((prevIndex) => prevIndex + 1);
  //   }
  // };

  // const handlePreviousCard = () => {
  //   if (activeCardIndex > 0) {
  //     setActiveCardIndex((prevIndex) => prevIndex - 1);
  //   }
  // };

  const fileInputRef = useRef(null);

  const handleClickRef = () => {
    fileInputRef.current?.click();
  };

  // const [isStorageUsed, setStorageUsed] = useState(isusedStoreage);

  function bytesToMB(isStorageUsed) {
    const MB = 1024 * 1024; // 1 MB = 1024 KB = 1024 * 1024 bytes
    return (isStorageUsed / MB).toFixed(2); // Convert bytes to MB and round to 2 decimal places
  }

  const usedStorage = bytesToMB(isusedStoreage);

  console.log("meta details looks likes this :", isusedStoreage);
  // console.log("used storeage looks likes this :", isStorageUsed);

  const maxStorage = 500; // 500 MB
  // const waringStorageUsed = 400 * 1024 * 1024; // Example: 400 MB in bytes

  const percentageUsed = (usedStorage / maxStorage) * 100; // Calculate percentage used
  const isWarning = percentageUsed > 80; // Check if usage exceeds 80%

  return (
    <>
      <dialog id="mediaSelect" className="modal z-40 ">
        <div className="modal-box h-screen min-w-[60%] bg-white text-black flex flex-col z-40">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <div className="w-full flex ">
            <div className="flex flex-col w-[200px] space-y-2">
              <p className="text-start text-lg font-medium text-gray-700 ">
                media storage
              </p>
              <input
                type="range"
                min={0}
                max={maxStorage}
                value={usedStorage}
                className="h-1 w-full appearance-none bg-gray-300"
                style={{
                  background: `linear-gradient(to right, ${
                    isWarning ? "#dc2626" : "#16a34a"
                  } ${percentageUsed}%, #e5e7eb ${percentageUsed}%)`,
                }}
                readOnly
              />
              <p className="text-start text-sm text-gray-600">
                <span className="text-green-600 text-lg"> {usedStorage} </span>
                MB of {maxStorage} MB used
                <span className="text-red-500">
                  {" "}
                  ({percentageUsed.toFixed(2)}%)
                </span>
              </p>
            </div>
            <h3 className=" ml-96 font-bold text-xl text-green-900 flex-col w-[350px]">
              <span className="items-center"> Media Library</span>

              <div className=" first-letter:h-7 flex mt-4">
                <div className="w-full flex justify-end gap-3 ">
                  {showMessage && isuploadmedia && !ismediaLoader && (
                    <div className="fixed top-12 left-1/2 transform -translate-x-1/2 bg-white border border-gray-200 shadow-md px-6 py-3 rounded-lg flex items-center gap-3 z-50">
                      <div className="bg-green-100 rounded-full p-2">
                        <IoIosCheckmarkCircle className="text-green-500 text-2xl" />
                      </div>
                      <span className="font-medium text-gray-800 text-sm">
                        {isuploadmedia.message}
                      </span>
                    </div>
                  )}
                  {showMessage && deletedMedia && !isloader && (
                    <div className="fixed top-12 left-1/2 transform -translate-x-1/2 bg-white border border-gray-200 shadow-md px-6 py-3 rounded-lg flex items-center gap-3 z-50">
                      <div className="bg-green-100 rounded-full p-2">
                        <IoIosCheckmarkCircle className="text-green-500 text-2xl" />
                      </div>
                      <span className="font-medium text-gray-800 text-sm">
                        {deletedMedia.message}
                      </span>
                    </div>
                  )}

                  <div className="inline-block">
                    <button
                      onClick={handleClickRef}
                      disabled={ismediaLoader}
                      className="btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]"
                    >
                      <MdOutlineCloudUpload className="text-xl text-white" />
                      Upload Media
                    </button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      className="hidden"
                      // accept={accept}
                      id="import"
                      onChange={handleuploadmedia}
                      onClick={(e) => {
                        // Critical fix: Reset value on click
                        e.currentTarget.value = "";
                      }}
                      // multiple
                    />
                  </div>
                  {selectedImage.url || selectedImage.length === 1 ? (
                    <button className="btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]">
                      <MdDeleteForever
                        className="text-xl text-white"
                        onClick={() =>
                          document.getElementById("deletemedia").showModal()
                        }
                      />
                      <dialog
                        id="deletemedia"
                        className="modal"
                        style={{ zIndex: "888px" }}
                      >
                        <div className="modal-box">
                          <form method="dialog">
                            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                              ✕
                            </button>
                          </form>

                          <div className="mx-auto flex justify-center">
                            <RiDeleteBin5Fill className="text-4xl text-gray-500" />
                          </div>
                          <p className="mx-auto text-lg text-center p-3 text-black">
                            Are you sure you want to delete this media?
                          </p>
                          <button
                            className="btn btn-sm bg-[#DC2C2B] text-white border-none rounded-md hover:bg-[#DC2C2B]"
                            onClick={handleDeletemedia}
                            disabled={isloading}
                          >
                            <RiDeleteBin5Fill className="text-lg text-white" />

                            <span className="gap-1 text-lg">Yes, I'm sure</span>
                          </button>
                        </div>
                      </dialog>
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]"
                      disabled
                    >
                      <MdDeleteForever
                        className="text-xl text-white"
                        onClick={() =>
                          document.getElementById("deletemedia").showModal()
                        }
                      />
                      <dialog
                        id="deletemedia"
                        className="modal"
                        style={{ zIndex: "888px" }}
                      >
                        <div className="modal-box">
                          <form method="dialog">
                            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                              ✕
                            </button>
                          </form>

                          <div className="mx-auto flex justify-center">
                            <RiDeleteBin5Fill className="text-4xl text-gray-500" />
                          </div>
                          <p className="mx-auto text-lg text-center p-3 text-black">
                            Are you sure you want to delete this media?
                          </p>
                          <button
                            className="btn btn-sm bg-[#DC2C2B] text-white border-none rounded-md hover:bg-[#DC2C2B]"
                            onClick={handleDeletemedia}
                            disabled={isloading}
                          >
                            <RiDeleteBin5Fill className="text-lg text-white" />

                            <span className="gap-1 text-lg">Yes, I'm sure</span>
                          </button>
                        </div>
                      </dialog>
                    </button>
                  )}
                </div>
              </div>
            </h3>
          </div>

          {/* <div className=" first-letter:h-7 flex mt-3">
         
            <div className="w-full flex justify-end gap-3 ">
              {showMessage && isuploadmedia && !ismediaLoader && (
                <div className="fixed top-12 left-1/2 transform -translate-x-1/2 bg-white border border-gray-200 shadow-md px-6 py-3 rounded-lg flex items-center gap-3 z-50">
                  <div className="bg-green-100 rounded-full p-2">
                    <IoIosCheckmarkCircle className="text-green-500 text-2xl" />
                  </div>
                  <span className="font-medium text-gray-800 text-sm">
                    {isuploadmedia.message}
                  </span>
                </div>
              )}
              {showMessage && deletedMedia && !isloader && (
                <div className="fixed top-12 left-1/2 transform -translate-x-1/2 bg-white border border-gray-200 shadow-md px-6 py-3 rounded-lg flex items-center gap-3 z-50">
                  <div className="bg-green-100 rounded-full p-2">
                    <IoIosCheckmarkCircle className="text-green-500 text-2xl" />
                  </div>
                  <span className="font-medium text-gray-800 text-sm">
                    {deletedMedia.message}
                  </span>
                </div>
              )}

              <div className="inline-block">
                <button
                  onClick={handleClickRef}
                  disabled={ismediaLoader}
                  className="btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]"
                >
                  <MdOutlineCloudUpload className="text-xl text-white" />
                  Upload Media
                </button>
                <input
                  ref={fileInputRef}
                  type="file"
                  className="hidden"
                  // accept={accept}
                  id="import"
                  onChange={handleuploadmedia}
                  onClick={(e) => {
                    // Critical fix: Reset value on click
                    e.currentTarget.value = "";
                  }}
                  // multiple
                />
              </div>
              <button className="btn btn-sm bg-[#0A474C] text-white border-none rounded-md hover:bg-[#173b3e]">
                <MdDeleteForever
                  className="text-xl text-white"
                  onClick={() =>
                    document.getElementById("deletemedia").showModal()
                  }
                />
                <dialog
                  id="deletemedia"
                  className="modal"
                  style={{ zIndex: "888px" }}
                >
                  <div className="modal-box">
                    <form method="dialog">
                      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                        ✕
                      </button>
                    </form>

                    <div className="mx-auto flex justify-center">
                      <RiDeleteBin5Fill className="text-4xl text-gray-500" />
                    </div>
                    <p className="mx-auto text-lg text-center p-3 text-black">
                      Are you sure you want to delete this media?
                    </p>
                    <button
                      className="btn btn-sm bg-[#DC2C2B] text-white border-none rounded-md hover:bg-[#DC2C2B]"
                      onClick={handleDeletemedia}
                      disabled={isloading}
                    >
                      <RiDeleteBin5Fill className="text-lg text-white" />

                      <span className="gap-1 text-lg">Yes, I'm sure</span>
                    </button>
                  </div>
                </dialog>
              </button>
            </div>
          </div> */}
          <div className="flex w-full items-center">
            <div role="tablist" className="tabs tabs-bordered mt-4 w-[10%]">
              <a role="tab" className="tab tab-active justify-start">
                {mediaquery}
              </a>
            </div>
            <div className="ml-auto  flex-col mt-3">
              {/* <div className="flex flex-col w-[200px] space-y-2">
                <p className="text-start text-sm font-medium text-gray-700">
                  media storage
                </p>
                <input
                  type="range"
                  min={0}
                  max={100}
                  value={40}
                  className="h-1 w-full appearance-none bg-gray-300 accent-green-600"
                  style={{
                    background: `linear-gradient(to right, #16a34a 40%, #e5e7eb 40%)`,
                  }}
                />
                <p className="text-start text-sm text-gray-600">
                  305.4 MB used of 5 GB
                </p>
              </div> */}
            </div>
          </div>
          <span className="text-green-800 mt-5 text-right mr-4">
            All {mediaquery}({ismedia.length})
          </span>

          <div className="flex-1 overflow-y-auto mt-3">
            <div className="mx-auto flex flex-wrap justify-evenly gap-5 mt-4">
              {ismedia?.map((data) => (
                <div key={data._id} className="relative">
                  {data.mediaType === "image" ? (
                    <div className="h-32 w-36 mb-5">
                      <img
                        src={data.url}
                        alt={`Image ${data._id}`}
                        className={`h-32 w-36 rounded object-cover cursor-pointer 
                         ${
                           Array.isArray(selectedImage)
                             ? selectedImage.some(
                                 (image) =>
                                   image.url === data.url &&
                                   image.mediaType === data.mediaType
                               )
                               ? "border-4 border-[#173b3e]"
                               : ""
                             : selectedImage.url === data.url &&
                               selectedImage.mediaType === data.mediaType
                             ? "border-4 border-[#173b3e]"
                             : ""
                         }
                          `}
                        onClick={() => handleClick(data)}
                      />

                      <p className="text-center text-sm font-medium text-gray-800 truncate ">
                        {data.filename}
                      </p>
                    </div>
                  ) : data.mediaType === "video" ? (
                    <div className="h-32 w-36 mb-5">
                      <video
                        src={data.url}
                        className={`h-32 w-36  rounded object-cover cursor-pointer 
                          ${
                            Array.isArray(selectedImage)
                              ? selectedImage.some(
                                  (image) =>
                                    image.url === data.url &&
                                    image.mediaType === data.mediaType
                                )
                                ? "border-4 border-[#173b3e]"
                                : ""
                              : selectedImage.url === data.url &&
                                selectedImage.mediaType === data.mediaType
                              ? "border-4 border-[#173b3e]"
                              : ""
                          }
                        `}
                        onClick={() => handleClick(data)}
                        controls
                      />
                      <p className=" text-sm font-medium text-gray-800 truncate ">
                        {data.filename}
                      </p>
                    </div>
                  ) : data.mediaType === "document" ? (
                    <div>
                      <div
                        className={`
                    h-40 w-36 flex flex-col items-center justify-between p-4 rounded-lg
                    bg-white shadow-md hover:shadow-lg transition-shadow duration-300
                    cursor-pointer overflow-hidden
                    ${
                      selectedImage.url === data.url &&
                      selectedImage.mediaType === data.mediaType
                        ? "border-4 border-[#173b3e]"
                        : ""
                    }
                  `}
                        onClick={() => handleClick(data)}
                      >
                        {/* <File size={32} className="text-gray-600" /> */}
                        <IoDocumentText className="text-pink-500 text-5xl " />

                        <p className="text-center text-sm font-medium text-gray-800 truncate w-full">
                          {data.filename}
                        </p>

                        <a
                          href={data.url}
                          target="_blank"
                          download
                          className="mt-2 text-md text-blue-600 hover:text-blue-800 flex items-center gap-2 justify-center w-full"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent event propagation
                          }}
                        >
                          <MdDownload className="text-2xl text-blue-950" />
                          download
                        </a>
                      </div>
                      <div>
                        <a
                          href={data.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mt-2 text-sm text-blue-600 hover:text-blue-800 flex items-center justify-center w-full"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <span className="flex gap-2" type="button">
                            <MdVisibility
                              className="text-lg text-blue-500"
                              type="button"
                            />
                            View
                          </span>
                        </a>
                      </div>
                    </div>
                  ) : null}
                  {selectedImage.url === data.url &&
                    selectedImage.mediaType === data.mediaType && (
                      <MdCheckCircle className="absolute -top-4 left-0.5 transform -translate-x-1/2 text-2xl text-[#173b3e]" />
                    )}
                </div>
              ))}
            </div>
          </div>

          <div className="w-full bg-transparent h-12 fixed bottom-0 left-0 right-0 flex justify-end items-center">
            {selectedImage && iscardData?.length === selectedImage.length ? (
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}

                <button
                  className="btn btn-sm bg-green-800 text-white  mr-5  w-32  flex justify-center items-center"
                  onClick={handlesendmedia}
                >
                  Send
                </button>
              </form>
            ) : selectedImage.length < 1 && !iscardData ? (
              <button
                className="btn btn-sm bg-green-800 text-white  mr-5  w-[15%]  flex justify-center items-center"
                onClick={handlesendmedia}
              >
                send
              </button>
            ) : (
              <button
                className="btn btn-sm bg-green-800 text-white  mr-5  w-[15%]  flex justify-center items-center"
                onClick={handlesendmedia}
                disabled
              >
                send
              </button>
            )}
          </div>
        </div>

        <Toaster
          toastOptions={{
            style: {
              zIndex: 9999, // Ensure toast stays above the dialog
            },
          }}
        />
      </dialog>
    </>
  );
}

export default Broadcast;

function ParamField({ isbody, onObjectsArrayChange }) {
  const [inputValue, setInputValue] = useState(isbody);

  useEffect(() => {
    setInputValue(isbody);
    handleChange(isbody);
  }, [isbody]);
  console.log("Template body text:", inputValue);

  const [objectsArray, setObjectsArray] = useState([]);

  console.log("parameter looks like this :", objectsArray);

  const handleChange = (inputValue) => {
    // const value = e.target.value;

    const value = inputValue;

    const regex = /{{(\d+)}}/g;
    let match;
    const idsFound = new Set();

    // Extract existing IDs
    while ((match = regex.exec(value)) !== null) {
      idsFound.add(parseInt(match[1], 10));
    }

    // Sort the IDs and create new sequential IDs
    const sortedIds = Array.from(idsFound).sort((a, b) => a - b);
    const idMapping = new Map();
    let currentId = 1;
    sortedIds.forEach((originalId) => {
      idMapping.set(originalId, currentId++);
    });

    // Replace the original IDs with the new sequential IDs
    let modifiedValue = value.replace(
      regex,
      (match, p1) => `{{${idMapping.get(parseInt(p1, 10))}}}`
    );

    // Create a lookup map from objectsArray
    const lookupMap = new Map(
      objectsArray.map((item) => [item.id, item.parameterVal])
    );

    // Update the new objects array with sequential IDs
    const newObjectsArray = Array.from(idMapping.values()).map((id) => ({
      id,
      parameterVal: lookupMap.get(id) || "", // Use existing value if it exists, otherwise empty string
    }));

    setInputValue(modifiedValue);
    setObjectsArray(newObjectsArray);
  };

  useEffect(() => {
    handleChange(inputValue);
  }, [inputValue]);

  useEffect(() => {
    onObjectsArrayChange(objectsArray);
  }, [objectsArray, onObjectsArrayChange]);

  const handleParameterValChange = (event, id, newValue) => {
    setObjectsArray(
      // New Method
      objectsArray.map((item) =>
        item.id === id ? { ...item, parameterVal: newValue.label } : item
      )

      // old method
      // objectsArray.map((item) => {
      //   if (item.id === id) {
      //     return { ...item, parameterVal: newValue };
      //   } else {
      //     return item;
      //   }
      // })
    );
  };

  const TheadingData = [
    { label: "name", id: 1 },
    { label: "mobile_Number", id: 2 },
  ];

  return (
    <>
      <TextComponent
        heading="Parameters"
        // body="You can personalize messages with - $FirstName, $Name, $MobileNumber etc."
        body1="You can personalize messages with - $FirstName, $Name, $MobileNumber etc."
      />
      {objectsArray.length > 0 && (
        <div className="mt-5">
          {objectsArray.map((item) => (
            <div className="flex justify-between items-center" key={item.id}>
              <div className="text-lg w-1/6 font-normal mt-6 pe-3">{`{{${item.id}}}`}</div>
              {/* <input
                value={item.parameterVal}
                onChange={(event) => {
                  handleParameterValChange(event, item.id);
                }}
                type="text"
                placeholder="Enter parameter value"
                className="input input-xs input-bordered w-5/6 h-auto p-2 bg-[#F0F0F0] text-black mt-2 placeholder:font-medium font-normal"
              /> */}

              <div className="w-5/6">
                <Autocomplete
                  disablePortal
                  id="TemplateLanguage"
                  options={TheadingData}
                  getOptionLabel={(option) => option.label}
                  sx={{
                    width: "100%",
                    marginTop: "30px",
                    background: "#F0F0F0",
                    height: "35px",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .css-z8b3ht-MuiFormLabel-root-MuiInputLabel-root": {
                      // display: "none",
                    },
                    "& .css-8ujita-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                      {
                        padding: "0px !important",
                      },
                    "& .MuiInputLabel-root": {
                      color: "#A9A9A9",
                      fontSize: "medium",
                      marginTop: "-8px !important",
                    },
                    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                      padding: "0px !important",
                    },
                  }}
                  onChange={(event, newValue) => {
                    handleParameterValChange(event, item.id, newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose Tag" />
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {/* <button className="btn btn-success" onClick={handleChange}>
        Click
      </button> */}
    </>
  );
}
