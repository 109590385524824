// import React from "react";
// import {
//   getBezierPath,
//   EdgeLabelRenderer,
//   BaseEdge,
//   useReactFlow,
// } from "reactflow";
// import { IoIosClose } from "react-icons/io";

// const CustomEdge = ({
//   id,
//   sourceX,
//   sourceY,
//   targetX,
//   targetY,
//   sourcePosition,
//   targetPosition,
//   data,
// }) => {
//   const [edgePath, labelX, labelY] = getBezierPath({
//     sourceX,
//     sourceY,
//     sourcePosition,
//     targetX,
//     targetY,
//     targetPosition,
//   });

//   const { deleteElements } = useReactFlow();

//   const onClick = useCallback(() => {
//     deleteElements({ edges: [{ id }] });
//   }, [id, deleteElements]);

//   return (
//     <>
//       <BaseEdge id={id} path={edgePath} />
//       <EdgeLabelRenderer>
//         <button
//           style={{
//             position: "absolute",
//             transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
//           }}
//           className="nodrag nopan bg-gray-400 bg-opacity-[0.5] rounded-full text-lg"
//         >
//           <IoIosClose />
//         </button>
//       </EdgeLabelRenderer>
//     </>
//   );
// };

// export default CustomEdge;

import { IoIosClose } from "react-icons/io";
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useReactFlow,
} from "reactflow";

export default function CustomEdge({ id, sourceX, sourceY, targetX, targetY }) {
  const { setEdges } = useReactFlow();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <button
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all",
          }}
          className="nodrag nopan bg-gray-400 bg-opacity-[0.5] rounded-full text-lg"
          onClick={() => {
            setEdges((es) => es.filter((e) => e.id !== id));
          }}
        >
          <IoIosClose />
        </button>
      </EdgeLabelRenderer>
    </>
  );
}
