// Define each tab's content component

import {
  Container,
  Grid,
  Tabs,
  Tab,
  Typography,
  Button,
  Box,
  TextField,
  Link,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { BiStore } from "react-icons/bi";
import { MdOutlineMessage, MdProductionQuantityLimits } from "react-icons/md";
import { FaShopify } from "react-icons/fa";
import { GiConfirmed } from "react-icons/gi";
import { useEffect, useState } from "react";
import {
  useConnectCatelog,
  useDisCatelog,
  useGetCatelog,
} from "../../../hooks/integrationHooks/FaceBookCatLog/useFaceBookCatLog";

function CreateCatalog() {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Create Catalog
      </Typography>
      <Typography variant="body1">
        Select your preferred option to setup catalog.
      </Typography>
      <Button
        variant="contained"
        color="primary" // Using default 'primary' color directly
        startIcon={<OpenInNewIcon />}
        sx={{ marginTop: 2 }}
        href="https://business.facebook.com/commerce"
        target="_blank"
      >
        Go to Meta
      </Button>
      <Button
        variant="contained"
        color="success" // Using default 'success' color directly
        startIcon={<OpenInNewIcon />}
        sx={{ marginLeft: 2, marginTop: 2 }}
        href="https://admin.shopify.com/"
        target="_blank"
      >
        Go to Shopify
      </Button>
    </Box>
  );
}

function ConnectToWhatsApp() {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Connect Catalog To Whatsapp Account
      </Typography>
      <Typography variant="body1" gutterBottom>
        Follow the steps in{" "}
        <Link
          href="https://www.youtube.com/watch?v=s8dczFPhstA"
          target="_blank"
          underline="hover"
          sx={{ display: "inline-flex", alignItems: "center" }}
          color="blue"
        >
          <PlayCircleOutlineIcon sx={{ marginRight: 0.5 }} /> Video
        </Link>{" "}
        on how to connect catalog to WhatsApp account.
      </Typography>
      <Button
        variant="contained"
        color="primary" // Using default 'primary' color directly
        startIcon={<OpenInNewIcon />}
        sx={{ marginTop: 2 }}
        href="https://business.facebook.com/settings?business_id"
        target="_blank"
      >
        Go to Meta
      </Button>
    </Box>
  );
}

function AssignPartnerAccess() {
  const businessManagerId = "312724624814812"; // Placeholder ID

  // Function to copy Business Manager ID to clipboard
  const handleCopyClick = () => {
    navigator.clipboard.writeText(businessManagerId);
    alert("Business Manager ID copied to clipboard!");
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Assign THEBOTMODE as Catalog Partner
      </Typography>
      <Typography variant="body1" gutterBottom>
        Follow steps in{" "}
        <Link
          href="https://www.youtube.com/watch?v=bJBPEri1Kk0"
          target="_blank"
          color="blue"
          underline="hover"
          sx={{ display: "inline-flex", alignItems: "center" }}
        >
          <PlayCircleOutlineIcon sx={{ marginRight: 0.5 }} /> Video
        </Link>{" "}
        to assign THEBOTMODE as catalog partner
      </Typography>

      <Typography variant="body1" sx={{ marginTop: 2 }}>
        THEBOTMODE Business Manager ID: <strong>{businessManagerId}</strong>{" "}
        <Button
          variant="text"
          color="primary"
          startIcon={<ContentCopyIcon />}
          onClick={handleCopyClick}
          sx={{ textTransform: "none" }}
        >
          Copy
        </Button>
      </Typography>

      <Button
        variant="contained"
        color="primary"
        startIcon={<OpenInNewIcon />}
        sx={{ marginTop: 2 }}
        href="https://business.facebook.com/settings?business_id"
        target="_blank"
      >
        Go to Meta
      </Button>
    </Box>
  );
}

// ConnectCatalog component
function ConnectCatalog() {
  const { conLoad, catalogData, connectCatalog } = useConnectCatelog();
  const { disLoad, disCatalog, catalogData: catalogData1 } = useDisCatelog();
  const { getLoad, getcatalogData, getCatalog } = useGetCatelog();
  const [catalogId, setCatalogId] = useState(getcatalogData?.catalog_Id); // Pre-filled catalog ID
  const [isConnected, setIsConnected] = useState(
    getcatalogData?.IsCatalogConnected
  );

  const handleDisconnect = async () => {
    if (isConnected) {
      await disCatalog(catalogId);
      await getCatalog();
      // if (catalogData1) {
      //   setIsConnected((pre) => !pre);
      // }
      setIsConnected((pre) => !pre);

      return;
    } else {
      await connectCatalog(catalogId);
      await getCatalog();
      // if (catalogData) {
      //   setIsConnected((pre) => !pre);
      // }
      setIsConnected((pre) => !pre);
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Enter Facebook Catalog ID
      </Typography>
      <Typography variant="body1" gutterBottom>
        Copy Catalog ID from Business Settings page and paste in the field below
        and hit the Connect button.
      </Typography>

      {/* Catalog ID Input Field */}
      <TextField
        label="Catalog ID"
        value={catalogId}
        fullWidth
        InputProps={{
          readOnly: isConnected,
        }}
        onChange={(e) => {
          setCatalogId(e.target.value);
        }}
        sx={{ marginBottom: 2 }}
      />

      {/* Disconnect Button */}
      <Button
        variant="contained"
        color={isConnected ? "error" : "info"}
        onClick={handleDisconnect}
        sx={{ marginBottom: 2 }}
        // disabled={conLoad || disLoad}
      >
        {isConnected ? "Disconnect" : "Connect"}
        {(conLoad || disLoad) && <span className="loading loading-dots"></span>}
      </Button>

      {/* Success Message */}
      {isConnected && (
        <>
          <Typography variant="body2" color="success.main">
            Congrats! Your Catalog has been connected to THEBOTMODE
          </Typography>

          <Box sx={{ marginTop: 3 }}>
            <Typography variant="body1" gutterBottom>
              Visit your connected Catalog
            </Typography>

            <Button variant="outlined" color="success">
              {/* <Link to="/template/create">Create Template</Link> */}
              <a
                href="https://wa.me/c/919649379267"
                target="_blank"
                rel="noopener noreferrer"
              >
                view catalog
              </a>
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

// function ConnectCatalog() {
//   const { conLoad, catalogData, connectCatalog } = useConnectCatelog();
//   const { disLoad, disCatalog } = useDisCatelog();
//   const { getLoad, getcatalogData, getCatalog } = useGetCatelog();

//   const [catalogId, setCatalogId] = useState("");
//   const [isConnected, setIsConnected] = useState(false);

//   useEffect(() => {
//     setCatalogId(getcatalogData?.catalog_Id || "");
//     setIsConnected(!!getcatalogData?.IsCatalogConnected);
//   }, [getcatalogData]);

//   const handleDisconnect = async () => {
//     try {
//       if (isConnected) {
//         await disCatalog(catalogId);
//       } else {
//         await connectCatalog(catalogId);
//       }
//       await getCatalog(); // Refresh data
//     } catch (error) {
//       console.error("Error updating catalog connection:", error);
//     }
//   };

//   return (
//     <Box>
//       <Typography variant="h5" gutterBottom>
//         Enter Facebook Catalog ID
//       </Typography>
//       <Typography variant="body1" gutterBottom>
//         Copy Catalog ID from Business Settings page and paste in the field below
//         and hit the Connect button.
//       </Typography>

//       {/* Catalog ID Input Field */}
//       <TextField
//         label="Catalog ID"
//         value={catalogId}
//         fullWidth
//         InputProps={{ readOnly: isConnected }}
//         onChange={(e) => setCatalogId(e.target.value)}
//         error={!catalogId.trim()}
//         helperText={!catalogId.trim() && "Catalog ID cannot be empty"}
//         sx={{ marginBottom: 2 }}
//       />

//       {/* Connect/Disconnect Button */}
//       <Button
//         variant="contained"
//         color={isConnected ? "error" : "info"}
//         onClick={handleDisconnect}
//         disabled={conLoad || disLoad || !catalogId.trim()}
//         sx={{ marginBottom: 2 }}
//       >
//         {isConnected ? "Disconnect" : "Connect"}
//         {(conLoad || disLoad) && <span className="loading loading-dots"></span>}
//       </Button>

//       {/* Success Message */}
//       {isConnected && (
//         <>
//           <Typography variant="body2" color="success.main">
//             Congrats! Your Catalog has been connected to THEBOTMODE
//           </Typography>
//           <Box sx={{ marginTop: 3 }}>
//             <Typography variant="body1" gutterBottom>
//               Visit your connected Catalog
//             </Typography>
//             <Button variant="outlined" color="success">
//               <a
//                 href="https://wa.me/c/919649379267"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 View Catalog
//               </a>
//             </Button>
//           </Box>
//         </>
//       )}
//     </Box>
//   );
// }

// Main SetupCatalog Component
export function SetupCatalog() {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      <Grid container spacing={4}>
        {/* Tab Bar */}
        <Grid item xs={12}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Create Catalog" />
            <Tab label="Connect to WhatsApp" />
            <Tab label="Assign Partner Access" />
            <Tab label="Connect Catalog" />
          </Tabs>
        </Grid>

        {/* Tab Content */}
        <Grid item xs={12}>
          {tabIndex === 0 && <CreateCatalog />}
          {tabIndex === 1 && <ConnectToWhatsApp />}
          {tabIndex === 2 && <AssignPartnerAccess />}
          {tabIndex === 3 && <ConnectCatalog />}{" "}
          {/* New Tab for Connect Catalog */}
        </Grid>

        {/* Back and Next Buttons */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 3,
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => setTabIndex((prev) => Math.max(prev - 1, 0))}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => setTabIndex((prev) => Math.min(prev + 1, 3))}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
