
import { create } from "zustand";

const useAllTags = create((set) => ({
    loadingAllTags: false,
    setloadingAllTags: (loadingAllTags) => set({ loadingAllTags }),
  
    AllListTags: [],
    setAllListTags: (AllListTags) => set({ AllListTags }),
  }));
  
  export default useAllTags;