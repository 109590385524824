import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { SiRazorpay } from "react-icons/si";
import { Link } from "react-router-dom";
import useStoreDetails from "../../zustand/sopify/useMetaUserDetails";
import { FaFacebook } from "react-icons/fa6";
// import { useGetStoreDetails } from "../../../hooks/integrationHooks/useShopify";
import { useGetStoreDetails } from "../../hooks/integrationHooks/useShopify";

function AppStore() {
  const { storeLoading, storeDetails } = useStoreDetails();
  useGetStoreDetails();

  const [currentStatus, setCurrentStatus] = useState("Not Connected");

  useEffect(() => {
    if (storeLoading) {
      setCurrentStatus("loading...");
    } else if (storeDetails.IsConneected === true) {
      setCurrentStatus("Connected");
    } else {
      setCurrentStatus("Not Connected");
    }
  }, [storeLoading, storeDetails]);

  console.log("storeDetails1:", storeDetails);
  console.log("current status looks likes this :", storeDetails.IsConneected);

  const StoreList = [
    {
      id: "1",
      link: "shopify/storeSetup",
      image: "https://logosandtypes.com/wp-content/uploads/2020/11/shopify.svg",
      Title: "Shopify",
      Status: "Status",
      CurrentStatus: currentStatus,
      paragraph1:
        "Provide live chat support to your customers & boost cart recovery",
    },
    {
      id: "2-woocommerce", // Ensure unique `id` here
      link: "woocommerce",
      image: "/assets/woocommerce_icon.png",
      Title: "WooCommerce",
      Status: "Status",
      CurrentStatus: "Not Connected",
      paragraph1:
        "Boost your cart recovery & reengage with your customers to upsell",
    },
    {
      id: "3-razorpay", // Ensure unique `id` here
      link: "razorpay",
      icon: <SiRazorpay className="text-6xl text-blue-600 gap-7 ml-4" />,
      Title: "Razorpay",
      Status: "Status",
      CurrentStatus: "Not Connected",
      paragraph1:
        "Send payment links & subscription updates to drive quick payments",
    },
    {
      id: "Catalog", // Ensure unique `id` here
      link: "SetupCatalog",
      icon: <FaFacebook className="text-6xl text-blue-600 gap-7 ml-4" />,
      Title: "Catalog",
      Status: "Status",
      CurrentStatus: "Not Connected",
      paragraph1:
        "Provide live chat support to your customers & boost cart recovery",
    },
  ];

  return (
    <>
      <div className="w-full text-xl p-5 shadow font-medium bg-white">
        <h2>App Store</h2>
        <h6 className="mt-2 text-sm font-normal">
          Our integrations make it quick and easy to get the most out of
          Delighted - from automating sending to customers, to following up
          within your help desk, or sharing feedback across your team.
        </h6>
      </div>

      <div className="p-10 flex justify-center flex-wrap gap-5 gap-y-5">
        {StoreList.map((item) => (
          <AppStoreCard
            key={item.id}
            image={item.image}
            icon={item.icon}
            link={item.link}
            Title={item.Title}
            Status={item.Status}
            CurrentStatus={item.CurrentStatus}
            paragraph1={item.paragraph1}
          />
        ))}
      </div>
    </>
  );
}

export default AppStore;

function AppStoreCard({
  image,
  icon,
  link,
  Title,
  Status,
  CurrentStatus,
  paragraph1,
}) {
  return (
    <>
      {CurrentStatus === "Connected" ? (
        <div>
          <Link to={`/integration/appstore/${link}`}>
            <div className="card p-5 card-compact w-96 bg-white shadow rounded-md">
              <figure className="w-16 mb-0">
                {image ? <img src={image} alt={Title} /> : icon}
              </figure>
              <div className="card-body font-medium -ms-2">
                <h2 className="card-title">{Title}</h2>
                <p className="-mb-2">{Status}</p>
               
                <p className=" text-base text-green-600 "><b>{CurrentStatus}</b></p>
                <p className="text-[#6E6E6E]">{paragraph1}</p>
                {/* <div className="card-actions justify-end"> */}
                {/* <Link className="w-full" to={`/integration/appstore/${link}`}>
            <button className="btn btn-sm w-full mt-2 -mb-2 rounded-md text-white border-none bg-[#0A474C] hover:bg-[#073135]">
              Store Setup <IoIosArrowForward className="text-lg" />
            </button>
          </Link> */}

              
                  {/* <Link className="w-full" to={`/integration/appstore/${link}`}> */}
                    {/* <button className="btn btn-sm w-full mt-2 -mb-2 rounded-md text-white border-none bg-[#0A474C] hover:bg-[#073135]">
              Store Setup <IoIosArrowForward className="text-lg" />
            </button> */}
                    <div className="btn btn-sm w-full mt-2 -mb-2 rounded-md text-white border-none bg-[#0A474C] hover:bg-[#073135]">
                      Go to setting
                    </div>
                  {/* </Link> */}
                
                {/* </div> */}
              </div>
            </div>
            {/* // <div className="card-actions justify-end">installed....</div> */}
          </Link>
        </div>
      ) : (
        <div className="card p-5 card-compact w-96 bg-white shadow rounded-md">
          <figure className="w-16 mb-0">
            {image ? <img src={image} alt={Title} /> : icon}
          </figure>
          <div className="card-body font-medium -ms-2">
            <h2 className="card-title">{Title}</h2>
            <p className="-mb-2">{Status}</p>
            <p className="text-[#808080] text-base">{CurrentStatus}</p>
            <p className="text-[#6E6E6E]">{paragraph1}</p>
            {/* <div className="card-actions justify-end"> */}
            {/* <Link className="w-full" to={`/integration/appstore/${link}`}>
            <button className="btn btn-sm w-full mt-2 -mb-2 rounded-md text-white border-none bg-[#0A474C] hover:bg-[#073135]">
              Store Setup <IoIosArrowForward className="text-lg" />
            </button>
          </Link> */}

             
              <div className="card-actions justify-end">
                <Link className="w-full" to={`/integration/appstore/${link}`}>
                  <button className="btn btn-sm w-full mt-2 -mb-2 rounded-md text-white border-none bg-[#0A474C] hover:bg-[#073135]">
                    Store Setup <IoIosArrowForward className="text-lg" />
                  </button>
                </Link>
              </div>
            
            {/* </div> */}
          </div>
        </div>
      )}
    </>
  );
}
