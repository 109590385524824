// import { createContext, useContext, useEffect, useState } from "react";
// import { io } from "socket.io-client";
// import { UseAuthContext } from "./AuthContext";

// export const SocketContext = createContext();

// export const useSocketContext = () => {
//   return useContext(SocketContext);
// };

// export const SocketContextProvider = ({ children }) => {
//   const [socket, setSocket] = useState(null);
//   const { authUser } = UseAuthContext();

//   useEffect(() => {
//     if (authUser) {
//       const socket = io("http://localhost:5000");

//       setSocket(socket);

//       return socket.close();
//     } else {
//       if (socket) {
//         socket.close();
//         setSocket(null);
//       }
//     }
//   }, [authUser, socket]);

//   return (
//     <SocketContext.Provider value={{ socket }}>
//       {children}
//     </SocketContext.Provider>
//   );
// };

import { createContext, useState, useEffect, useContext } from "react";
// import { useAuthContext } from "./AuthContext";
import { UseAuthContext } from "./AuthContext";

import io from "socket.io-client";

const SocketContext = createContext();

export const useSocketContext = () => {
  return useContext(SocketContext);
};

export const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const { authUser } = UseAuthContext();

  const DOMAIN_WHATSAPP_API_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:5000"
    : "https://app.thebotmode.com";

    
  useEffect(() => {
    if (authUser) {
      // Initialize the socket connection when authUser is available
      const newSocket = io(DOMAIN_WHATSAPP_API_URL, {
        query: {
          userId: authUser._id,
        },
      });

      setSocket(newSocket);

      // Handle online users update
      newSocket.on("getOnlineUsers", (users) => {
        setOnlineUsers(users);
      });

      // Handle socket disconnection
      newSocket.on("disconnect", () => {
        console.log("Socket disconnected");
      });

      // Cleanup function to close the socket connection
      return () => {
        newSocket.close();
        setSocket(null);
      };
    } else {
      // Close socket if user logs out
      if (socket) {
        socket.close();
        setSocket(null);
      }
    }
  }, [authUser]); // Depend on authUser for re-initialization

  return (
    <SocketContext.Provider value={{ socket, onlineUsers }}>
      {children}
    </SocketContext.Provider>
  );
};
