// import React, { useState } from "react";
// import QuickGuilde from "../../../../components/QuickGuilde";
// import {
//   useDisconnectStore,
//   useGetStoreDetails,
//   useShopifysetup,
//   useShopifyStoreConnection,
// } from "../../../../hooks/integrationHooks/useShopify";
// import toast from "react-hot-toast";
// import useStoreDetails from "../../../../zustand/sopify/useMetaUserDetails";
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

// const StoreSetupSPF = () => {
//   const { storeLoading, storeDetails } = useStoreDetails();
//   // useGetStoreDetails()
//   const { Disconnectloading, disconnectStore } = useDisconnectStore();

//   console.log(storeLoading, storeDetails);

//   const quickReplyObj = {
//     text1: "Easily configure Ecomm+ to automate your shopify messages.",
//     text2:
//       "Automatic Abandoned Cart Reminders, Order Notifications, COD recovery on whatsapp.",
//     link1: "How to get Access Token and API Secret Key",
//     link2: "How to setup automated shopify messages",
//   };

//   const [isShopurl, setshopurl] = useState("");
//   const [isShoptoken, setshopifytoken] = useState("");
//   const [isShopkey, setShopkey] = useState("");
//   const [isstoredomain, setstoredomain] = useState("");
//   // const { isloader, handleshopifystore } = useShopifyStoreConnection();

//   const { isloading: isloading, handlestoresetup } = useShopifysetup();

//   console.log("shop url looks likes this :", isShopurl);
//   console.log("shop url looks likes this :", isShoptoken);
//   console.log("shop url looks likes this :", isShopkey);

//   const handlesubmitShopifyData = async () => {
//     const shopidata = {
//       storeUrl: isShopurl,
//       apiToken: isShoptoken,
//       apisecreateKey: isShopkey,
//     };

//     // const requiredFields = ["storeUrl", "apiToken", "apisecreateKey"];
//     const requiredFields = ["storeUrl"];

//     for (const field of requiredFields) {
//       if (!shopidata[field]) {
//         toast.error(`${field} is required`);
//         return;
//       }
//     }

//     await handlestoresetup(shopidata);
//   };

//   const handleDisconnect = async () => {
//     if (Disconnectloading) {
//       toast.error("Your request is being processed!");
//       return;
//     }

//     const shopId = storeDetails?.shopData._id;
//     const templateIDsandNames = [];

//     if (
//       storeDetails &&
//       storeDetails.shopData &&
//       Array.isArray(storeDetails.shopData.templates)
//     ) {
//       for (const item of storeDetails.shopData.templates) {
//         console.log(item);
//         templateIDsandNames.push({ id: item.id, name: item.configData.name });
//       }
//     }

//     await disconnectStore(shopId, templateIDsandNames);
//     console.log(templateIDsandNames, shopId);
//   };

//   return (
//     <>
//       {storeLoading ||
//         (isloading && (
//           <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
//             <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
//           </div>
//         ))}
//       <div className="w-[80%]">
//         <QuickGuilde data={quickReplyObj} />
//         {storeDetails?.IsConneected ? (
//           <div className="w-5/6 rounded-md shadow-sm mt-10 mx-auto bg-white py-6 px-8 flex justify-between items-center h-28">
//             <div>
//               <div className="text-[#0A474C] font-medium text-lg">
//                 Store Is Connected
//                 <span className="ms-1">
//                   <CheckCircleOutlineIcon className="mb-0.5 text-green-500 font-extrabold" />
//                 </span>
//               </div>
//               <div className="text-black mt-1 font-medium text-sm">
//                 {storeDetails.shopData?.shopUrl}
//               </div>
//             </div>

//             <div className="flex gap-4">
//               <a
//                 className="btn w-24 bg-[#0A474C] text-white border-none rounded-md btn-sm"
//                 href={`https://${storeDetails.shopData?.shopUrl}`}
//                 target="_blank"
//               >
//                 Visit Store
//               </a>

//               <button
//                 className="btn w-24  btn-error text-white border-none rounded-md btn-sm"
//                 onClick={handleDisconnect}
//               >
//                 {Disconnectloading ? (
//                   <span className="loading loading-dots"></span>
//                 ) : (
//                   "Disconnect"
//                 )}
//               </button>
//             </div>
//           </div>
//         ) : (
//           <div className="w-5/6 rounded-md shadow-sm mt-10 mx-auto bg-white py-6 px-8">
//             <div>
//               <div className="text-[#0A474C] font-medium text-lg">
//                 Shopify App
//               </div>
//               <div className="text-[#6E6E6E] font-medium text-xs">
//                 Enter your shopily domain to log in or install this app install
//               </div>
//               <div className="mt-2">
//                 <input
//                   type="text"
//                   className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
//                   placeholder="example.myshopify.com"
//                   onChange={(e) => {
//                     setshopurl(e.currentTarget.value);
//                   }}
//                   value={isShopurl}
//                 />
//               </div>
//             </div>

//             {/* <div className="mt-5">
//               <div className="text-[#0A474C] font-medium text-lg">
//                 Access Token
//               </div>
//               <div className="text-[#6E6E6E] font-medium text-xs">
//                 Copy Access Token from Shopify store
//               </div>
//               <div className="mt-2">
//                 <input
//                   type="text"
//                   className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
//                   placeholder="Access Token"
//                   onChange={(e) => setshopifytoken(e.currentTarget.value)}
//                 />
//               </div>
//             </div> */}

//             {/* <div className="mt-5">
//               <div className="text-[#0A474C] font-medium text-lg">
//                 API Secret Key
//               </div>
//               <div className="text-[#6E6E6E] font-medium text-xs">
//                 Copy API Secret key from Shopify store
//               </div>
//               <div className="mt-2">
//                 <input
//                   type="text"
//                   className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
//                   placeholder="Api Secret Key"
//                   onChange={(e) => {
//                     setShopkey(e.currentTarget.value);
//                   }}
//                 />
//               </div>
//             </div>      */}

//             {/* <div className="mt-5">
//             <input
//                   type="text"
//                   className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
//                   placeholder="stoore domain"
//                   onChange={(e) => {
//                     setstoredomain(e.currentTarget.value);
//                   }}
//                 />
//             </div> */}
//             <button
//               className="mt-5 btn w-24 bg-[#0A474C] text-white border-none rounded-md btn-sm"
//               onClick={handlesubmitShopifyData}
//               disabled={isloading}
//             >
//               Install
//             </button>
//           </div>
//         )}

//         <div className="w-5/6 rounded-md shadow-sm mt-10 mx-auto mb-10 bg-white py-6 px-8">
//           <div>
//             <div className="text-[#0A474C] font-medium text-lg">
//               About Shopify
//             </div>
//             <div className="text-[#6E6E6E] font-medium text-sm mt-2">
//               Shopify is an eCommerce platform, enabling businesses to create a
//               customized gateway to their business, including website, payment,
//               inventory, and shipping management tools.
//             </div>
//           </div>

//           <div className="mt-5">
//             <div className="text-[#0A474C] font-medium text-lg">
//               THEBOTMODE - Shopify
//             </div>
//             <div className="text-[#6E6E6E] font-medium text-sm mt-2">
//               Connecting Shopify with THEBOTMODE will help you send the
//               following automated notifications to your customers WhatsApp. Some
//               simple automation you can send:
//             </div>
//             <div className="text-[#6E6E6E] font-medium text-sm mt-2">
//               <ul className="ulStyle ms-5">
//                 <li>Abandoned Cart Recovery Notifications</li>
//                 <li>
//                   Payment Updates like Payment Failure, Payment Pending or
//                   Payment completed
//                 </li>
//                 <li>
//                   Order Confirmation notifications like Order Completed or order
//                   cancelled
//                 </li>
//                 <li>Customers order id with delivery updates</li>
//               </ul>
//             </div>
//             <div className="text-[#6E6E6E] font-medium text-sm mt-2">
//               Alongside you can solve customer support queries via Live Chat or
//               Chatbots on Whatsapp for your E-Commerce Store.
//             </div>
//           </div>

//           <div className="mt-5">
//             <div className="text-[#0A474C] font-medium text-lg">
//               Boost Your Sales
//             </div>
//             <div className="text-[#6E6E6E] font-medium text-sm mt-2">
//               Abandoned Cart Recovery notifications recover 45-60% of Abandoned
//               Carts in No Time! Boosting your sales instantly.
//             </div>
//           </div>

//           <div className="mt-5">
//             <div className="text-[#0A474C] font-medium text-lg">
//               Broadcast Notifications at Scale
//             </div>
//             <div className="text-[#6E6E6E] font-medium text-sm mt-2">
//               Broadcast Notifications to your customers and users about product
//               updates, feedback and more
//             </div>
//           </div>

//           <div className="mt-5">
//             <div className="text-[#0A474C] font-medium text-lg">
//               Solve for Customer Support
//             </div>
//             <div className="text-[#6E6E6E] font-medium text-sm mt-2">
//               Get Multiple Human Live Chat Support on Whatsapp and Solve for
//               Customer Support on Whatsapp.
//             </div>
//           </div>
//         </div>
//       </div>
//       )
//     </>
//   );
// };

// export default StoreSetupSPF;


import React, { useState } from "react";
import QuickGuilde from "../../../../components/QuickGuilde";
import {
  useDisconnectStore,
  useGetStoreDetails,
  useShopifysetup,
  useShopifyStoreConnection,
} from "../../../../hooks/integrationHooks/useShopify";
import toast from "react-hot-toast";
import useStoreDetails from "../../../../zustand/sopify/useMetaUserDetails";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const StoreSetupSPF = () => {
  const { storeLoading, storeDetails } = useStoreDetails();
  // useGetStoreDetails()
  const { Disconnectloading, disconnectStore } = useDisconnectStore();

  console.log(storeLoading, storeDetails);

  const quickReplyObj = {
    text1: "Easily configure Ecomm+ to automate your shopify messages.",
    text2:
      "Automatic Abandoned Cart Reminders, Order Notifications, COD recovery on whatsapp.",
    link1: "How to get Access Token and API Secret Key",
    link2: "How to setup automated shopify messages",
  };

  const [isShopurl, setshopurl] = useState("");
  const [isShoptoken, setshopifytoken] = useState("");
  const [isShopkey, setShopkey] = useState("");
  const [isstoredomain, setstoredomain] = useState("");
  const { isloader:isloading, handleshopifystore } = useShopifyStoreConnection();

  // const { isloading: isloading, handlestoresetup } = useShopifysetup();

  console.log("shop url looks likes this :", isShopurl);
  console.log("shop url looks likes this :", isShoptoken);
  console.log("shop url looks likes this :", isShopkey);

  const handlesubmitShopifyData = async () => {
    const shopidata = {
      storeUrl: isShopurl,
      apiToken: isShoptoken,
      apisecreateKey: isShopkey,
    };

    const requiredFields = ["storeUrl", "apiToken", "apisecreateKey"];
    // const requiredFields = ["storeUrl"];

    for (const field of requiredFields) {
      if (!shopidata[field]) {
        toast.error(`${field} is required`);
        return;
      }
    }

    await handleshopifystore(shopidata);
  };

  const handleDisconnect = async () => {
    if (Disconnectloading) {
      toast.error("Your request is being processed!");
      return;
    }

    const shopId = storeDetails?.shopData._id;
    const templateIDsandNames = [];

    if (
      storeDetails &&
      storeDetails.shopData &&
      Array.isArray(storeDetails.shopData.templates)
    ) {
      for (const item of storeDetails.shopData.templates) {
        console.log(item);
        templateIDsandNames.push({ id: item.id, name: item.configData.name });
      }
    }

    await disconnectStore(shopId, templateIDsandNames);
    console.log(templateIDsandNames, shopId);
  };

  return (
    <>
      {storeLoading ||
        (isloading && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
          </div>
        ))}
      <div className="w-[80%]">
        <QuickGuilde data={quickReplyObj} />
        {storeDetails?.IsConneected ? (
          <div className="w-5/6 rounded-md shadow-sm mt-10 mx-auto bg-white py-6 px-8 flex justify-between items-center h-28">
            <div>
              <div className="text-[#0A474C] font-medium text-lg">
                Store Is Connected
                <span className="ms-1">
                  <CheckCircleOutlineIcon className="mb-0.5 text-green-500 font-extrabold" />
                </span>
              </div>
              <div className="text-black mt-1 font-medium text-sm">
                {storeDetails.shopData?.shopUrl}
              </div>
            </div>

            <div className="flex gap-4">
              <a
                className="btn w-24 bg-[#0A474C] text-white border-none rounded-md btn-sm"
                href={`https://${storeDetails.shopData?.shopUrl}`}
                target="_blank"
              >
                Visit Store
              </a>

              <button
                className="btn w-24  btn-error text-white border-none rounded-md btn-sm"
                onClick={handleDisconnect}
              >
                {Disconnectloading ? (
                  <span className="loading loading-dots"></span>
                ) : (
                  "Disconnect"
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="w-5/6 rounded-md shadow-sm mt-10 mx-auto bg-white py-6 px-8">
            <div>
              <div className="text-[#0A474C] font-medium text-lg">
                Shopify App
              </div>
              <div className="text-[#6E6E6E] font-medium text-xs">
                Enter your shopily domain to log in or install this app install
              </div>
              <div className="mt-2">
                <input
                  type="text"
                  className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
                  placeholder="example.myshopify.com"
                  onChange={(e) => {
                    setshopurl(e.currentTarget.value);
                  }}
                  value={isShopurl}
                />
              </div>
            </div>

            <div className="mt-5">
              <div className="text-[#0A474C] font-medium text-lg">
                Access Token
              </div>
              <div className="text-[#6E6E6E] font-medium text-xs">
                Copy Access Token from Shopify store
              </div>
              <div className="mt-2">
                <input
                  type="text"
                  className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
                  placeholder="Access Token"
                  onChange={(e) => setshopifytoken(e.currentTarget.value)}
                />
              </div>
            </div>

            <div className="mt-5">
              <div className="text-[#0A474C] font-medium text-lg">
                API Secret Key
              </div>
              <div className="text-[#6E6E6E] font-medium text-xs">
                Copy API Secret key from Shopify store
              </div>
              <div className="mt-2">
                <input
                  type="text"
                  className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
                  placeholder="Api Secret Key"
                  onChange={(e) => {
                    setShopkey(e.currentTarget.value);
                  }}
                />
              </div>
            </div>     

            {/* <div className="mt-5">
            <input
                  type="text"
                  className="input input-sm w-3/6 border-none bg-[#F0F0F0] placeholder:text-xs placeholder:font-medium"
                  placeholder="stoore domain"
                  onChange={(e) => {
                    setstoredomain(e.currentTarget.value);
                  }}
                />
            </div> */}
            <button
              className="mt-5 btn w-24 bg-[#0A474C] text-white border-none rounded-md btn-sm"
              onClick={handlesubmitShopifyData}
              disabled={isloading}
            >
              Install
            </button>
          </div>
        )}

        <div className="w-5/6 rounded-md shadow-sm mt-10 mx-auto mb-10 bg-white py-6 px-8">
          <div>
            <div className="text-[#0A474C] font-medium text-lg">
              About Shopify
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Shopify is an eCommerce platform, enabling businesses to create a
              customized gateway to their business, including website, payment,
              inventory, and shipping management tools.
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              THEBOTMODE - Shopify
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Connecting Shopify with THEBOTMODE will help you send the
              following automated notifications to your customers WhatsApp. Some
              simple automation you can send:
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              <ul className="ulStyle ms-5">
                <li>Abandoned Cart Recovery Notifications</li>
                <li>
                  Payment Updates like Payment Failure, Payment Pending or
                  Payment completed
                </li>
                <li>
                  Order Confirmation notifications like Order Completed or order
                  cancelled
                </li>
                <li>Customers order id with delivery updates</li>
              </ul>
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Alongside you can solve customer support queries via Live Chat or
              Chatbots on Whatsapp for your E-Commerce Store.
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              Boost Your Sales
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Abandoned Cart Recovery notifications recover 45-60% of Abandoned
              Carts in No Time! Boosting your sales instantly.
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              Broadcast Notifications at Scale
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Broadcast Notifications to your customers and users about product
              updates, feedback and more
            </div>
          </div>

          <div className="mt-5">
            <div className="text-[#0A474C] font-medium text-lg">
              Solve for Customer Support
            </div>
            <div className="text-[#6E6E6E] font-medium text-sm mt-2">
              Get Multiple Human Live Chat Support on Whatsapp and Solve for
              Customer Support on Whatsapp.
            </div>
          </div>
        </div>
      </div>
      )
    </>
  );
};

export default StoreSetupSPF;
