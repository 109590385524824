import React, { useEffect, useState } from "react";
import GlobalHeader from "../../../components/GlobalComponets/GlobalHeader";
import DeleteIcon from "@mui/icons-material/Delete";

//icons for the navbar
import { IoSearch } from "react-icons/io5";
import { PiBellSimpleRingingFill } from "react-icons/pi";
import { Box, Drawer, IconButton } from "@mui/material";
import { IoClose } from "react-icons/io5"; // Import the close icon
// import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import { AiTwotoneApi } from "react-icons/ai";
import LocalAtmTwoToneIcon from "@mui/icons-material/LocalAtmTwoTone";
import AccountBalanceWalletTwoToneIcon from "@mui/icons-material/AccountBalanceWalletTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import { useDeleteTag, UsegetAlltags } from "../../../hooks/useTags";
import useAllTags from "../../../zustand/useAllTags.Zus";

// function ManageTag() {
//   const { getalltags } = UsegetAlltags();

//   const { AllListTags, loadingAllTags } = useAllTags();

//   console.log("AllListTags:", AllListTags);

//   // const formattedData = transformTagsToData(AllListTags);

//   const DetailsOfGlobalHeader = {
//     Title: "Tags",
//     Details: false,
//   };

//   const tableheader = [
//     { headname: "Tag Name", text: " text-[#32476A]" },
//     { headname: "Category", text: " text-[#32476A]" },
//     { headname: "Customer Journey", text: " text-[#32476A]" },
//     { headname: "First Message", text: " text-[#32476A]" },
//     { headname: "Created At", text: " text-[#32476A]" },
//     { headname: "Action", text: " text-[#32476A]" },
//   ];

//   const data = [
//     {
//       tag: "hot lead ",
//       time: "12/12/2024",
//       Category: "-",
//       FirstMessage: "-",
//       CustomerJourney: "-",
//     },
//     {
//       tag: "sales",
//       time: "14/12/2024",
//       Category: "-",
//       FirstMessage: "-",
//       CustomerJourney: "-",
//     },
//     {
//       tag: "shopify",
//       time: "15/12/2024",
//       Category: "-",
//       FirstMessage: "-",
//       CustomerJourney: "-",
//     },
//   ];

//   let randomcolor = [
//     { text: "text-blue-800", bg: "bg-blue-100" },
//     { text: "text-red-800", bg: "bg-red-100" },
//     { text: "text-green-800", bg: "bg-green-100" },
//     { text: "text-yellow-800", bg: "bg-yellow-100" },
//     { text: "text-purple-800", bg: "bg-purple-100" },
//     { text: "text-pink-800", bg: "bg-pink-100" },
//     { text: "text-teal-800", bg: "bg-teal-100" },
//     { text: "text-indigo-800", bg: "bg-indigo-100" },
//     { text: "text-gray-800", bg: "bg-gray-100" },
//     { text: "text-orange-800", bg: "bg-orange-100" },
//     { text: "text-cyan-800", bg: "bg-cyan-100" },
//     { text: "text-lime-800", bg: "bg-lime-100" },
//     { text: "text-rose-800", bg: "bg-rose-100" },
//     { text: "text-amber-800", bg: "bg-amber-100" },
//     { text: "text-violet-800", bg: "bg-violet-100" },
//   ];
//   console.log("random color looks likes this :", ...randomcolor);
//   //   const [istags,settags] = useState(data)

//   //   useEffect(()=>{
//   //     const randomIndex = Math.floor(Math.random() * randomcolor.length);
//   //     const selectedColor = randomcolor[randomIndex];
//   //     settags([...istags, {color: selectedColor }]);
//   //   },[istags])

//   const [istags, setTags] = useState();

//   //   useEffect(() => {
//   //     const initializedTags = data.map((item) => {
//   //       const randomIndex = Math.floor(Math.random() * randomcolor.length);
//   //       const selectedColor = randomcolor[randomIndex];
//   //       return {
//   //         ...item,
//   //         color: selectedColor,
//   //         element: (
//   //           <div
//   //             className={`max-h-8 flex gap-1 w-fit rounded-lg p-2 mt-1 items-center cursor-pointer ${selectedColor.bg} ${selectedColor.text}`}
//   //           >
//   //             {item.tag}
//   //           </div>
//   //         ),
//   //       };
//   //     });

//   //     setTags(initializedTags);
//   //   }, []);

//   const getRandomColor = () => {
//     const randomIndex = Math.floor(Math.random() * randomcolor.length);
//     return randomcolor[randomIndex];
//   };

//   useEffect(() => {
//     const initializedTags = data.map((item) => ({
//       ...item,
//       color: getRandomColor(),
//     }));
//     setTags(initializedTags);
//   }, []);
//   console.log("istags looks like this:", istags);

//   console.log("is tags looks lieks this :", istags);

//   return (
//     <>
//       <GlobalHeader item={DetailsOfGlobalHeader} />
//       {/* <NavBar /> */}
//       {!loadingAllTags && (
//         <div className="mx-auto flex items-center justify-center h-screen mb-5">
//           <Table tableheader={tableheader} tabledata={istags} />
//         </div>
//       )}
//     </>
//   );
// }

function ManageTag() {
  const { getalltags } = UsegetAlltags();
  const { AllListTags, loadingAllTags } = useAllTags();

  const DetailsOfGlobalHeader = {
    Title: "Tags",
    Details: false,
  };

  const tableheader = [
    { headname: "Tag Name", text: " text-[#32476A]" },
    { headname: "Category", text: " text-[#32476A]" },
    { headname: "Customer Journey", text: " text-[#32476A]" },
    { headname: "First Message", text: " text-[#32476A]" },
    { headname: "Created At", text: " text-[#32476A]" },
    { headname: "Action", text: " text-[#32476A]" },
  ];

  let randomcolor = [
    { text: "text-blue-800", bg: "bg-blue-100" },
    { text: "text-red-800", bg: "bg-red-100" },
    { text: "text-green-800", bg: "bg-green-100" },
    { text: "text-yellow-800", bg: "bg-yellow-100" },
    { text: "text-purple-800", bg: "bg-purple-100" },
    { text: "text-pink-800", bg: "bg-pink-100" },
    { text: "text-teal-800", bg: "bg-teal-100" },
    { text: "text-indigo-800", bg: "bg-indigo-100" },
    { text: "text-gray-800", bg: "bg-gray-100" },
    { text: "text-orange-800", bg: "bg-orange-100" },
    { text: "text-cyan-800", bg: "bg-cyan-100" },
    { text: "text-lime-800", bg: "bg-lime-100" },
    { text: "text-rose-800", bg: "bg-rose-100" },
    { text: "text-amber-800", bg: "bg-amber-100" },
    { text: "text-violet-800", bg: "bg-violet-100" },
  ];

  const [tags, setTags] = useState([
    {
      tag: "hot lead",
      time: "12/12/2024",
      Category: "-",
      FirstMessage: "-",
      CustomerJourney: "-",
      color: { text: "text-blue-800", bg: "bg-blue-100" },
    },
    {
      tag: "sales",
      time: "14/12/2024",
      Category: "-",
      FirstMessage: "-",
      CustomerJourney: "-",
      color: { text: "text-green-800", bg: "bg-green-100" },
    },
  ]);

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * randomcolor.length);
    return randomcolor[randomIndex];
  };

  const transformTagsToData = (tagsArray, createdAt) => {
    console.log("transformTagsToData:", tagsArray, createdAt);

    return tagsArray.map((tag) => ({
      tag: tag,
      time: new Date(createdAt).toLocaleDateString("en-US"), // Format date
      Category: "-", // Default
      FirstMessage: "-", // Default
      CustomerJourney: "-", // Default
      color: getRandomColor(), // Assign random color
    }));
  };

  useEffect(() => {
    if (!loadingAllTags && AllListTags[0]?.tags?.length > 0) {
      const formattedData = transformTagsToData(
        AllListTags[0].tags,
        AllListTags[0].createdAt
      );
      setTags(formattedData);
    }
  }, [loadingAllTags]);

  console.log("transformTagsToData 2:", AllListTags);
  console.log("transformTagsToData 3:", tags);

  const handleDeleteSuccess = (deletedTag) => {
    // Update local state to remove the deleted tag
    setTags(tags.filter((tag) => tag.tag !== deletedTag));
  };

  return (
    <>
      <GlobalHeader item={DetailsOfGlobalHeader} />
      {!loadingAllTags && (
        <div className="mx-auto flex items-center justify-center h-screen mb-5">
          <Table
            tableheader={tableheader}
            tabledata={tags}
            // getalltags={getalltags}
            onDeleteSuccess={handleDeleteSuccess}
          />
        </div>
      )}
    </>
  );
}

function Table({ tableheader, tabledata, getalltags, onDeleteSuccess }) {
  const { deleteTag, isDeleting } = useDeleteTag();

  const handleDelete = async (tag) => {
    const success = await deleteTag(tag);
    if (success) {
      // Call onDeleteSuccess if provided, to refresh or update the UI
      onDeleteSuccess && onDeleteSuccess(tag);
      // getalltags();
    }
  };

  return (
    <div>
      <thead className=" bg-gray-50 border rounded-md text-sm font-normal">
        <tr className="w-14">
          {tableheader.map((data, index) => (
            <th
              className=" py-4 text-sm font-normal ml-2 w-auto h-auto pl-7 pr-7"
              key={index}
            >
              <h5 className={data.text || "text-black"}>{data?.headname}</h5>
            </th>
          ))}
        </tr>
      </thead>

      <tbody className=" w-auto bg-gray-50 border text-sm font-normal gap-y-3 ">
        {tabledata?.map((data, index) => (
          <tr
            className="text-center h-16 w-auto mt-5 cursor-pointer"
            key={index}
          >
            <td
              className={`flex justify-center items-center mt-2 ${data.color.bg} ${data.color.text} max-h-8 flex gap-1 w-fit rounded-lg p-2 mt-1`}
            >
              {data.tag}
            </td>
            <td>{data.Category}</td>
            <td>{data.CustomerJourney}</td>
            <td>{data.FirstMessage}</td>
            <td>{data.time}</td>
            <td>
              <button
                onClick={() => handleDelete(data.tag)}
                disabled={isDeleting}
                className="text-center text-red-600 hover:text-red-800"
              >
                {!isDeleting?<DeleteIcon className="text-xl" />:<span className="loading loading-spinner text-xl"></span>}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </div>
  );
}

function NavBar() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 320 }} role="presentation">
      <div className="w-full flex  flex-col mt-10  h-screen bg-white rounded-lg shadow-md p-4 ">
        {/* Profile Section */}
        <div className="flex  w-full   h-44 flex-col items-center">
          {/* Avatar */}
          <div className="relative mb-4">
            <div className="w-24 h-24 rounded-full bg-gradient-to-r from-orange-400 to-green-400 p-1">
              <div className="w-full h-full rounded-full bg-white p-1">
                <div className="w-full h-full rounded-full bg-gray-200 flex items-center justify-center">
                  {/* Placeholder for Avatar */}
                  {/* <span className="text-xl text-gray-500">Avatar</span> */}
                  <img
                    src=" https://res.cloudinary.com/dvulhjjes/image/upload/v1731749079/profile%20images/djxylmp8mv56audk3lqa.png"
                    className="w-full h-full  object-cover"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Profile Info */}
          <div className="text-center">
            <p className="text-lg font-medium">zoro</p>
            <p className="text-sm text-gray-500 font-medium">
              zoro812@gmail.com
            </p>
          </div>
        </div>
        {/* <div class="border-b-gray-400 border-dotted border-gray-500 w-full"></div> */}
        <div class="border-t border-dashed border-gray-300 w-full"></div>

        {/* Navigation */}
        <div className="mt-5 space-y-3">
          <div className="w-full text-left px-4 py-2 text-gray-700 rounded-lg flex  gap-x-3 items-center  hover:bg-gray-200">
            {/* <AccountBoxIcon className=" text-gray-500 " fontSize="large" /> */}
            <AccountCircleTwoToneIcon
              className="text-gray-500"
              fontSize="large"
            />
            <div className="font-medium text-gray-500 hover:text-gray-700 text-sm ">
              Profile
            </div>
          </div>
          {/* <button className="w-full text-left px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-100">
            Projects
            <span className="ml-2 text-sm text-red-500">(3)</span>
          </button> */}
          {/* <button className="w-full text-left px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-100">
            Subscription
          </button> */}

          <div className="w-full text-left px-4 py-2 text-gray-700 rounded-lg flex  gap-x-3 items-center  hover:bg-gray-200">
            {/* <AccountBoxIcon className=" text-gray-500 " fontSize="large" /> */}
            <LocalAtmTwoToneIcon className="text-gray-500" fontSize="large" />
            <div className="font-medium text-gray-500 hover:text-gray-700 text-sm ">
              {" "}
              Subscription
            </div>
          </div>

          <div className="w-full text-left px-4 py-2 text-gray-700 rounded-lg flex  gap-x-3 items-center  hover:bg-gray-200">
            {/* <AccountBoxIcon className=" text-gray-500 " fontSize="large" /> */}
            <AiTwotoneApi className="text-gray-500 text-xl" />
            <div className="font-medium text-gray-500 hover:text-gray-700 text-sm ">
              {" "}
              Whatsapp business api status
            </div>
          </div>
          {/* <button className="w-full text-left px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-100">
            billing
          </button> */}
          <div className="w-full text-left px-4 py-2 text-gray-700 rounded-lg flex  gap-x-3 items-center  hover:bg-gray-200">
            {/* <AccountBoxIcon className=" text-gray-500 " fontSize="large" /> */}
            <AccountBalanceWalletTwoToneIcon
              className="text-gray-500 "
              fontSize="large"
            />
            <div className="font-medium text-gray-500 hover:text-gray-700 text-sm ">
              {" "}
              Billing
            </div>
          </div>
          {/* <button className="w-full text-left px-4 py-2 text-gray-700 rounded-lg hover:bg-gray-100">
            Subscription
          </button> */}
        </div>
      </div>

      <div className="mt-6 flex justify-center items-center bottom-3 fixed w-[310px] ">
        <button className="w-[97%] mr-2 ml-2 px-4 py-3 bg-red-100 text-red-700 text-sm  rounded-lg hover:bg-red-200">
          <span className="font-bold">Logout</span>
        </button>
      </div>
    </Box>
  );

  return (
    <>
      <div className="mx-auto flex items-center justify-between p-4 border-b h-20">
        {/* Left Content (Logo or Items) */}
        <div className="flex items-center space-x-4"></div>

        {/* Right Content (Icons or Profile) */}
        <div className="flex items-center space-x-4 h-14 p-2">
          {/* Add your icons or profile here */}
          <div className="w-16 h-8 p-2 bg-[#EDEFF2] flex  items-center justify-between hover:bg-gray-200 rounded-lg">
            <IoSearch className="text-lg text-[#637381]" />
            <div className=" bg-white h-5 w-6 p-1 rounded-md shadow-md items-center justify-evenly flex ">
              <span>K</span>
            </div>
          </div>
          <div className="h-9 w-9 rounded-full  bg-[#F7F7F7] hover:bg-gray-200   hover:w-[40px] hover:h-[40px]  relative flex items-center justify-center  transition-all duration-200 [ease: cubic-bezier(0.4, 0, 0.2, 1)] ">
            <PiBellSimpleRingingFill className="text-gray-400 text-lg  " />
          </div>

          <div className="relative">
            {/* Gradient border container */}
            <div
              className="w-9 h-9 rounded-full bg-gradient-to-r from-orange-400 to-green-400 p-0.5  shadow-md
             hover:w-[40px] hover:h-[40px] 
             transition-all duration-300 [ease: cubic-bezier(0.4, 0, 0.2, 1)] "
              onClick={toggleDrawer(true)}
            >
              {/* Inner avatar container */}
              <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
                {DrawerList}
              </Drawer>
              <div className="w-full h-full rounded-full bg-white p-0.5">
                {/* Your actual avatar content */}
                <span className="h-full w-full rounded-full bg-gray-200 relative flex items-center justify-center">
                  {/* If you want to add an image */}
                  {/* <img src="..." alt="avatar" className="h-full w-full rounded-full object-cover" /> */}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageTag;
