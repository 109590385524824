export const flowConfigs = {
  AbandonedCart: {
    abandonedCartSettings: {
      heading: "Abandoned Cart 1",
      subHeading:
        "This is the first message that is triggered if the user abandons your cart",
      CartSettings: false,
      timingOptions: ["10 Min", "20 Min", "30 Min"],
    },
    abandonedCartFollowUpSettings: {
      heading: "Abandoned Cart 2",
      subHeading:
        "This is the second message that is triggered if the user abandons your cart",
      CartSettings: false,
      timingOptions: ["3 Hours", "12 Hours", "1 Day"],
    },
    abandonedCartFollowUp1Settings: {
      heading: "Abandoned Cart 3",
      subHeading:
        "This is the third message that is triggered if the user abandons your cart",
      CartSettings: false,
      timingOptions: ["1 Day", "2 Day", "3 Day"],
    },
  },
  ConfirmationFlow: {
    codOrderConfirmationSettings: {
      heading: "COD Order Confirmation",
      subHeading:
        "This is the message that is triggered when the user makes a Cash on Delivery (COD) order",
      CartSettings: false,
      timingOptions: null,
    },
    codOrderFollowupSettings: {
      heading: "COD Order Follow Up",
      subHeading:
        "This is the message that is triggered when the user has not replied to the previous Cash on Delivery (COD) order message",
      CartSettings: false,
      timingOptions: ["1 Hours", "3 Hours", "5 Hours"],
    },
    codOrderConfirmStatusSettings: {
      heading: "COD Order Confirm Status",
      subHeading:
        "This is the message that is triggered when the user confirms your Cash on Delivery (COD) order",
      CartSettings: false,
      timingOptions: null,
    },
    codOrderCancelStatusSettings: {
      heading: "COD Order Cancel Status",
      subHeading:
        "This is the message that is triggered when the user cancels your Cash on Delivery (COD) order",
      CartSettings: false,
      timingOptions: null,
    },
  },
  OrderFlow: {
    orderConfirmedSettings: {
      heading: "Order Confirmed",
      subHeading:
        "This is the message that is triggered when the user makes a paid order",
      CartSettings: false,
      timingOptions: null,
    },
    orderCancelledSettings: {
      heading: "Order Cancelled",
      subHeading:
        "This is the message that is triggered if the user cancels your order",
      CartSettings: false,
      timingOptions: null,
    },
    orderFulfilledSettings: {
      heading: "Order Fulfilled",
      subHeading:
        "This is the message that is triggered when the order is shipped to the user",
      CartSettings: false,
      timingOptions: null,
    },
    reorderSettings: {
      heading: "Reorder",
      subHeading:
        "This is the message that is triggered to prompt user for reorder",
      CartSettings: false,
      timingOptions: ["7 Days", "15 Days", "21 Days", "30 Days", "45 Days"],
    },
    orderFeedbackSettings: {
      heading: "Order Feedback",
      subHeading: "This is the message that is triggered for user feedback",
      CartSettings: false,
      timingOptions: ["7 Days", "15 Days", "21 Days", "30 Days", "45 Days"],
    },
  },
};
