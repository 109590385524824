// import React, { useState } from "react";
// import { IoSearchSharp } from "react-icons/io5";
// import { IoMdClose } from "react-icons/io";

// const AgentQuary = ({ setAgentQuery }) => {
//   const [activeTab, setActiveTab] = useState("active");

//   const hadleTab = (val) => {
//     setActiveTab(val);
//     setAgentQuery(val);
//   };
//   return (
//     <div className="w-[100%]">
//       <div className="bg-[#0a5a5b] text-gray-200  text-xs font-normal px-4 py-5 flex justify-evenly gap-3 overflow-x-auto">
//         <div
//           onClick={() => {
//             hadleTab("active");
//           }}
//           className={`uppercase cursor-pointer hover:text-blue-500 ${
//             activeTab === "active" && "text-blue-500"
//           }`}
//         >
//           Active(5)
//         </div>
//         <div
//           onClick={() => {
//             hadleTab("requesting");
//           }}
//           className={`uppercase cursor-pointer hover:text-blue-500 ${
//             activeTab === "requesting" && "text-blue-500"
//           }`}
//         >
//           requesting(5)
//         </div>
//         <div
//           onClick={() => {
//             hadleTab("intervened");
//           }}
//           className={`uppercase cursor-pointer hover:text-blue-500 ${
//             activeTab === "intervened" && "text-blue-500"
//           }`}
//         >
//           intervened(5)
//         </div>
//         <div
//           onClick={() => {
//             hadleTab("expired");
//           }}
//           className={`uppercase cursor-pointer hover:text-blue-500 ${
//             activeTab === "expired" && "text-blue-500"
//           }`}
//         >
//           expired(5)
//         </div>
//         <div
//           onClick={() => {
//             hadleTab("all");
//           }}
//           className={`uppercase cursor-pointer hover:text-blue-500 ${
//             activeTab === "all" && "text-blue-500"
//           }`}
//         >
//           all(5)
//         </div>
//       </div>
//       {activeTab === "intervened" && (
//         <div className="w-full">
//           <label className="form-control w-full max-w-xs bg-[#EBF5F3]">
//             {/* <div className="label bg-[#EBF5F3]">
//               <span className="label-text">
//                 Pick the best fantasy franchise
//               </span>
//               <span className="label-text-alt">Alt label</span>
//             </div> */}
//             <select className="select select-bordered bg-[#EBF5F3]  min-w-full max-w-full">
//               <option selected>Intervened By Me (12)</option>
//               <option>Intervened By Any</option>
//               <option>Intervened By Any</option>
//               <option>Intervened By Any</option>
//               <option>Intervened By Any</option>
//             </select>
//           </label>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AgentQuary;

// import React, { useState } from "react";
// import {
//   MenuItem,
//   Select,
//   FormControl,
//   InputLabel,
//   Box,
//   Typography,
// } from "@mui/material";

// const AgentQuary = ({ setAgentQuery }) => {
//   const [activeTab, setActiveTab] = useState("all");
//   const [intervenedFilter, setIntervenedFilter] = useState("Intervened By Me");

//   const handleTabChange = (event) => {
//     const selectedTab = event.target.value;
//     setActiveTab(selectedTab);
//     setAgentQuery(selectedTab);
//   };

//   const handleIntervenedFilterChange = (event) => {
//     setIntervenedFilter(event.target.value);
//   };

//   return (
//     <Box sx={{ width: "100%", p: 2 }}>
//       {/* Heading */}
//       <Typography
//         variant="h6"
//         sx={{
//           mb: 2,
//           fontWeight: "bold",
//           color: "#0a5a5b",
//           textAlign: "center",
//         }}
//       >
//         Chat Options
//       </Typography>

//       {/* Dropdown for Chat Types */}
//       <FormControl fullWidth variant="outlined" sx={{}}>
//         <InputLabel
//           id="chat-type-label"
//           sx={{
//             backgroundColor: "white",
//             px: 1,
//             fontWeight: "500",
//             color: "#0a5a5b",
//           }}
//         >
//           Select Chat Type
//         </InputLabel>
//         <Select
//           labelId="chat-type-label"
//           value={activeTab}
//           onChange={handleTabChange}
//           label="Select Chat Type"
//           sx={{
//             borderRadius: "8px",
//             backgroundColor: "#EBF5F3",
//             "& .MuiOutlinedInput-notchedOutline": {
//               borderColor: "#0a5a5b",
//             },
//             "&:hover .MuiOutlinedInput-notchedOutline": {
//               borderColor: "#096869",
//             },
//             "& .MuiSelect-icon": {
//               color: "#0a5a5b",
//             },
//           }}
//         >
//           <MenuItem value="active">Active (5)</MenuItem>
//           <MenuItem value="requesting">Requesting (5)</MenuItem>
//           <MenuItem value="intervened">Intervened (5)</MenuItem>
//           <MenuItem value="expired">Expired (5)</MenuItem>
//           <MenuItem value="all">All (5)</MenuItem>
//         </Select>
//       </FormControl>

//       {/* Dropdown for Intervened Filter */}
//       {activeTab === "intervened" && (
//         <FormControl fullWidth variant="outlined" sx={{ marginTop: 3 }}>
//           <InputLabel
//             id="intervened-filter-label"
//             sx={{
//               backgroundColor: "white",
//               px: 1,
//               fontWeight: "500",
//               color: "#0a5a5b",
//             }}
//           >
//             Intervened Filter
//           </InputLabel>
//           <Select
//             labelId="intervened-filter-label"
//             value={intervenedFilter}
//             onChange={handleIntervenedFilterChange}
//             label="Intervened Filter"
//             sx={{
//               borderRadius: "8px",
//               backgroundColor: "#EBF5F3",
//               "& .MuiOutlinedInput-notchedOutline": {
//                 borderColor: "#0a5a5b",
//               },
//               "&:hover .MuiOutlinedInput-notchedOutline": {
//                 borderColor: "#096869",
//               },
//               "& .MuiSelect-icon": {
//                 color: "#0a5a5b",
//               },
//             }}
//           >
//             <MenuItem value="Intervened By Me">Intervened By Me (12)</MenuItem>
//             <MenuItem value="Intervened By Any">Intervened By Any</MenuItem>
//             <MenuItem value="Other">Other</MenuItem>
//           </Select>
//         </FormControl>
//       )}
//     </Box>
//   );
// };

// export default AgentQuary;

import React, { useState } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Typography,
} from "@mui/material";
import useZusConversation from "../../../zustand/useZusConversation";
import useAgentsZus from "../../../zustand/useAgents.Zus";
import { UseAuthContext } from "../../../context/AuthContext";

const AgentQuary = ({ setAgentQuery }) => {
  const { loadingCNV, conversations } = useZusConversation();
  const { loadingAgent, agents } = useAgentsZus();

  const { authUser } = UseAuthContext();
  // console.log("authUser:", authUser);
  // console.log("agents:", agents);

  const currentauthuser = authUser?.agnet_id
    ? authUser.agnet_id
    : authUser?._id;

  const [activeTab, setActiveTab] = useState("all");
  const [intervenedFilter, setIntervenedFilter] = useState(currentauthuser);

  // Dynamic Chat Stats
  const chatStats = {
    active: conversations.filter((conversation) => {
      const now = Date.now();
      const expiresAt = new Date(conversation?.expiresAt).getTime();
      return expiresAt > now; // Active chats that haven't expired
    }).length,
    unread: conversations.filter((conversation) => {
      return conversation.unseenMessages > 0;
    }).length,
    requesting: conversations.filter(
      (conversation) => conversation?.requesting === true
    ).length,
    intervened: conversations.filter((conversation) => conversation?.agentId)
      .length,
    expired: conversations.filter((conversation) => {
      const now = Date.now();
      const expiresAt = new Date(conversation?.expiresAt).getTime();
      return expiresAt <= now; // Expired chats
    }).length,
    all: conversations.length, // Total conversations
  };

  // Handlers
  const handleTabChange = (event) => {
    const selectedTab = event.target.value;
    setActiveTab(selectedTab);
    setAgentQuery({
      heading: selectedTab,
      subHeading: intervenedFilter,
    });
  };

  const handleIntervenedFilterChange = (event) => {
    setIntervenedFilter(event.target.value);
    setAgentQuery({
      heading: activeTab,
      subHeading: event.target.value,
    });
  };

  return (
    <>
      {!loadingCNV && (
        <Box sx={{ width: "100%", px: 2, pt: 1 }}>
          {/* Heading */}
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontWeight: "bold",
              color: "#0a5a5b",
              textAlign: "center",
            }}
          >
            Chat Options
          </Typography>

          {/* Dropdown for Chat Types */}
          <FormControl fullWidth variant="outlined">
            <InputLabel
              id="chat-type-label"
              sx={{
                backgroundColor: "white",
                px: 1,
                fontWeight: "500",
                color: "#0a5a5b",
              }}
            >
              Select Chat Type
            </InputLabel>
            <Select
              labelId="chat-type-label"
              value={activeTab}
              onChange={handleTabChange}
              label="Select Chat Type"
              sx={{
                borderRadius: "8px",
                backgroundColor: "#EBF5F3",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#0a5a5b",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#096869",
                },
                "& .MuiSelect-icon": {
                  color: "#0a5a5b",
                },
              }}
            >
              <MenuItem value="active">
                Active&nbsp;
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "0.75rem",
                    color: "#096869",
                    background: "#EBF5F3",
                    padding: "2px 6px",
                    borderRadius: "8px",
                    display: "inline-block",
                    fontWeight: "500",
                  }}
                >
                  {chatStats.active}
                </Typography>
              </MenuItem>
              <MenuItem value="unread">
                Unread&nbsp;
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "0.75rem",
                    color: "#096869",
                    background: "#EBF5F3",
                    padding: "2px 6px",
                    borderRadius: "8px",
                    display: "inline-block",
                    fontWeight: "500",
                  }}
                >
                  {chatStats.unread}
                </Typography>
              </MenuItem>
              <MenuItem value="requesting">
                Requesting&nbsp;
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "0.75rem",
                    color: "#096869",
                    background: "#EBF5F3",
                    padding: "2px 6px",
                    borderRadius: "8px",
                    display: "inline-block",
                    fontWeight: "500",
                  }}
                >
                  {chatStats.requesting}
                </Typography>
              </MenuItem>
              <MenuItem value="intervened">
                Intervened&nbsp;
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "0.75rem",
                    color: "#096869",
                    background: "#EBF5F3",
                    padding: "2px 6px",
                    borderRadius: "8px",
                    display: "inline-block",
                    fontWeight: "500",
                  }}
                >
                  {chatStats.intervened}
                </Typography>
              </MenuItem>
              <MenuItem value="expired">
                Expired&nbsp;
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "0.75rem",
                    color: "#096869",
                    background: "#EBF5F3",
                    padding: "2px 6px",
                    borderRadius: "8px",
                    display: "inline-block",
                    fontWeight: "500",
                  }}
                >
                  {chatStats.expired}
                </Typography>
              </MenuItem>
              <MenuItem value="all">
                All&nbsp;
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "0.75rem",
                    color: "#096869",
                    background: "#EBF5F3",
                    padding: "2px 6px",
                    borderRadius: "8px",
                    display: "inline-block",
                    fontWeight: "500",
                  }}
                >
                  {chatStats.all}
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>

          {/* Intervened Filter (Visible Only for Intervened Tab) */}
          {!loadingAgent && activeTab === "intervened" && (
            <FormControl fullWidth variant="outlined" sx={{ marginTop: 3 }}>
              <InputLabel
                id="intervened-filter-label"
                sx={{
                  backgroundColor: "white",
                  px: 1,
                  fontWeight: "500",
                  color: "#0a5a5b",
                }}
              >
                Intervened Filter
              </InputLabel>
              <Select
                labelId="intervened-filter-label"
                value={intervenedFilter}
                onChange={handleIntervenedFilterChange}
                label="Intervened Filter"
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#EBF5F3",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#0a5a5b",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#096869",
                  },
                  "& .MuiSelect-icon": {
                    color: "#0a5a5b",
                  },
                }}
              >
                {!authUser?.agnet_id && (
                  <MenuItem value={authUser._id}>
                    Intervened By Me ({authUser?.IntervenedChat?.length || 0})
                  </MenuItem>
                )}
                {authUser?.user_Role !== "Agent" && (
                  <MenuItem value={"any"}>Intervened By any</MenuItem>
                )}
                {/* {Array.isArray(agents) &&
                  agents.map((item) => (
                    <MenuItem key={item._id} value={item.name}>
                      Intervened By{" "}
                      {item._id === authUser?.agnet_id ? "Me" : item.name} (
                      {item?.IntervenedChat?.length || 0})
                    </MenuItem>
                  ))} */}
                {Array.isArray(agents) &&
                  agents.map((item) => {
                    if (authUser?.agent_id && authUser?.user_Role === "agent") {
                      // Show only the matched item when authUser.agent_id exists
                      if (item._id === authUser.agent_id) {
                        return (
                          <MenuItem key={item._id} value={item._id}>
                            Intervened By Me (
                            {item?.IntervenedChat?.length || 0})
                          </MenuItem>
                        );
                      }
                      return null; // Skip other items
                    }

                    // Show all items if authUser.agent_id is not present
                    return (
                      <MenuItem key={item._id} value={item._id}>
                        Intervened By{" "}
                        {item._id === authUser?.agnet_id ? "Me" : item.name} (
                        {item?.IntervenedChat?.length || 0})
                      </MenuItem>
                    );
                  })}
                {/* <MenuItem value="Intervened By Any">Intervened By Any</MenuItem>
                <MenuItem value="Other">Other</MenuItem> */}
              </Select>
            </FormControl>
          )}
        </Box>
      )}
    </>
  );
};

export default AgentQuary;
