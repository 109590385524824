import React, { useEffect } from "react";
import TableHeader from "../../components/template/TableHeader";
import List from "../../components/template/List";
import { useFetchTemplates } from "../../hooks/useManagetemplate";
import useAllTemplate from "../../zustand/useAllTemplate";
import { TableCell, TableRow, Typography } from "@mui/material";

function Marketing() {
  // const {} = useFetchTemplates("MARKETING", "APPROVED");

  const { tempLoading: loadingMarketing, allTemplate } = useAllTemplate();

  const marketingTemplates = allTemplate.filter(
    (temp) =>
      temp.templateStatus === "APPROVED" &&
      temp.configData.category === "MARKETING"
  );

  // console.log("template id looks like :", marketingTemplates);

  return (
    // <div className="container mx-auto">
    //   <table className="w-11/12 my-10 rounded-md mx-auto">
    //     <TableHeader />

    //     {loadingMarketing ? (
    //       <span className="loading loading-spinner"></span>
    //     ) : (
    //       marketingTemplates.map((data) => (
    //         <List
    //           key={data._id}
    //           row1={data.configData?.name}
    //           row2={data.configData?.category}
    //           row3={data.templateStatus}
    //           row4={data.configData?.components[0]?.format}
    //           header={data.configData.components[0]?.text}
    //           time={data.createdAt}
    //           row5={data.id}
    //           row6={data.row6}
    //           boxContent2={data.configData?.components[1]?.text}
    //           bodyText={data.configData?.components[1]?.example?.body_text}
    //           boxContent4={data.configData?.components[2]?.text}
    //           buttons={data.configData?.components[3]?.buttons || []}
    //         />
    //       ))
    //     )}
    //   </table>
    // </div>

    <div className="container mx-auto">
      <table className="w-11/12 my-10 rounded-md mx-auto">
        <TableHeader />
        {loadingMarketing ? (
          <span className="loading loading-spinner"></span>
        ) : marketingTemplates.length >= 1 ? (
          marketingTemplates.map((data) => {
            let templateFormat;
            let headerText;
            let bodyText;
            let footer;
            let totalbutton = [];
            let cardsaArray;
            let CAROUSELtype;

            const components = data.configData.components;

            for (let j = 0; j < components.length; j++) {
              const component = components[j];


              console.log("cards ALL DATA LOOKS LIKES :", components);
              if (component.type === "HEADER" && component.format) {
                templateFormat = component.format;

                if (component.format === "Text") {
                  headerText = component.text;
                  console.log(
                    "header looks like this in map function:",
                    headerText
                  );
                }
              } else if (component.type === "BODY" && component.text) {
                bodyText = component.text;
              } else if (component.type === "FOOTER" && component.text) {
                footer = component.text;
              } else if (component.type === "BUTTONS" && component.buttons) {
                totalbutton = totalbutton.concat(component.buttons);
              }   else if (component.type === "CAROUSEL" && component?.cards) {
                cardsaArray = component.cards;
                CAROUSELtype = component.type
              }
            }

            return (
              <List
                key={data._id}
                row1={data.configData?.name}
                row2={data.configData?.category}
                row3={data.templateStatus}
                row4={templateFormat}
                row5={data.id}
                row6={data.row6}
                time={data.createdAt}
                header={headerText}
                boxContent2={bodyText}
                boxContent4={footer}
                buttons={totalbutton}
                cardsaArray={cardsaArray}
                CAROUSELtype={CAROUSELtype}
              />
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={7} align="center">
              <Typography variant="body1" color="textSecondary">
                No Utility templates found !
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </table>
    </div>
  );
}

export default Marketing;
