import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Campaign from "../../components/Campaign/Campaign";
import { useAnaCam } from "../../hooks/useCampaign";
import { formatDate } from "../../utils/extractTime";
function Sent() {
  const { id } = useParams();
  console.log("id looks like this:", id);

  const { loading: overviewLoader, camAna: overviewData } = useAnaCam({
    id,
    category: "sent",
  });

  console.log("sent looks likes this  in sent page :", overviewData.sent);

  const {
    status,
    audience,
    createdAt,
    delivered,
    failed,
    clicked,
    Replied,
    sent,
    read,
  } = overviewData;

  const template = overviewData?.tempateId?.configData;
  const campstatus = {
    title: "Status",
    count: `${status}`,
  };

  const totalmember = {
    title: "Audience",
    count: `${audience?.length}`,
  };

  const Date = {
    title: "date",
    count: formatDate(createdAt),
  };

  const campData = [
    {
      title: "Sent",
      count: `${sent?.length}`,
      index: "0",
    },
    {
      title: "delivered",
      count: `${delivered?.length}`,
      index: "1",
    },
    {
      title: "Read",
      count: `${read?.length}`,
      index: "2",
    },
    ,
    {
      title: "Replies",
      count: `${clicked?.length}`,
      index: "3",
    },
  ];

  return (
    <>
      {overviewLoader && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
        </div>
      )}
      <Campaign
        firstdiv={campstatus}
        secoundiv={totalmember}
        lastDiv={Date}
        fullData={campData}
        template={template}
        tableData={sent}
      />
    </>
  );
}

export default Sent;
